import React, { useState, useEffect } from 'react';
import { Button } from '@themesberg/react-bootstrap';
import { formatCurrency, rowsPerPageOptions } from '../../../../../utils/common';
import { DataGrid } from '@mui/x-data-grid';
import { Chip, Box, LinearProgress, Typography } from '@mui/material';
import WalletTrustLineSet from '../../../../../components/model/WalletTrustLineSet';
import { useSelector } from 'react-redux';
import { tableCellStyle } from '../../../../../utils/common';

const CurrenciesTable = ({ loading, paginationdetails }) => {
  const [isWalletTrustlineModel, setisWalletTrustlineModel] = useState(false);
  const [wallets, setWallets] = useState({});
  const [selectedCoin, setSelectedCoin] = useState({
    coin: '',
    address: '',
  });

  const reduxWallets = useSelector((state) => state.walletsReducer.wallets);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);
  const currency = useSelector((state) => state.profileReducer?.user?.mto?.currency);

  useEffect(() => {
    console.log(reduxWallets);
    if (reduxWallets?.length > 0) {
      let updatedWallets = reduxWallets;

      let filteredArray = reduxWallets;
      console.log(filteredArray);

      if (paginationdetails.search !== '') {
        filteredArray = filteredArray.filter(
          (wallet) =>
            wallet.coin.name.toLowerCase().includes(paginationdetails.search.toLowerCase()) ||
            wallet.coin.symbol.toLowerCase().includes(paginationdetails.search.toLowerCase()) ||
            wallet.address.toLowerCase().includes(paginationdetails.search.toLowerCase())
        );
      }

      if (filteredArray.length > 0) {
        updatedWallets = filteredArray;
      }

      setWallets(updatedWallets);
      console.log(updatedWallets);
    }
  }, [paginationdetails, reduxWallets]);

  const handleClick = (address, coin) => {
    const filterXrp = reduxWallets.find((item) => item.coin.symbol === coin.symbol);

    setSelectedCoin({
      ...selectedCoin,
      coin: coin,
      address: filterXrp.address,
    });
    setisWalletTrustlineModel(true);
  };

  const createDataRow = (wallet) => ({
    id: wallet.id,
    currency: wallet.coin.symbol,
    balance: formatCurrency(wallet.balance, wallet?.coin?.decimal_place, wallet?.coin?.symbol, wallet?.coin?.symbol_prefix),
    valueInCurrency: wallet.balance_currency,
    status: wallet.trust_line_set,
    coinData: wallet.coin,
    logo: wallet.coin.logo,
  });

  const rows = wallets?.length > 0 ? wallets.sort((a, b) => b.id - a.id).map((wallet) => createDataRow(wallet)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 100,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'currency',
      headerName: 'Currency',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <strong>
            <img style={{ width: '40px', height: '40px', marginRight: '12px' }} src={params.row.logo} alt='coin' />
            {params.row.coinData.symbol}
          </strong>
        );
      },
    },

    {
      field: 'balance',
      headerName: 'Balance',
      sortable: false,
      flex: 1,
      minWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'valueInCurrency',
      headerName: `Value in ${currency?.code}`,
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {formatCurrency(params.formattedValue, currency?.decimal_place, currency?.code, currency?.symbol_prefix)}
          </Typography>
        );
      },
    },
    {
      flex: 1,
      field: 'status',
      headerName: 'Status',
      sortable: false,
      minWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          handleClick(params.row.address, params.row.coinData);
        };

        return params.formattedValue ? (
          <Chip
            style={{
              fontSize: '14px',
              fontWeight: '600',
              backgroundColor: 'rgb(217, 246, 229)',
              paddingTop: '4px',
            }}
            label='Verified'
            color='success'
            variant='outlined'
          />
        ) : (
          <Button
            onClick={onClick}
            style={{ background: 'none', height: '40px' }}
            className='d-flex align-items-center red-btn gap-2'
            disabled={!permission?.accounts?.currencies?.permission?.setTrustline[role]}
          >
            <span className='fw-bolder'>Activate</span>
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          // height: 825,
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          pageSize={7}
          rowsPerPageOptions={rowsPerPageOptions}
          disableColumnFilter={true}
          disableColumnMenu={true}
          loading={loading}
        />
      </Box>
      {wallets?.count === 0 && (
        <p
          style={{
            textAlign: 'center',
            marginTop: 10,
            fontWeight: 'bold',
          }}
        >
          No Record Found
        </p>
      )}
      {isWalletTrustlineModel && (
        <WalletTrustLineSet
          selectedCoin={selectedCoin}
          open={isWalletTrustlineModel}
          handleClose={() => {
            setisWalletTrustlineModel(!isWalletTrustlineModel);
          }}
        />
      )}
    </>
  );
};

export default CurrenciesTable;
