import React from 'react';
import { Radio } from '@mui/material';

const RadioComponent = ({ type, value, onChange }) => {
  return (
    <Radio
      checked={type === value}
      value={type}
      onChange={onChange}
      sx={{
        padding: '10px',
        color: '#0B0523',
        '&.Mui-checked': {
          color: '#0B0523',
        },
      }}
    />
  );
};

export default RadioComponent;
