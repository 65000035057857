import React from 'react';
import { Button } from 'rsuite';
import reload from '../assets/img/icons/reload.svg';

const ButtonResetFilter = ({ onClick }) => {
  return (
    <Button
      className=' w-xs-100  text-dark w-100'
      style={{
        background: 'none',
        border: 'none',
        width: '100%',
        minWidth: 'fit-content',
        boxShadow: 'none',
      }}
      onClick={onClick}
    >
      Reset Filters
      <img src={reload} style={{ marginLeft: '7px' }} alt='reset filter' />
    </Button>
  );
};

export default ButtonResetFilter;
