import { XRPL_TEST_ACCOUNT_CREATE_URL } from "../ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";

const xrpl = require("xrpl");
const xrpl_lib = require("xrpl-accountlib");
// const xrpl_wss = 'wss://s.altnet.rippletest.net:51233';

//const xrpl_wss = "wss://s1.ripple.com";
const xrpl_wss = process.env.REACT_APP_XRPL_WSS;

const padToCoinDecimal = (amount, coinDetail) => {
  try {
    return  parseFloat(amount).toFixed(coinDetail.decimal_place)
  } catch (error) {
    return amount
  }
}

export const get_wallet = async (secret) => {
  if (secret.includes(" ")) {
    return xrpl.Wallet.fromMnemonic(secret, {
      mnemonicEncoding: "rfc1751",
      algorithm: "secp256k1",
    });
  }
  console.log(xrpl.Wallet.fromSeed(secret));
  return xrpl.Wallet.fromSeed(secret);
};

export const trust_set = async (from, issuer, secret, coin, amount = 10000) => {
  console.log("all para", { from, issuer, secret, coin, amount });

  try {
    let fee = await get_fee();
    // console.log('fee', fee);
    let sequence = await get_sequence(from);
    // console.log('sequence', sequence);
    let current_validated_ledger = await get_current_validated_ledger();
    // console.log(current_validated_ledger);
    let wallet = await get_wallet(secret);
    // console.log('wallet', wallet);
    if (coin?.length !== 3) {
      coin = Buffer.from(coin, "utf-8").toString("hex");
      coin = coin.padEnd(40, "0");
    }

    const signed = wallet.sign(
      {
        TransactionType: "TrustSet",
        Account: from,
        Fee: fee,
        Sequence: sequence,
        // LastLedgerSequence: current_validated_ledger + 4,
        LimitAmount: {
          currency: coin.toString().toUpperCase(),
          issuer: issuer,
          value: amount.toString(),
        },

        Flags: 262144,
      },
      true
    );

    console.log("signed", signed);
    // console.log(xrpl.decode(signed.tx_blob))
    return signed.tx_blob;
  } catch (error) {
    console.log(error);
  }
};
export const get_current_validated_ledger = async () => {
  const client = new xrpl.Client(xrpl_wss);
  await client.connect();

  const response = await client.request({
    command: "ledger",
    ledger_index: "validated",
  });
  console.log(response);
  client.disconnect();
  console.log("Response", response);
  return parseInt(response.result.ledger.ledger_index); //.toInt();
};
export const get_sequence = async (address) => {
  console.log("get se", xrpl_wss);
  const client = new xrpl.Client(xrpl_wss);
  await client.connect();
  console.log("connecting");
  const response = await client.request({
    command: "account_info",
    //account: address,
    account: address,
    ledger_index: "validated",
  });
  client.disconnect();
  console.log("Response", response);
  //return response.result.ledger_index;
  return response.result.account_data.Sequence;
};
export const get_fee = async () => {
  const client = new xrpl.Client(xrpl_wss);
  await client.connect();

  const response = await client.request({
    command: "fee",
  });
  console.log(response, response.result.drops.minimum_fee * 3, (response.result.drops.minimum_fee * 3).toString());
  client.disconnect();
  //   console.log("Response",response);
  return (response.result.drops.minimum_fee * 3).toString();
};
export const getDigits = (num) => {
  try {
    return num.toString().split(".")[1].length;
  } catch (e) {
    return 0;
  }
};
const hasDot = (num) => {
  try {
    let a = num.toString().split(".")[1].length;
    return true;
  } catch (e) {
    return false;
  }
};
const cleanAmount = (amount) => {
  try {
    if (hasDot(amount)) {
      while (amount.slice(-1) == "0") {
        amount = amount.slice(0, -1);
      }
    }
    if (amount.slice(-1) == ".") {
      // lasdt char is .
      amount = amount.slice(0, -1);
    }
    // if (amount.slice(-1) > 0) {
    //   // lasdt char is .
    //   amount = amount.slice(0, -1);
    // }
    return amount;
  } catch (e) {
    return amount;
  }
};

export const send = async (
  fromAddress,
  toAddress,
  secretkey,
  amountSend,
  amountRecv, //amount
  currencySend,
  currencyRecv, //coin
  issuerSend,
  issuerRecv, //issuerWallet
  // decimal_place,
  SourceTag,
  DestinationTag
) => {
  console.log({
    fromAddress,
    toAddress,
    secretkey,
    amountSend,
    amountRecv, //amount
    currencySend,
    currencyRecv, //coin
    issuerSend,
    issuerRecv,
  });
  try {
    // later on send decimal_palce from place of function call
    let decimal_place = 2;
    amountSend = parseFloat(amountSend).toString();
    amountRecv = parseFloat(amountRecv).toString();
    // const wallet = xrpl.Wallet.fr
    if (currencySend?.length !== 3) {
      currencySend = Buffer.from(currencySend, "utf-8").toString("hex");
      currencySend = currencySend.padEnd(40, "0");
    }

    if (currencyRecv?.length !== 3) {
      currencyRecv = Buffer.from(currencyRecv, "utf-8").toString("hex");
      currencyRecv = currencyRecv.padEnd(40, "0");
    }
    let wallet = await get_wallet(secretkey);
    console.log(wallet.address, fromAddress, wallet.address === fromAddress);

    // Removed address verification to facilitate multisign
    // if (wallet.address !== fromAddress) {
    //   throw new Error("Invalid pass");
    // }
    let sequence = await get_sequence(fromAddress);
    let fee = await get_fee();
    let current_validated_ledger = await get_current_validated_ledger();
    console.log("Sequence", sequence, ",Current Validated:", current_validated_ledger);
    let signed = null;
    if (currencySend.toUpperCase() == "XRP") {
      amountSend = (parseFloat(amountSend) * 1000000).toString();
    }
    if (currencyRecv.toUpperCase() == "XRP") {
      amountRecv = (parseFloat(amountRecv) * 1000000).toString();
    }

    // let sendmax_value = cleanAmount(parseFloat(amountSend) * 1.06);
    let sendmax_value = parseFloat(amountSend).toFixed(getDigits(amountSend)).toString();

    let txn = {
      TransactionType: "Payment",
      Account: fromAddress,
      SourceTag: Number(SourceTag),
      Amount: {
        currency: currencyRecv,
        value: cleanAmount(amountRecv.toString()),
        issuer: issuerRecv,
      },

      Destination: toAddress,
      DestinationTag: Number(DestinationTag),
      Fee: fee,
      Sequence: sequence,
      SendMax: {
        currency: currencySend,
        value: cleanAmount((padToCoinDecimal(sendmax_value * 1.15, {decimal_place})).toString()),
        // value: Number(sendmax_value.toFixed(8)).toString(),
        // value: cleanAmount(sendmax_value.toFixed(8).toString()),
        issuer: issuerSend,
      },
    };
    if (currencyRecv === "XRP") {
      txn.Amount = amountRecv.toString();
    }
    if (currencySend === "XRP") {
      txn.SendMax = txn.SendMax.value;
    }

    if (currencySend === "XRP" && currencyRecv === "XRP") {
      delete txn.SendMax;
    }
    console.log(txn);
    signed = wallet.sign(txn, true);

    console.log(signed);
    return signed.tx_blob;
  } catch (error) {
    toast.error("Something went wrong, please check your passphrase");
    console.log(error);
    console.log(error.data);
  }
};
// 2.31+ 3.45  = 5.
// 332.3400000000001

export const create_account = async (handleCallBack) => {
  console.log(process.env.REACT_APP_STB_ENV);
  if (
    process.env.REACT_APP_STB_ENV === "TEST" ||
    process.env.REACT_APP_STB_ENV === "DEV"
    // process.env.NODE_ENV === 'development' ||
    // process.env.NODE_ENV === 'test'
    // NODE_ENV variable value cannot be changed
  ) {
    axios
      .post(XRPL_TEST_ACCOUNT_CREATE_URL)
      .then((response) => {
        const { data } = response;
        const res = {
          address: data?.account?.classicAddress,
          mnemonic: data?.account?.secret,
        };
        handleCallBack(res);
        console.log(response, "TEST");
      })
      .catch((error) => {
        console.log(error, error.response);
      });
  } else {
    let account = xrpl.Wallet.generate();
    console.log(account, "PROD");
    const res = { address: account.classicAddress, mnemonic: account.seed };
    handleCallBack(res);
  }
};

export const getOffers = async (fromAddress, senderCurrency, toAddress, toCurrency, amount_recv, issuerWallet, onSuccess, onError) => {
  console.log("Amount Received: ", fromAddress, senderCurrency, toAddress, toCurrency, amount_recv, issuerWallet);
  let amountRecv = parseFloat(amount_recv).toString();
  const client = new xrpl.Client(xrpl_wss);
  await client.connect();
  let txn = {
    command: "ripple_path_find",
    destination_account: toAddress,
    destination_amount: {
      currency: toCurrency,
      issuer: issuerWallet,
      value: cleanAmount(amountRecv),
    },
    source_account: fromAddress,
    source_currencies: senderCurrency,
  };

  if (toCurrency === "XRP") {
    txn.destination_amount = txn.destination_amount.value;
  }
  console.log("txn to check ripple path:\n", txn);
  try {
    const response = await client.request(txn);
    console.log(response);
    onSuccess(response);
  } catch (err) {
    console.log(err);
    onError();
  }
  client.disconnect();
  // console.log(JSON.stringify(response));
};
