import { Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';

const DobSelect = ({ formik, name, label = 'Date of Birth', required = false }) => {
  return (
    <Stack spacing={0} width={'100%'}>
      <Typography>
        {label} {required ? '*' : ''}
      </Typography>
      <Stack>
        <DatePicker
          disableFuture
          format={'MM/dd/yyyy'}
          value={formik.values[name]}
          onChange={(value) => {
            console.log(value);
            formik.setTouched(name);
            formik.setTouched({
              ...formik.touched,
              [name]: true,
            });
            formik.setFieldValue(name, value);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              variant='outlined'
              name={name}
              id={name}
              onChange={(value) => {
                console.log(value);
                formik.setTouched(name, true);
                formik.handleChange(value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched[name] && Boolean(formik.errors[name])}
              {...params}
            />
          )}
        />
        {formik.touched[name] && Boolean(formik.errors[name]) && (
          <p
            style={{
              color: '#d32f2f',
              fontSize: '12px',
            }}
          >
            {formik.errors[name]}
          </p>
        )}
      </Stack>
    </Stack>
  );
};

export default DobSelect;
