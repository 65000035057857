import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import { TextField, MenuItem } from "@material-ui/core";
import { Button } from "rsuite";
import { Upload } from "../../../../../components/upload";
import { allowedMediaTypes } from "../../../../../utils/common";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextFieldComponent, TextFieldSelect } from "../../../../../components";

const StatusModal = ({
  isOpen,
  onClose,
  updateClickHandler,
  mtoPatchLoading,
  canChangeTo = [],
}) => {
  console.log(canChangeTo);

  const yupObject = {
    status: yup.string("Select a status").required("Status is required."),

    remarks: yup.string("Write a remark"),
    document: yup.mixed("Select a document").when("status", {
      is: (value) => {
        console.log(value);
        return value === 3;
      },
      then: yup
        .mixed("Select a document")
        .required("Document image is required."),
    }),
    // .required("Document image is required."),
  };

  const validationSchema = yup.object(yupObject);

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    // validateOnChange: true,
  });

  console.log(formik);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // setDoc(newFile);
        formik.setFieldValue("document", newFile);
      }
    },
    [formik]
  );
  const handleRemoveFile = () => {
    // setDoc(null);
    formik.setFieldValue("document", null);
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"sm"}
      onClose={onClose}
      scroll={"body"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Update Status</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0 !important" }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: "repeat(1, 1fr)" },
          }}
        >
          <TextFieldSelect
            formik={formik}
            name="status"
            type="text"
            label="Status"
            options={canChangeTo}
            render={(item) => (
              <MenuItem value={item.id}>{item.actionable_name}</MenuItem>
            )}
          />
          {formik.values.status === 3 && (
            <Stack
              direction={"column"}
              sx={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flex: 1,
              }}
              spacing={0.5}
            >
              <Typography>Upload Document</Typography>
              <Upload
                accept={allowedMediaTypes}
                name="document"
                maxSize={3145728}
                onDrop={handleDrop}
                onDelete={handleRemoveFile}
                file={formik.values.document}
                error={
                  formik.touched.document && Boolean(formik.errors.document)
                }
                helperText={formik.touched.document && formik.errors.document}
              />
            </Stack>
          )}

          <TextFieldComponent
            multiline
            rows={3}
            formik={formik}
            name={"remarks"}
            label={"Remarks"}
            type={"text"}
          />
        </Box>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            className="btn white-btn action-button "
            onClick={onClose}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              updateClickHandler(
                formik.values.status,
                formik.values.remarks,
                formik.values.document
              )
            }
            loading={mtoPatchLoading}
            disabled={!formik.isValid}
            className={`btn action-button primary_color text-white`}
            appearance="primary"
          >
            Next
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default StatusModal;
