import React from 'react';

import { Dialog, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material';
import ButtonSecondary from '../ButtonSecondary';
import ButtonPrimary from '../ButtonPrimary';
import { DocumentCard } from '../documentCard';


const ContractFilesModal = ({ onClose, isOpen }) => {


  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'md'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Contract Files</DialogTitle>
          <DialogContent sx={{ paddingTop: '5px !important', paddingBottom: '0 !important' }}>
          <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
                gridGap: "1rem",
                width: "100%",
              }}
            >
              {[{id:1, name:'Contract', document:'https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg'}, {id:2, name:'Contract Doc2', document:'contract.pdf'}]?.map((document, i) => {
                  return (
                    <DocumentCard documents={document} />
                  );
                })}
            </Box>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
            <div></div>
              <ButtonPrimary
                onClick={handleCloseModal}
              >
               Close
              </ButtonPrimary>
            </DialogActions>
          </DialogContent>
      </Dialog>
    </>
  );
};

export default ContractFilesModal;
