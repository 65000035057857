import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { TextFieldComponent, SplitInputField } from '../../../components';
import { merchantApi } from '../../../redux/api/apiConfig';
import { ADD_FINANCIAL_DATA } from '../../../ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileData } from '../../../redux/actions/profile';

const AddFinancial = ({ onClose, financialDetails, isEdit, loading = false, financialType, open }) => {
  const {
    user: { mto },
  } = useSelector((state) => state.profileReducer);

  const dispatch = useDispatch();

  const validationSchema = {
    bank: {
      name: yup.string('Enter name').min(2, 'Please enter valid name'),
      // .required('Name is required!'),

      account_no: yup.string('Enter account number').min(2, 'Please enter valid account number'),
      // .required('Account Number is required!'),

      iban: yup.string('Enter IBAN'),
      // .min(10, 'Enter Valid IBAN'),
      // .required('Phone Number is required'),

      swift_code: yup.string('Enter SWIFT BIC code'),
      // .min(10, 'Enter Valid IBAN'),
      // .required('Country is required'),
      branch: yup
        .string()
        .length(5, 'Must be exactly 5 digits')
        .matches(/^[0-9]+$/, 'Must be only digits'),

      transit_number: yup
        .string()
        .length(3, 'Must be exactly 3 digits')
        .matches(/^[0-9]+$/, 'Must be only digits'),

      lei_code: yup.string('Enter LEI code'),
      aba_number: yup.string('Enter ABA routing number'),

      ifsc_code: yup.string('Enter IFSC code'),

      address: yup.string('Enter address').required('Address is required'),
      // status: yup.string('Enter address'),
      // .required('Address is required'),
    },
    venmo: {
      venmo_id: yup.string('Enter Venmo ID').min(3, 'Please enter valid Venmo ID'),
    },
    paypal: {
      email: yup.string('Enter your email').email('Enter a valid email'),
    },
  };

  const currentValidation = validationSchema[financialType];

  let formattedFinancialDetails;

  switch (financialType) {
    case 'venmo':
      console.log(financialDetails);
      formattedFinancialDetails = {
        venmo_id: financialDetails.venmo_id,
      };
      break;

    case 'paypal':
      formattedFinancialDetails = { email: financialDetails.email };
      break;
    case 'bank':
      formattedFinancialDetails = { ...financialDetails.bank };
      break;
    default:
      break;
  }
  console.log({ ...formattedFinancialDetails }, currentValidation);
  const formik = useFormik({
    initialValues: { ...formattedFinancialDetails },
    validateOnBlur: true,
    validationSchema: yup.object(currentValidation),
    // validateOnMount: true,
  });

  const nextClickHandler = async (status) => {
    const { email, venmo_id, name, account_no, iban, swift_code, branch, transit_number, lei_code, aba_number, ifsc_code, address } = formik.values;
    let data = {
      type: financialType,
      status,
      email,
      venmo_id,
      bank: {
        name,
        account_no,
        branch,
        lei_code,
        swift_code,
        iban,
        aba_number,
        transit_number,
        ifsc_code,
        address,
      },
    };
    if (financialType !== 'bank') {
      delete data.bank;
    }
    if (financialType !== 'venmo') {
      delete data.venmo_id;
    }
    if (financialType !== 'paypal') {
      delete data.email;
    }
    console.log(data);

    if (!isEdit) {
      const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?mto=${mto?.id}`, data);
    } else {
      const patchResponse = await merchantApi.patch(`${ADD_FINANCIAL_DATA}${financialDetails.id}/`, data);
    }
    dispatch(getProfileData());
    onClose();
  };

  // useEffect(() => {

  // },[])

  console.log(formik);
  console.log(formik.values);
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={financialType === 'bank' ? 'md' : 'sm'}
      scroll={'paper'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          // width: '628px',
          // minHeight: '550px',
          // maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle>Add Financial Details</DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          {financialType === 'bank' && (
            <>
              <TextFieldComponent formik={formik} name={'name'} type={'text'} label={'Bank Name'} />

              <TextFieldComponent formik={formik} name={'account_no'} type={'text'} label={'Account No.'} />

              <TextFieldComponent formik={formik} name={'iban'} type={'text'} label={'IBAN'} />

              <TextFieldComponent formik={formik} name={'swift_code'} type={'text'} label={'SWIFT BIC Code'} />

              <SplitInputField
                formik={formik}
                titleLeft='Branch No.'
                nameLeft='branch'
                typeLeft='text'
                basisLeft={'60%'}
                titleRight='Transit No.'
                nameRight='transit_number'
                typeRight='text'
                basisRight={'40%'}
              />

              <TextFieldComponent formik={formik} name={'aba_number'} type={'text'} label={'ABA Routing No.'} />
              <TextFieldComponent formik={formik} name={'ifsc_code'} type={'text'} label={'IFSC Code'} />
              <TextFieldComponent formik={formik} name={'lei_code'} type={'text'} label={'LEI code'} />
            </>
          )}

          {financialType === 'venmo' && <TextFieldComponent formik={formik} name={'venmo_id'} type={'text'} label={'Venmo ID'} />}

          {financialType === 'paypal' && <TextFieldComponent formik={formik} name={'email'} type={'email'} label={'PayPal Email'} />}
        </Box>
        {financialType === 'bank' && (
          <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Bank Address'} multiline minRows={4} maxRows={4} />
        )}
      </DialogContent>
      <DialogActions>
        <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
          Cancel
        </Button>

        <Button
          className={`btn action-button primary_color text-white  ${!formik.isValid ? 'disabled' : null}`}
          appearance='primary'
          loading={loading}
          onClick={() => nextClickHandler('active')}
          disabled={!formik.isValid}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFinancial;
