import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Label } from '../../../../../components';
import useDeleteModal from '../../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../../components/deleteModal';
import { useSelector } from 'react-redux';

import { Chip, Box, Stack, Typography, LinearProgress, Tooltip, Avatar } from '@mui/material';
import { rowsPerPageOptions, tableCellStyle } from '../../../../../utils/common';
import { startCase } from 'lodash';
import createAvatar from '../../../../../utils/createAvatar';

const BusinessTable = ({ users, paginateTable, setPaginateTable, fetchUsersLoading, setClientToEdit, setIsEdit, toggleAddUserModal, deleteUser }) => {
  const [deleteId, setDeleteId] = useState('');
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Clients Added' : 'No Clients Found'}
      </Stack>
    );
  };

  const confirmDelete = () => {
    deleteUser(deleteId);
    toggleShowDeleteModal();
  };

  const createDataRow = (user) => {
    return {
      id: user.id,
      name: startCase(user.business_name),
      tag: user?.xrpl_tag,
      belongsTo: startCase(user?.sender ? `${user?.sender?.first_name} ${user?.sender?.last_name}` : null),
      city: user?.city?.name || '--',
      province: user?.province?.name || '--',
      status: user.status,
      phoneNumber: user?.primary_contact_no || '--',
      actions: user,
    };
  };

  const rows = users?.results?.length > 0 ? users.results.map((user) => createDataRow(user)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      // flex: 1,
      minWidth: 80,
      // maxWidth: 80,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: 1,
      minWidth: 300,
      // maxWidth: 250,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 2, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: 'tag',
      headerName: 'Xrpl Tag',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'belongsTo',
      headerName: 'Belongs To',
      sortable: false,
      flex: 1,
      minWidth: 300,
      // maxWidth: 250,
      headerClassName: 'header',
      renderCell: (params) => {
        return params.formattedValue ? (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 2, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        ) : (
          '--'
        );
      },
      // align: 'center',
      // headerAlign: 'center',
    },
    {
      field: 'city',
      headerName: 'City',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'province',
      headerName: 'Province',
      sortable: false,
      flex: 1,
      minWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      // flex: 1,
      minWidth: 100,
      alignItems: 'center',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label
            variant={'ghost'}
            color={params.formattedValue.toLowerCase() === 'active' ? 'complete' : 'application submitted'}
            sx={{ textTransform: 'capitalize' }}
          >
            {params.formattedValue}
          </Label>
        );
      },
      // align: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      sortable: false,
      flex: 1,
      minWidth: 170,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' sx={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
      // align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 150,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        console.log(params.value);
        return (
          <Stack direction='row' spacing={2}>
            {permission?.clients?.receivers?.business?.permission?.change[role] && (
              <Stack>
                <Tooltip title='Edit' arrow>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onActivateClicked(params.value);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
            {/* {permission?.clients?.receivers?.business?.permission?.delete[
              role
            ] && (
              <Stack>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    onClick={() => {
                      setDeleteId(params.value.id);
                      toggleShowDeleteModal();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )} */}
          </Stack>
        );
      },
    },
  ];

  // const handlePopoverOpen = (event) => {
  //   const field = event.currentTarget.dataset.field;
  //   const id = event.currentTarget.parentElement.dataset.id;
  //   const row = rows.find((r) => r.id == id);
  //   setValue(row[field]);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };
  // const open = Boolean(anchorEl);

  const onActivateClicked = (client) => {
    setIsEdit(true);
    setClientToEdit(client);
    toggleAddUserModal();
  };

  const handleChangePage = (newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchUsersLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={users.count}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
      {showDeleteModal && <DeleteModal onClose={toggleShowDeleteModal} isOpen={showDeleteModal} onSubmit={confirmDelete} text={'the client'} />}
    </>
  );
};

export default BusinessTable;
