import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Button } from 'rsuite';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper
} from '@mui/material';
import { formatWithoutTimezone } from '../../utils/common';

const FeeHistoryModal = ({
  onClose,
  isOpen,
  text = '',
  loading = false,
}) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  function createData(id, date, action, action_by,) {
    return { id, date: formatWithoutTimezone(date, 'dd MMM yyyy HH:mm a'), action, action_by  };
  }

  const rows = [
    createData(1, '2024-06-20T00:29:26.261304-04:00',  'Created Fee', 'Amar Narute'),
    createData(2, '2024-06-20T00:29:26.261304-04:00', 'Updated fixed cost to 10 CAD', 'Amar Narute'),
    createData(3, '2024-06-20T00:29:26.261304-04:00', 'Updated percentage to 2%', 'Amar Narute'),
    createData(3, '2024-06-20T00:29:26.261304-04:00', 'Deactivated Fee', 'Amar Narute'),
    createData(3, '2024-06-20T00:29:26.261304-04:00', 'Activated Fee', 'Amar Narute'),
  ];

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'md'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle>Fee History</DialogTitle>
        <DialogContent sx={{ paddingBottom: '0 !important' }}>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Action</TableCell>
            <TableCell align="right">Action By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.date}</TableCell>
              <TableCell align="left">{row.action}</TableCell>
              <TableCell align="right">{row.action_by}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
          <div></div>
            <Button
              className={`btn action-button primary_color text-white`}
              appearance="primary"
              type={'submit'}
              onClick={onClose}
              loading={loading}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeeHistoryModal;
