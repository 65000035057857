import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  error: null,
  wallets: [],
  coins: null,
};

const setWallets = (action, state) => {
  return updateObject(state, {
    loading: false,
    wallets: action.payload,
  });
};

const setCoins = (action, state) => {
  return updateObject(state, {
    loading: false,
    coins: action.payload,
  });
};
const walletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WALLETS:
      return setWallets(action, state);
    case actionTypes.GET_COINS:
      return setCoins(action, state);

    default:
      return state;
  }
};

export default walletsReducer;
