import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';

import * as API from '../../../ApiUrl';
import { RemittanceTable } from './components';
import { merchantApi } from '../../../redux/api/apiConfig';
import { format } from 'date-fns';
import { Box, Divider, MenuItem, Stack, TextField, InputAdornment, Autocomplete, Card } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import usePageTitle from '../../../hooks/usePageTitle';
import { reportTimePeriods } from '../../reports/common';
import { formatDate } from '../../../utils/common';
import { ButtonPrimary, ButtonResetFilter, Iconify } from '../../../components';
import { StyledDatePicker } from '../../../components/datePicker';
import { FilterDropdown, Highlight, HighlightSkeleton } from '../../reports/components';
import { CLEAR_INWARD_REMITTANCE, UPDATE_INWARD_REMITTANCE } from '../../../redux/actionTypes';
import StatusModal from './components/statusModal';
import { InternalTransferModal } from '../../../components/internalTransferModal';


function InternalTransfers() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState('');



  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [counts, setCounts] = useState({
    pending: 0,
    processed: 0,
    all: 0,
  });
  const { configReducer } = useSelector((state) => state);
  const filters = useSelector((state) => state.filters.remittances.inward);
  // const { coins = [] } = walletsReducer;
  const { remittanceStatuses: allStatuses = [] } = configReducer;

  const dispatch = useDispatch();
  const history = useHistory();
  usePageTitle('Internal Transfers', 'View your internal transfers');

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_INWARD_REMITTANCE });
  };

  const getCounts = useCallback(async (filters) => {
    console.log(filters);
    const created_date_before = formatDate(filters?.calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(filters?.calenderValue[0], 'yyyy-MM-dd');
    merchantApi(`${API.REMITTANCE_INCOMING}`, {
      params: {
        limit: 1,
        created_date_before,
        created_date_after,
      },
    })
      .then((response) => {
        const { count, pending, complete } = response.data;
        setCounts({
          pending,
          processed: complete,
          all: count,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
      });
  }, []);

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const { calenderValue, pageSize, currentPage, search, status, statusValue } = filters;
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');
    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      search,
      status: status?.id,
      created_date_after,
      created_date_before,
    };
    // If table status is all, only then the status filter value will be passed
    if (statusValue === 'processed' || statusValue === 'payout_done') {
      let completedId = allStatuses
        .filter((status) => status.sender_can_change_to.length === 0 && status.receiver_can_change_to.length === 0)
        .map((status) => status.id);
      delete params.status;
      let finalStatusIds = completedId.reduce((prev, curr) => {
        return `${prev},${curr}`;
      });
      if (statusValue === 'processed') {
        params.status_not = finalStatusIds;
      } else {
        params.status = finalStatusIds;
      }
      console.log(params, statusValue);
    }

    merchantApi
      .get(`${API.REMITTANCE_INCOMING}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [filters, allStatuses]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, filters.status, getTrans]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('here');
      getCounts(filters);
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.calenderValue, getCounts]);

  const viewDetailsHandler = (id) => {
    history.push(Routes.IncomingPayment.path + `/${id}`);
  };

  const toggleStatusModal = () => {
    console.log('owncowieociwnoec')
    setShowStatusModal((state) => !state);
  };

  const toggleTransferModal = () => {
    setShowTransferModal((state) => !state)
  }

  const updateStatusClickHandler = (transaction) => {
    console.log('hereprmvpekrmvopewkrm')
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks, document) => {
    // let value = allStatuses.find((statusItem) => statusItem.id === status).value;
    // if (value === 'reject_request') {
    //   localStorage.setItem('remarks', remarks);
    //   setShowPassphraseModal(true);
    // } else {
      if(document){
        updateStatusWithDocument(status, remarks, document);
      } else {
        updateStatus(status, remarks);
      }
    // }
  };

  const updateStatusWithDocument = async (status, remarks, documents) => {
    try {
      setMtoPatchLoading(true);
      let fileData = new FormData();
      fileData.append('documents', documents);
      fileData.append('remarks', remarks);
      fileData.append('status', status);
  
      const response = await merchantApi.patch(`${API.REMITTANCE_UPLOAD}${transactionData.id}/`, fileData);
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
      getCounts(filters);
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };


  const updateStatus = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${API.REMITTANCE_INCOMING}${transactionData.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
      getCounts(filters);
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'flex-end'} width={'100%'} mb={2}>
          <ButtonPrimary onClick={toggleTransferModal}>New Internal Transfer</ButtonPrimary>
        </Stack>
        <RemittanceTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          updateStatus={updateStatusClickHandler}
          counts={counts}
        />
        {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          canChangeTo={transactionData?.current_status?.receiver_can_change_to}
        />
      )}
      {showTransferModal && (
        <InternalTransferModal
          isOpen={showTransferModal}
          onClose={toggleTransferModal}
          onSubmit={() =>{}}
        />
      )}
      </Box>

     
    </>
  );
}

export default InternalTransfers;
