import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { Routes } from '../../../../../routes';
import { send } from '../../../../../utils/xrpl_functions';
import * as API from '../../../../../ApiUrl';
import { CreateRedeemTable } from '../createRedeemTable';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import back from '../../../../../assets/img/icons/back.svg';
import selectSvg from '../../../../../assets/img/illustrations/select.svg';
import { RedeemSummary } from '../redeemSummary';
import { format } from 'date-fns';
import { Box, Stack, Autocomplete, TextField, Typography } from '@mui/material';
import { ButtonPrimary } from '../../../../../components';
import { PassphraseModal } from '../../../../../components/passphraseModal';
import usePageTitle from '../../../../../hooks/usePageTitle';

function RedemptionRequestRaised() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState('');
  const [showMtoModal, setShowMtoModal] = useState(false);
  const [selectedMto, setSelectedMto] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [showPassphraseModal, setShowPassphraseModal] = useState(false);
  const [passphraseLoading, setPassphraseLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [mtoOptions, setMtoOptions] = useState([]);
  const [redeemSummary, setRedeemSummary] = useState({
    subTotal: 0,
    fees: 0,
    selectedCurrency: '',
  });

  const [paginateTable, setpaginateTable] = useState({
    coin: '',
    mto: '',
  });
  usePageTitle('Create Settlement Request', '');
  const { walletsReducer, profileReducer } = useSelector((state) => state);
  const { wallets = [] } = walletsReducer;
  const { user } = profileReducer;

  const history = useHistory();
  const location = useLocation();

  const getMtoOptions = useCallback(async (mto) => {
    try {
      const response = await merchantApi.get(`${API.MTO}`);
      console.log(response);
      const mtoOptions = response.data;

      const mtoOption = mtoOptions.find((mtooption) => mtooption.id == mto);
      console.log(mtoOption, mtoOptions, mto);
      setMtoOptions(response.data);
      setSelectedMto(mtoOption);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch MTO list.');
    }
  }, []);

  const backClickHandler = () => {
    history.push(Routes.AvailableRedemptions.path);
  };

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    merchantApi
      .get(`${API.REDEEM_LIST}`, {
        params: {
          coin: paginateTable.coin,
          mto: paginateTable.mto,
        },
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        console.log(response);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);
    return () => clearTimeout(timer);
  }, [paginateTable.coin, paginateTable.mto, getTrans]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const filters = Object.fromEntries(query);
    if (wallets) {
      const coin = wallets?.find((wallet) => wallet.coin.id == filters.coin);
      console.log(coin, wallets, filters.coin);
      setSelectedCoin(coin);
    }
    setpaginateTable(filters);
    getMtoOptions(filters.mto);
  }, [getMtoOptions, location, wallets]);

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatus = (transaction) => {
    if (transaction.current_status.can_update_document) {
      history.push({
        pathname: Routes.OutwardRemittance.path + `/details/${transaction.id}`,
        state: { isEdit: true },
      });
    }
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const viewDetailsHandler = (transaction) => {
    history.push(Routes.OutwardRemittance.path + `/details/${transaction.id}`);
  };

  const toggleMtoModal = () => {
    setShowMtoModal((state) => !state);
  };

  const togglePassphraseModal = () => {
    setShowPassphraseModal((state) => !state);
  };

  const passphraseSubmitHandler = async (passphrase) => {
    setPassphraseLoading(true);

    // const code = user?.mto?.currency?.code;
    // console.log(code);
    // const coin = coins.find(
    //   (coin) => coin.symbol === redeemSummary.selectedCurrency
    // );
    const {
      address: fromAddress,
      coin: { issuer_address: issuerSend, id: coinId },
    } = wallets.find((wallet) => wallet.coin.symbol === redeemSummary.selectedCurrency);

    // const {
    //   address: toAddress,
    //   coin: { issuer_address: issuerRecv, symbol: receiverCurrency },
    // } = selectedMto.wallet.find(
    //   (wallet) => wallet.coin.id === redeemSummary.selectedCurrency
    // );

    try {
      const blob = await send(
        fromAddress,
        issuerSend,
        passphrase,
        redeemSummary.subTotal,
        redeemSummary.subTotal,
        redeemSummary.selectedCurrency,
        redeemSummary.selectedCurrency,
        issuerSend,
        issuerSend,
        user?.xrpl_tag,
        user?.mto?.xrpl_tag
      );
      if (blob) {
        const response = await merchantApi.post(API.REDEEM, {
          blob,
          coin: coinId,
          amount: redeemSummary.subTotal,
          remittance: selectedTransactions,
          requester_mto: paginateTable.mto,
        });
        toast.success('Settlement request created successfully');
        setSelectedTransactions([]);
        setShowPassphraseModal(false);
        setRedeemSummary({
          subTotal: 0,
          fees: 0,
          selectedCurrency: '',
        });
        getTrans();
        history.push(Routes.RedemptionsRaised.path);
        // closeMtoModal();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    } finally {
      setPassphraseLoading(false);
    }
  };

  const onSelectedRedeemChange = (ids) => {
    if (ids.length === translist.length) {
    }
    let selectedTransactions = [];
    let subTotal = 0;
    if (ids.length > 0) {
      // Logic to select only one kind of currency if list has more than one type of currencies
      // if (ids.length === translist.length) {
      //   selectedTransactions = translist.filter(
      //     (transaction) =>
      //       ids.includes(transaction.id) &&
      //       transaction.receive_currency.symbol === user.mto.currency.code
      //   );
      // } else {
      selectedTransactions = translist.filter((transaction) => ids.includes(transaction.id));
      // }
    }

    if (selectedTransactions.length > 0) {
      subTotal = selectedTransactions.reduce(
        (prev, cur) =>
          (Number(prev) + Number(cur.send_amount) + Number(cur.receiver_commission)).toFixed(
            selectedTransactions[0]?.send_currency?.decimal_place ?? 3
          ),
        0
      );
    }
    let selectableRowIds = selectedTransactions.map((transaction) => transaction.id);
    console.log(selectableRowIds, selectedTransactions);
    setSelectedTransactions(selectableRowIds);

    setRedeemSummary({
      subTotal,
      fees: 0,
      selectedCurrency: selectedTransactions[0]?.send_currency?.symbol || '',
    });
  };

  const toggleSelectAll = () => {
    if (selectedTransactions.length !== 0 && selectedTransactions.length === translist.length) {
      onSelectedRedeemChange([]);
    } else {
      onSelectedRedeemChange(translist.map((transaction) => transaction.id));
    }
  };

  console.log(redeemSummary);

  return (
    <>
      {/* {loading && <Loader backdrop content=" " vertical />} */}

      <Box sx={{ padding: '24px ' }}>
        <span onClick={backClickHandler} className='back mb-4'>
          <img src={back} alt='back' />
          <span>Back</span>
        </span>
        <div className='d-flex flex-col align-items-start mb-3 gap-4'>
          <Stack direction={'row'} justifyContent={'space-between'} gap={3} width={'100%'}>
            <Stack direction={'row'} gap={3} sx={{ width: '100%' }}>
              <Stack sx={{ maxWidth: '25%', width: '25%' }}>
                <Autocomplete
                  // disablePortal
                  id='mto'
                  value={selectedMto}
                  onChange={(e, newValue) => {
                    setpaginateTable((prev) => ({ ...prev, mto: newValue.id }));
                    setSelectedMto(newValue);
                  }}
                  options={mtoOptions}
                  getOptionLabel={(option) => option.name}
                  sx={{ flexGrow: 1, width: '100%' }}
                  renderInput={(params) => {
                    return <TextField placeholder='Select MTO' {...params} size='small' />;
                  }}
                />
              </Stack>
              <Stack sx={{ maxWidth: '25%', width: '25%' }}>
                <Autocomplete
                  // disablePortal
                  id='coin'
                  value={selectedCoin}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, newValue) => {
                    setpaginateTable((prev) => ({
                      ...prev,
                      coin: newValue.coin.id,
                    }));
                    setSelectedCoin(newValue);
                  }}
                  options={wallets}
                  getOptionLabel={(option) => option.coin.symbol}
                  sx={{ flexGrow: 1, width: '100%' }}
                  // renderInput={(params) => <Dropdown.Item as="button" />}
                  renderInput={(params) => {
                    return <TextField placeholder='Select Coin' {...params} size='small' />;
                  }}
                />
              </Stack>
            </Stack>
            <Stack>
              {paginateTable.coin && paginateTable.mto ? (
                <ButtonPrimary onClick={toggleSelectAll} disabled={fetchTranslistLoading}>
                  {selectedTransactions.length !== 0 && selectedTransactions.length === translist.length ? 'Unselect All' : ' Select All'}
                </ButtonPrimary>
              ) : (
                ''
              )}
            </Stack>
          </Stack>
        </div>
        {paginateTable.coin && paginateTable.mto ? (
          <Stack gap={3} direction={'row'} alignItems={translist?.length <= 5 ? 'flex-start' : 'flex-end'}>
            <CreateRedeemTable
              translist={translist}
              fetchTranslistLoading={fetchTranslistLoading}
              setpaginateTable={setpaginateTable}
              paginateTable={paginateTable}
              onSelectedRedeemChange={onSelectedRedeemChange}
              selectedTransactions={selectedTransactions}
            />
            <Stack sx={{ width: '25%', marginLeft: 'auto' }}>
              <RedeemSummary
                total={(redeemSummary.subTotal - redeemSummary.fees).toFixed(2)}
                discount={redeemSummary.fees}
                subtotal={redeemSummary.subTotal}
                currency={redeemSummary.selectedCurrency}
              />
              <ButtonPrimary onClick={togglePassphraseModal} disabled={redeemSummary.subTotal - redeemSummary.fees <= 0}>
                Settle
              </ButtonPrimary>
            </Stack>
          </Stack>
        ) : (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <img src={selectSvg} alt={'select'} style={{ width: '40%', height: '40%', margin: '1.5rem' }} />
            <Typography sx={{ fontSize: '1.25rem' }}>Select MTO and coin to view transactions</Typography>
          </Stack>
        )}
        {/* </Stack> */}
      </Box>
      {showPassphraseModal && (
        <PassphraseModal
          isOpen={showPassphraseModal}
          onClose={togglePassphraseModal}
          submitClickHandler={passphraseSubmitHandler}
          passphraseLoading={passphraseLoading}
          backClickHandler={togglePassphraseModal}
        />
      )}
    </>
  );
}

export default RedemptionRequestRaised;
