import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Stack, Typography, Autocomplete } from '@mui/material';
import { TextField, MenuItem } from '@material-ui/core';
import { Button } from 'rsuite';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { MTO_ACCOUNTS, REMITTANCE_OUTGOING } from '../../../../../ApiUrl';
import { BalanceCard } from '../../../../../components/balanceCard';
import { ButtonPrimary, ButtonSecondary } from '../../../../../components';
import { toast } from 'react-toastify';

const AccountSelectionModal = ({ isOpen, onClose, transaction, nextClickHandler, updateClickHandler, mtoPatchLoading }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(0);
  const [senderAcOptions, setSenderAcOptions] = useState([]);
  const [remarks, setRemarks] = useState('');

  const { send_currency, send_amount, receiver_commission } = transaction;
  const totalSendAmount = Number(send_amount) + Number(receiver_commission);

  // const totalSendAmount = 4900;
  const getSenderAccounts = async () => {
    try {
      const response = await merchantApi.get(MTO_ACCOUNTS, {
        params: {
          mto_type: 'operating',
        },
      });
      console.log(response.data.data);
      setSenderAcOptions(response.data.results);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  };

  useEffect(() => {
    getSenderAccounts();
  }, []);

  const onAccountChange = (newAccount) => {
    const newWallet = newAccount.balances.find((wallet) => wallet.coin.symbol === send_currency.symbol);
    if (newWallet) {
      setSelectedWallet(newWallet);
    }
  };

  const onNextClick = async () => {
    try {
      const response = await merchantApi.patch(`${REMITTANCE_OUTGOING}${transaction.id}/`, {
        sender_mto_account: selectedAccount.id,
      });
      nextClickHandler();
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'sm'}
      onClose={onClose}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Select Operating Account</DialogTitle>
      <DialogContent sx={{ paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
          }}
        >
          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0.5}
          >
            <Typography>Operating Wallet</Typography>
            <Autocomplete
              disablePortal
              id='sender'
              value={selectedAccount}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, newValue) => {
                onAccountChange(newValue);
                setSelectedAccount(newValue);
              }}
              options={senderAcOptions}
              getOptionLabel={(option) => option.name}
              sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params) => <TextField placeholder='Search sender accounts' {...params} variant='outlined' />}
            />
          </Stack>
          {selectedWallet ? (
            <Stack
              direction={'column'}
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flex: 1,
              }}
              spacing={0.5}
            >
              <BalanceCard
                insufficientBalance={selectedWallet?.balance < totalSendAmount}
                balance={`${Number(selectedWallet.balance).toFixed(selectedWallet?.coin?.decimal_place)} ${selectedWallet?.coin?.symbol}`}
                onRefreshClick={getSenderAccounts}
                loading={false}
                // add={true}
                // addClickHandler={toggleShowTransfer}
              />
            </Stack>
          ) : (
            ''
          )}
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between', mt: 2 }}>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={onNextClick} loading={mtoPatchLoading} disabled={selectedWallet?.balance < totalSendAmount}>
            Next
          </ButtonPrimary>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AccountSelectionModal;
