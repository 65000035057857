import React from 'react';
import { View } from '@react-pdf/renderer';
import PreviewContainer from './PreviewContainer';
import PreviewItem from './PreviewItem';

const SenderSection = ({ type, sender, coins }) => {
  const senderType = type.split('2')[0];
  let senderContent;
  if (senderType === 'p') {
    senderContent = (
      <>
        <PreviewItem title={'First Name'} content={sender?.first_name} />
        <PreviewItem title={'Last Name'} content={sender?.last_name} />
      </>
    );
  } else if (senderType === 'b') {
    senderContent = (
      <>
        <PreviewItem title={'Business Name'} content={sender?.business_name} />
        <PreviewItem title={'Primary Contact Name'} content={sender?.primary_contact_name} />
      </>
    );
  }

  return (
    <View style={{ marginBottom: '12px' }}>
      <PreviewContainer title={'Sender Details'}>
        {senderContent}
        <PreviewItem title={'Phone'} content={sender?.sender?.primary_contact_no} />
        <PreviewItem title={'City'} content={sender?.city?.name} />

        <PreviewItem title={'Province'} content={sender?.province?.name} />
        <PreviewItem title={'Country'} content={sender?.country?.name} />
        <PreviewItem title={'Address'} content={sender?.address} />
      </PreviewContainer>
    </View>
  );
};

export default SenderSection;
