import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const HeroBarChartLoader = () => {
  return (
    <Stack sx={{ margin: '10px 15px', gap: 1 }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-around' }}>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={80} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={50} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={150} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={87} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={97} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={65} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={98} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={57} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={86} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={80} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={45} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={78} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={87} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={23} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={64} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={86} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={34} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={56} />
        </Stack>
        <Stack direction={'row'} sx={{ alignItems: 'flex-end', gap: 1 }}>
          <Skeleton animation={'wave'} variant='rounded' width={10} height={130} />
          <Skeleton animation={'wave'} variant='rounded' width={10} height={100} />{' '}
          <Skeleton animation={'wave'} variant='rounded' width={10} height={40} />
        </Stack>
      </Stack>
      <Skeleton animation={'wave'} variant='text' sx={{ width: '100%' }} />
    </Stack>
  );
};

export default HeroBarChartLoader;
