import React from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Stack, Typography, Box, CircularProgress, Card } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Iconify from "../Iconify";

// ----------------------------------------------------------------------


export default function InfoCard({ title, icon, color, number, action }) {
  const theme = useTheme();

  return (
    <Card
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 1, minWidth: 250, maxWidth:500, overflow:'hidden', padding:'24px 20px', position:'relative' }}
    >
      <Stack
        alignItems="flex-start"
        justifyContent="center"
      >
        <Stack direction={'row'} gap={1}>

        <Typography variant="h4">{number}</Typography>
        {action}
        </Stack>
        <Typography variant="subtitle2" sx={{  color: 'text.secondary' }}>
          {title}
        </Typography>
      </Stack>

      <Iconify icon={icon} width={46} sx={{ color: theme.palette[color].main, position:'absolute', top:'24px', right:'40px'}} />
      <Stack
        spacing={0.5}
        sx={{
          ml: 2,
          top: "-44px",
          width: "160px",
          height: "160px",
          right: "-50px",
          opacity: 0.12,
          borderRadius: "24px",
          position: "absolute",
          transform: "rotate(40deg)",
          background: `linear-gradient(to right, ${theme.palette[color].main} 0%,  ${theme.palette.warning.contrastText} 100%)`,
        }}
      ></Stack>
    </Card>
  );
}
