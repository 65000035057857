import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../../redux/api/apiConfig';
import MonthlyFeesTable, { OutwardFeesTable } from './components/MonthlyFeesTable';
import { AddNewSlabModal, EditMonthlyFeesModal } from '../feesComponents';
import 'rsuite/dist/styles/rsuite-default.css';
import { Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import * as API from '../../../../ApiUrl';
import usePageTitle from '../../../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { ButtonPrimary } from '../../../../components';
import { useSelector } from 'react-redux';
import FeeHistoryModal from '../../../../components/feeHistoryModal/FeeHistoryModal';

const MonthlyFees = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchSlabsLoading, setFetchSlabsLoading] = useState(true);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [slabToEdit, setSlabToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
  });
  const [showFeeHistoryModal, setShowFeeHistoryModal] = useState(false);

  const toggleFeeHistoryModal = () => {
    setShowFeeHistoryModal((prevState) => !prevState);
  };

  const permission = useSelector((state) => state.profileReducer?.permission?.configurations?.fees?.outwardRemittanceFees?.permission);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  usePageTitle('Monthly Fees', '');

  useEffect(() => {
    console.log(slabToEdit);
  }, [slabToEdit]);

  const toggleAddNewSlabModal = () => {
    if (showAddSlabModal) {
      getFeeSlabs();
      setIsEdit(false);
      setSlabToEdit({});
    }
    setShowAddSlabModal((prevState) => !prevState);
  };

  const deleteSlab = async (id) => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.delete(`${API.FEE}${id}/`);
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFeeSlabs();
      setFetchSlabsLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error.response.data.message);
      setFetchSlabsLoading(false);
    }
  };

  const getFeeSlabs = useCallback(async () => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.get(`${API.FEE}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
          side: 'outward',
        },
      });
      console.log(response);
      setSlabs(response.data);
      setFetchSlabsLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchSlabsLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getFeeSlabs();
    }, 500);
    return () => clearTimeout(timer);
  }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search, getFeeSlabs]);

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <MonthlyFeesTable
        fetchSlabsLoading={fetchSlabsLoading}
        slabs={slabs}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setSlabToEdit={setSlabToEdit}
        setIsEdit={setIsEdit}
        toggleAddNewSlabModal={toggleAddNewSlabModal}
        toggleFeeHistoryModal={toggleFeeHistoryModal}
        deleteSlab={deleteSlab}
      />
      {showAddSlabModal && (
        <EditMonthlyFeesModal
          onClose={toggleAddNewSlabModal}
          isOpen={showAddSlabModal}
          isEdit={isEdit}
          slabToEdit={slabToEdit}
          setSlabToEdit={setSlabToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleAddNewSlabModal();
            getFeeSlabs();
          }}
          side={'outward'}
        />
      )}
      {showFeeHistoryModal && (<FeeHistoryModal fee={slabToEdit} isOpen={showFeeHistoryModal} onClose={toggleFeeHistoryModal} />) }
    </Box>
  );
};

export default MonthlyFees;
