import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../../redux/api/apiConfig';
import back from '../../../../assets/img/icons/back.svg';
import { Autocomplete, Box, Divider, Stack, TextField } from '@mui/material';
import { OFFER, SYSTEM_USER } from '../../../../ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { ExchangeOfferHistoryTable } from './components';
import * as actionTypes from '../../../../redux/actionTypes';
import { ButtonPrimary, ButtonResetFilter } from '../../../../components';
import { StyledDatePicker } from '../../../../components/datePicker';
import { format } from 'date-fns';
import { formatDate } from '../../../../utils/common';
const ExchangeOfferHistory = () => {
  const [offerHistory, setOfferHistory] = useState(null);
  const [users, setUsers] = useState([]);

  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.configurations.exchangeOffersHistory);

  const getExchangeOfferHistory = useCallback(async () => {
    try {
      const { user, calenderValue } = filters;
      const valid_from = formatDate(calenderValue[1], 'yyyy-MM-dd');
      const valid_to = formatDate(calenderValue[0], 'yyyy-MM-dd');
      const response = await merchantApi.get(`${OFFER}${id}/history`, {
        params: {
          valid_from,
          valid_to,
          modified_by: user?.id,
        },
      });
      console.log(response);
      setOfferHistory(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, [id, filters]);

  const getPortalUsers = useCallback(async () => {
    try {
      const response = await merchantApi.get(SYSTEM_USER);
      console.log(response);
      setUsers(response.data.results);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, []);

  const backClickHandler = () => {
    history.goBack();
  };

  const handleChangePage = (newPage) => {
    dispatch({
      type: actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  const handleClearFilters = () => {
    dispatch({ type: actionTypes.CLEAR_EXCHANGE_OFFER_HISTORY });
  };

  useEffect(() => {
    getPortalUsers();
  }, [getPortalUsers]);

  useEffect(() => {
    getExchangeOfferHistory();
  }, [filters.calenderValue, filters.user, getExchangeOfferHistory]);

  return (
    <Box sx={{ padding: '0 24px 24px ' }}>
      <Stack sx={{}}>
        <span onClick={backClickHandler} className='back mb-4'>
          <img src={back} alt='back' />
          <span>Back</span>
        </span>
      </Stack>

      {/* <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          width={'100%'}
        >
          <Stack
            sx={{ flexDirection: 'row', gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: 'dashed' }}
              />
            }
          >
           
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                   let a = new Date(newValue)
                    console.log(newValue, filters.calenderValue, newValue > filters.calenderValue[1])
                    dispatch({
                      type: actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1]
                            ? null
                            : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0]
                            ? null
                            : filters.calenderValue[0],
                          new Date(format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            

            <Stack sx={{ minWidth: '200px', flex: 1 }}>
              <Autocomplete
                id="status"
                value={filters.user}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: actionTypes.UPDATE_EXCHANGE_OFFER_HISTORY,
                    payload: {
                      user: newValue ?? null,
                    },
                  });
                }}
                options={users}
                getOptionLabel={(option) => `${option?.first_name} ${option?.last_name} (${option?.role?.name})`}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select User"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
            
          </Stack>
        </Stack> */}

      <ExchangeOfferHistoryTable
        filters={filters}
        offerHistory={offerHistory}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default ExchangeOfferHistory;
