import React from 'react';
import { Modal, ButtonToolbar, Button } from 'rsuite';
import { BsPlus } from 'react-icons/bs';
import AddInvoice from '../pages/AddInvoice';

class InvoiceModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: true,
      show: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open(size) {
    this.setState({ size, show: true });
  }
  render() {
    const { backdrop, show } = this.state;
    return (
      <div className='modal-container'>
        <ButtonToolbar>
          <Button appearance='default' className='pull-right font-12 load text-white' size='lg' onClick={() => this.open('lg')}>
            {' '}
            <BsPlus size='1.2em' />
            Create New Invoice
          </Button>
        </ButtonToolbar>

        <Modal size={this.state.size} backdrop={backdrop} show={show} onHide={this.close}>
          <Modal.Header>
            <Modal.Title className='font-fam'>Create New Invoice</Modal.Title>
          </Modal.Header>
          <hr />
          <Modal.Body>
            <AddInvoice />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default InvoiceModals;
