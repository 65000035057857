import React, { useCallback, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import { FEE, INTERNAL_TRANSFER } from "../../ApiUrl";
import SearchNotFound from "../SearchNotFound";
import { accountTypes } from "../../utils/common";
import { UploadDocument } from "../uploadDocumentModal";
import { AddNewCard } from "../addNewCard";

const validationSchema = yup.object({
  from: yup.object().required("From is required").nullable(),
  to: yup.object().required("To is required").nullable(),
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const CreateContractModal = ({ onClose, isOpen, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const { user } = useSelector((state) => state.profileReducer) || {};
  const currency = user?.mto?.currency?.code;

  const addClickHandler = async (values) => {
    const { from, to, fixed_cost, percentage_charge } = values;
    try {
      setLoading(true);
      const response = await merchantApi.post(INTERNAL_TRANSFER, {
        from_amount: from,
        to_amount: to,
        fixed_cost_fee: fixed_cost,
        percentage_fee: percentage_charge,
        mto: user.mto.id,
      });
      console.log(response);
      onSubmit();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      fromType: '',
      from: null,
      toType: '',
      to: null,
      amount: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const getOption = useCallback((value) => {
    const { first_name, last_name, country } = value;
    return `${first_name} ${last_name} ${country ? `(${country.name})` : ""}`;
  }, []);

  const toggle = () => {
    // toggle modal to add subaccount
  };
  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Create Contract
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={1}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
         
          <Autocomplete
                disablePortal
                sx={{ flexGrow: 1, width: "100%" }}
                id="search"
                getOptionLabel={(option) => {
                  return getOption(option);
                }}
                value={formik.values.from}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                options={searchResults}
                onInputChange={(e, value) => setInputValue(value)}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("from", value);
                  }
                }}
                noOptionsText={
                  <SearchNotFound toggle={toggle} searchQuery={inputValue} />
                }
                renderInput={(params) => {
                  return (
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexGrow: 1,
                      }}
                      spacing={0}
                    >
                      <Typography>From</Typography>
                      <TextField
                        {...params}
                        variant="outlined"
                        name={"sub-account"}
                        placeholder={`Search Sub Account`}
                        type="text"
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.from && Boolean(formik?.errors?.from)
                        }
                        helperText={
                          formik?.touched?.from && formik?.errors?.from
                        }
                      />
                    </Stack>
                  );
                }}
                renderOption={(props, option) => {
                  const { id } = option;
                  return (
                    <li {...props} key={id}>
                      <Typography variant="body2">
                        {getOption(option)}
                      </Typography>
                    </li>
                  );
                }}
              />
  <Autocomplete
                disablePortal
                sx={{ flexGrow: 1, width: "100%" }}
                id="search"
                getOptionLabel={(option) => {
                  return getOption(option);
                }}
                value={formik.values.to}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                options={searchResults}
                onInputChange={(e, value) => setInputValue(value)}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("to", value);
                  }
                }}
                noOptionsText={
                  <SearchNotFound toggle={toggle} searchQuery={inputValue} />
                }
                renderInput={(params) => {
                  return (
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexGrow: 1,
                      }}
                      spacing={0}
                    >
                      <Typography>To</Typography>
                      <TextField
                        {...params}
                        variant="outlined"
                        name={"sub-account"}
                        placeholder={`Search Sub Account`}
                        type="text"
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.to && Boolean(formik?.errors?.to)
                        }
                        helperText={formik?.touched?.to && formik?.errors?.to}
                      />
                    </Stack>
                  );
                }}
                renderOption={(props, option) => {
                  const { id } = option;
                  return (
                    <li {...props} key={id}>
                      <Typography variant="body2">
                        {getOption(option)}
                      </Typography>
                    </li>
                  );
                }}
              />

              <TextFieldComponent
                formik={formik}
                name={"amount"}
                type={"number"}
                label={"Amount"}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{currency}</InputAdornment>
                  ),
                }}
              />
               <TextFieldComponent
                formik={formik}
                name={"comments"}
                type={"text"}
                label={"Comments"}
                multiline
                rows={4}
              />
              <Typography sx={{textAlign: 'left', marginTop: '8px !important', width: '100%'}}>Documents</Typography>
               <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
                      gridGap: '1rem',
                      width: '100%',
                      marginTop: '0 !important'
                    }}
                  >

              <AddNewCard onClick={toggleDocumentUpload} />
                  </Box>
                  </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
        {showDocumentUpload && (
        <UploadDocument isOpen={showDocumentUpload} onClose={toggleDocumentUpload} senderId={formik.values?.from?.id}/>
      )}
      </Dialog>
    </>
  );
};

export default CreateContractModal;
