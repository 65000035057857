import React, { useEffect, useRef, useState } from "react";
import { Button } from "rsuite";
import { FormCheck } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";

import { merchantApi } from "../../redux/api/apiConfig";
import * as API from "../../ApiUrl";
import copy_white from "../../assets/img/icons/copy_white.svg";
import { getWallets } from "../../redux/actions/wallet";
import { create_account } from "../../utils/xrpl_functions";

const MerchantWalletKey = (props) => {
  console.log("props", props);
  const { open, handleClose, isMerchant, selectedWalletType } = props;

  const [isLoading, setisLoading] = useState(false);
  const [passPhraseKey, setPassPhraseKey] = useState(null);
  const secretKeyRef = useRef(null);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [isPassphraseAvailable, setisPassphraseAvailable] = useState(false);
  const [isAgreed, setisAgreed] = useState(false);
  const [isUpdateLoading, setisUpdateLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const data = JSON.parse(localStorage.getItem("accountDetail"));
  console.log(data);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setisLoading(true);
        await create_account(handleCallBack);
      } catch (err) {
        toast.error(err?.data?.data?.message || "Account creation failed");
        // toast.error('Account creation failed.');
        setisLoading(false);
      }
    })();
  }, []);

  const handleCallBack = (accountFormXrp) => {
    localStorage.setItem("accountDetail", JSON.stringify({ address: accountFormXrp.address }));
    setisPassphraseAvailable(true);
    setPassPhraseKey(accountFormXrp.mnemonic);
    setAddress(accountFormXrp.address);
    setisLoading(false);
  };

  const onContinueClicked = async () => {
    setisUpdateLoading(true);

    try {
      // const response = await merchantApi.post(API.GET_SIGNER_ADDRESS, {
      //   address,
      // });
      // console.log(response);
      // setAddress(response.data.data.address);
      // localStorage.setItem("accountDetail", JSON.stringify({ address: response.data.data.address }));
      // const createWalletResponse = await merchantApi.post(API.GET_WALLETS, { address });
      toast.success("Wallets created successfully");
      setisUpdateLoading(false);
      dispatch(getWallets());
      handleClose();
    } catch (err) {
      toast.error(err?.data?.data?.message || "Wallet creation failed.");
    }

    // await merchantApi
    //   .post(API.GET_WALLETS, { address })
    //   .then((res) => {
    //     toast.success('Wallets created successfully');
    //     setisUpdateLoading(false);
    //     dispatch(getWallets());
    //     handleClose();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error(err?.data?.data?.message || 'Wallet creation failed.');
    //     setisUpdateLoading(false);
    //   });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(passPhraseKey);
      toast.success("PassPhrase copied successfully!");
    } catch (err) {
      console.log(err);
      toast.error("Unable to copy to clipboard");
    }
  };

  const handleChangeAcceptBox = (event) => {
    setisAgreed(event.target.checked);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      // onClose={handleClose}
      // onBackdropClick="false"
      className='wallet-configure'
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
          width: "560px",
          minHeight: "456px",
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle className='text-center '>
        <p className='dialog-title'>Welcome to Spend The Bits!</p>
      </DialogTitle>
      <DialogContent className='mt-3 merchant-wallet'>
        <div>
          <p className='instructions mb-3'>
            <div className='instruction flex-row'>
              <span className='circle'>1</span>
              <span>Please note down your secret key in a safe place. This key can be used to access/transfer all your funds to anybody.</span>
            </div>
            <br />
            <br />
            <div className='instruction flex-row'>
              <span className='circle'>2</span>
              <span>Please keep this hand written in a safe place and DO NOT share this with anybody you do not trust with your funds.</span>
            </div>
            <br /> <br />
            <div className='instruction flex-row'>
              <span className='circle'>3</span>
              <span>Please note that no one, including Spend The Bits officials can help to recover your funds if you lose this key.</span>
            </div>
          </p>
          <div className='merchant-wallet-key'>
            <p ref={secretKeyRef} className='secretkey'>
              {passPhraseKey}
            </p>
            <Button loading={isLoading} className='primary_color text-white copy-btn' onClick={copyToClipboard}>
              <img src={copy_white} alt='copy' />
              Copy
            </Button>
          </div>
          <FormCheck type='checkbox' id='acceptTerms' className=' d-flex mt-4 mb-2 alignn-items-center'>
            <FormCheck.Input className='me-2' id='acceptTerms' name='acceptTerms' onChange={handleChangeAcceptBox} />
            <FormCheck.Label>
              <p>I understand this and I have safely noted my key</p>
            </FormCheck.Label>
          </FormCheck>
        </div>
      </DialogContent>
      <hr className='hr' />
      <DialogActions className='footer-content'>
        <Button
          className='action-button primary_color text-white w-100'
          disabled={!isAgreed || isLoading}
          loading={isLoading}
          onClick={onContinueClicked}
        >
          {!isUpdateLoading ? (isPassphraseAvailable ? "Continue" : "Retry") : "loading..."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MerchantWalletKey;

// export default MerchantWalletKey;
