import React, { useState } from 'react';

const useDeleteModal = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleShowDeleteModal = () => {
    setShowDeleteModal((prevState) => !prevState);
  };

  return { showDeleteModal, toggleShowDeleteModal };
};

export default useDeleteModal;
