import React from 'react';
import { Button } from 'rsuite';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import _ from 'lodash';
import { connect } from 'react-redux';

const QRDialog = ({ userPayString, handleClose, open }) => {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(userPayString);
    toast.success('Address copied successfully!');
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      className='wallet-configure'
      maxWidth='md'
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '480px',
          minHeight: '538px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Pay Here</p>
        <p>Scan this QR code from SpendTheBits app to pay</p>
      </DialogTitle>
      <hr className='hr' />
      <DialogContent className='mt-3 merchant-wallet'>
        <div className='merchant-wallet-key grey_bg qr-container br-8'>
          <QRCode size={180} value={userPayString} className=' center' />
        </div>
        <div style={{ wordBreak: 'break-all' }} className='mt-4 text-center'>
          <p className='secretkey'>Your Store Paystring</p>
          <strong>{userPayString}</strong>
        </div>
      </DialogContent>
      <hr className='hr' />
      <DialogActions className='footer-content'>
        <Button className='btn action-button white-btn w-100' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(QRDialog);
