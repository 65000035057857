import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
// @mui
import { Box, List, Typography } from '@mui/material';
// routes
// components
//
import ChatConversationItem from './ChatConversationItem';
import ChatConversationItemSkeleton from './ChatConversationItemSkeleton';
import { Routes } from '../../../routes';
import useResponsive from '../../../hooks/useResponsive';
import { useSelector } from 'react-redux';
import bg from '../../../assets/illustrations/noData.svg';
import Image from '../../../components/image/Image';

// ----------------------------------------------------------------------

ChatConversationList.propTypes = {
  conversations: PropTypes.array,
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.number,
  sx: PropTypes.object,
};

export default function ChatConversationList({
  conversations,
  isOpenSidebar,
  onCloseNav,
  openOnly,
  category,
  // activeConversationId,
  sx,
  ...other
}) {
  const history = useHistory();
  const isDesktop = useResponsive('up', 'md');

  const { activeConversationId, isLoading } = useSelector((state) => state.chat);

  const handleSelectConversation = (conversationId) => {
    history.push(`${Routes.UserChatGeneral.path}ticket/${conversationId}`);
  };

  console.log(conversations, isLoading);
  const loading = isLoading && conversations.length === 0;
  // const loading = true;

  let filteredConversations = conversations;
  if (category !== '') {
    filteredConversations = filteredConversations.filter((conversation) => conversation.type === category);
  }
  if (openOnly) {
    filteredConversations = filteredConversations.filter((conversation) => conversation.status === 'open');
  }
  return (
    <>
      <List disablePadding sx={sx} {...other}>
        {(loading ? [...Array(12)] : filteredConversations).map((conversation, index) =>
          conversation ? (
            <ChatConversationItem
              key={conversation?.id}
              isOpenSidebar={isOpenSidebar}
              conversation={conversation}
              isSelected={activeConversationId === conversation.id}
              onSelectConversation={() => {
                if (!isDesktop) {
                  onCloseNav();
                }
                handleSelectConversation(conversation.id);
              }}
            />
          ) : (
            <ChatConversationItemSkeleton />
          )
        )}
      </List>
      {!loading && filteredConversations.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 1,
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Image src={bg} sx={{ pt: 1, px: 1, width: '200px', height: 'fir-content' }} />
          <Typography variant='h6'>No Tickets Availabe!</Typography>
        </Box>
      ) : (
        ''
      )}
    </>
  );
}
