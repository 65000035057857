import PropTypes from "prop-types";
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import OtpInput from 'react-otp-input';
import ButtonSecondary from "../ButtonSecondary";
import ButtonPrimary from "../ButtonPrimary";

// ----------------------------------------------------------------------

OtpDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  onClose: PropTypes.func,
};

export default function OtpDialog({
  title,
  action,
  open,
  onClose,
  onSubmit,
  ...other
}) {
    console.log('obwjdcksbdsc',{title, action, open,})
  const [otp, setOtp] = useState("");
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent sx={{ typography: "body2" }}>
      <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      containerStyle={{width:'100%', justifyContent:'space-evenly'}}
      inputStyle={{width:'2.5rem', height:'2.5rem'}}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
    />
      </DialogContent>

      <DialogActions>
        <ButtonSecondary  onClick={onClose}>
          Cancel
        </ButtonSecondary>
        <ButtonPrimary
                
                onClick={() => {
                  onSubmit(otp);
                 
                }}
              >
                Verify OTP
              </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}
