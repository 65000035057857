import React from 'react';
import { textFieldLabelProps } from '../utils/common';
import { TextField, Stack, Typography } from '@mui/material';
const TextFieldSelect = ({
  formik,
  name = '',
  type = 'text',
  label = '',
  size = 'normal',
  options = [],
  onChange = () => {},
  render = () => {},
  required = true,
  placeholder = '',
  ...others
}) => {
  return (
    <Stack spacing={0} width={'100%'}>
      <Typography>
        {label} {required ? '*' : ''}
      </Typography>
      <TextField
        select
        fullWidth
        placeholder={placeholder}
        variant='outlined'
        name={name}
        id={name}
        size={size}
        type={type}
        // InputLabelProps={textFieldLabelProps}
        value={formik.values[`${name}`]}
        onChange={(event) => {
          formik.handleChange(event);
          onChange(event);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched[`${name}`] && Boolean(formik.errors[`${name}`])}
        helperText={formik.touched[`${name}`] && formik.errors[`${name}`]}
        {...others}
      >
        {options.map(render)}
      </TextField>
    </Stack>
  );
};

export default TextFieldSelect;
