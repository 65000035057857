// form
import React, { useState } from 'react';
// @mui
import { Box, Stack, Typography, Divider, RadioGroup, Tooltip } from '@mui/material';
import SvgColor from '../../../components/svg-color';
import { MaskControl, StyledCard, StyledWrap } from './styles';
// import Block from 'src/components/block/Block';
import _ from 'lodash';
import AddFinancial from './AddFinancial';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PaymentAccounts({ options, formik, accountDetails }) {
  const [openAddFinancial, setOpenAddFinancial] = useState(false);
  const { values, setFieldValue } = formik;
  console.log(options);
  console.log(accountDetails)
  console.log(values);
  const toggleAddFinancial = () => {
    setOpenAddFinancial((prev) => !prev);
  };

  const selectAccount = (account) => {
    setFieldValue('account', account.id);
  }

  return (
    <Box>
      {/* <Block title={'Please select the account you would like to use'}> */}

      <RadioGroup name='themeMode'>
        <StyledWrap>
          {options.length > 0 ? (
            options.map((account, idx) => (
              <StyledCard key={idx} selected={values.account === account.id}
              onClick={() => selectAccount(account)}
              >
                <Stack
                  sx={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <SvgColor src={`/assets/icons/payments/${account.type}.svg`} />
                  <Stack>
                    <Typography variant='body1' sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                      {(account.display)}
                    </Typography>
                  </Stack>
                  {accountDetails?.type !=='digital_asset' && (
                     <>
                  <Divider orientation='vertical' flexItem />
                 <Tooltip title={'Edit'}>
                    <SvgColor src={`/assets/icons/edit.svg`}  onClick={toggleAddFinancial}/>
                  </Tooltip>
                  </>)}
                </Stack>
              </StyledCard>
            ))
          ) : (
            values?.payment_method !== 'digital_asset' && 
            <StyledCard key={'add'} selected={false} onClick={toggleAddFinancial}>
              <SvgColor src={`/assets/icons/add.svg`} />
              <Stack sx={{ ml: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 500 }}>
                  {_.capitalize('Add New Account')}
                </Typography>
              </Stack>
            </StyledCard>
          )}
        </StyledWrap>
      </RadioGroup>
      {openAddFinancial && (
        <AddFinancial
          open={openAddFinancial}
          financialType={values.payment_method}
          financialDetails={accountDetails || {}}
          onClose={toggleAddFinancial}
          isEdit={options.length !== 0}
        />
      )}
    </Box>
  );
}
