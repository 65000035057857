import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Typography, InputAdornment, IconButton } from '@mui/material';
import { ButtonPrimary, ButtonSecondary } from '..';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordModal = ({ isOpen, onClose, nextClickHandler, loading, nextButtonText = 'Next' }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'xs'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Enter Your Password</DialogTitle>
      <DialogContent>
        <Stack spacing={1} direction={'column'}>
          <Stack spacing={0}>
            <Typography>Password</Typography>
            <TextField
              fullWidth
              variant='outlined'
              name={'from'}
              id={'from'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle passphrase visibility'
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        <ButtonPrimary onClick={() => nextClickHandler(password)} loading={loading}>
          {nextButtonText}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordModal;
