import React from 'react';
import { InvoicesData } from '../components/Tables';
import { Dropdown, Icon, Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { Routes } from '../routes';
import { BsPlus } from 'react-icons/bs';
import { ButtonToolbar, Col } from '@themesberg/react-bootstrap';
import InvoiceModals from '../components/InvoiceModals';

export default () => {
  return (
    <div className='page-wrapper mt-2'>
      <div className='user'>
        <div className='users  bg-white p-4 radi'>
          <div className='row align-items-center pb-4'>
            <div className='col-lg-8'>
              <ButtonToolbar className='d-flex '>
                <Button appearance='default' className='color-blue font-12 rounded-pill pe-4 ps-4'>
                  Latest
                </Button>
                <Button appearance='ghost' className='font-12 ms-2 pe-4 ps-4'>
                  Draft
                </Button>
              </ButtonToolbar>
            </div>
            <div className='col-lg-2'>
              <Dropdown title='This Week' className='bg-lights font-700 bg-lights rounded-pill' icon={<Icon icon='calendar' />}>
                <Dropdown.Item icon={<Icon icon='calendar' />}>This Month</Dropdown.Item>
                <Dropdown.Item icon={<Icon icon='calendar' />}>Last 7 Days</Dropdown.Item>
                <Dropdown.Item icon={<Icon icon='calendar' />}>Yesterday</Dropdown.Item>
                <Dropdown.Item icon={<Icon icon='calendar' />}>Today</Dropdown.Item>
              </Dropdown>
            </div>
            <div className='col-lg-2'>
              <InvoiceModals />
            </div>
          </div>
          <Col xs={12} xl={12} className='mb-4'>
            <InvoicesData />
          </Col>
        </div>
      </div>
    </div>
  );
};
