import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../redux/api/apiConfig';
import { UserKycTable } from './components';
import 'rsuite/dist/styles/rsuite-default.css';
import { toast } from 'react-toastify';
import * as API from '../../ApiUrl';
import searchIcon from '../../assets/img/icons/searchIcon.svg';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import usePageTitle from '../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { ButtonPrimary } from '../../components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UpdateKycModal } from './components';

const UserKyc = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedKycId, setSelectedKycId] = useState('');
  const [showUpdateKycModal, setShowUpdateKycModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [clientToEdit, setClientToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
  });
  const location = useLocation();

  usePageTitle('User KYB', 'View your user KYB details');

  const toggleUpdateKycModal = () => {
    setShowUpdateKycModal((prev) => !prev);
  };

  const toggleAddUserModal = () => {
    if (showAddUserModal) {
      getUsers();
      setIsEdit(false);
      setClientToEdit({});
    }
    setShowAddUserModal((prevState) => !prevState);
  };

  const deleteUser = async (id) => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.delete(`${API.SENDER_IND}${id}/`);
      console.log(response);

      getUsers();
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
      // toast.error(error.response.data.message);
      setFetchUsersLoading(false);
    }
  };

  const getUsers = useCallback(async () => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.get(`${API.KYC_USERS}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
        },
      });
      if (location?.state?.userId) {
        let kycId = response.data.results.find((kyc) => kyc?.user?.data?.id == location?.state?.userId)?.id;
        setSelectedKycId(kycId);
      }
      console.log(response);
      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
      setFetchUsersLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 500);
    return () => clearTimeout(timer);
  }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search, getUsers]);

  useEffect(() => {
    if (location?.state?.openKycModal) {
      console.log(location);
      toggleUpdateKycModal();
    }
  }, []);

  const handleSearch = (value) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
        <Stack sx={{ maxWidth: '25%', flex: 1 }}>
          <Form onSubmit={(e) => e.preventDefault()} className='w-100 search'>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>
                  <img src={searchIcon} alt='search' />
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  type='text'
                  placeholder='Search'
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Stack>
      </Stack>

      <UserKycTable
        fetchUsersLoading={fetchUsersLoading}
        users={users}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setClientToEdit={setClientToEdit}
        setIsEdit={setIsEdit}
        toggleAddUserModal={toggleAddUserModal}
        deleteUser={deleteUser}
        setSelectedKycId={setSelectedKycId}
        toggleUpdateKycModal={toggleUpdateKycModal}
      />
      {showUpdateKycModal && <UpdateKycModal open={showUpdateKycModal} onClose={toggleUpdateKycModal} kycId={selectedKycId} getUsers={getUsers} />}
    </Box>
  );
};

export default UserKyc;
