import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '../redux/actions/config';

const usePageTitle = (pageTitle = '', pageSubTitle = '') => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle({ pageTitle, pageSubTitle }));
  }, [dispatch, pageTitle, pageSubTitle]);
};

export default usePageTitle;
