import React from "react";
import { Stack } from "@mui/material";
import { PreviewItem, Label } from "../../../../components";
import {
  formatWithoutTimezone,
} from "../../../../utils/common";
import { useSelector } from "react-redux";
const HistoryItem = ({ status, type }) => {
  let label;

  const isMediator = useSelector(
    (state) => state.profileReducer?.user?.is_mediator
  );
  const { text_colour, background_colour } = status.status;
  const display =
    type === "outward"
      ? isMediator
        ? status?.status?.mediator_name
        : status?.status?.sender_name
      : status?.status?.receiver_name;

  label = (
    <Label
      variant={"ghost"}
      textColor={text_colour}
      backgroundColor={background_colour}
      sx={{ textTransform: "capitalize" }}
    >
      {display}
    </Label>
  );

  return (
    <Stack
      key={status.id}
      spacing={3}
      direction={"row"}
      sx={{
        borderRadius: "8px",
        borderColor: "#000",
        backgroundColor: "#F0F0F0",
        padding: "1rem",
        marginTop: "1.5rem",
        width: { lg: "90%", xs: "100%" },
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <Stack spacing={0} direction={"row"}>
        <PreviewItem
          title={"Status"}
          content={label || ""}
          sx={{ minWidth: "250px" }}
        />
        <PreviewItem title={"Remarks"} sx={{ minWidth: "200px" }} content={status?.remarks || ""} />
      </Stack>
      <Stack spacing={0} direction={"row"}>
      <PreviewItem title={"Updated By"}  sx={{ minWidth: "200px" }} content={status?.created_user || ""} />
        <PreviewItem
          title={"Date"}
          sx={{ minWidth: "150px" }}
          content={formatWithoutTimezone(
            status?.modified_date,
            "dd MMM yyyy HH:mm a"
          )}
        />
      </Stack>
    </Stack>
  );
};

export default HistoryItem;
