import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { Box, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';
import { Button } from 'rsuite';

import ButtonPrimary from '../ButtonPrimary';

import * as yup from 'yup';
import { useFormik } from 'formik';
import TextFieldSelect from '../TextFieldSelect';
import TextFieldComponent from '../TextFieldComponent';
import { Upload } from '../upload';
import { toast } from 'react-toastify';
import { allowedMediaTypes } from '../../utils/common';
import { uploadFile, uploadSenderDocument } from '../../redux/actions/config';

export default function UploadDocument({ isOpen, onClose, senderId, updateDocs}) {

  
  const yupObject = {
    name: yup.string('Enter a name').required('Document name is required.'),

    document_type: yup.string('Select a document type').required('Document type is required.'),
    document: yup.mixed('Select a document').required('Document image is required.'),
  };

  const validationSchema = yup.object(yupObject);
  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  const { kycDocTypes } = useSelector((state) => state.configReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue('document', newFile);
      }
    },
    [formik]
  );

  const handleRemoveFile = () => {
    formik.setFieldValue('document', null);
  };

  const uploadFileClickHandler = async () => {
    try {
      setLoading(true);
    const { document } = formik.values;
      let fileData = new FormData();
      fileData.append("file", document);
      fileData.append("is_private", true);
      const res = await dispatch(uploadFile(fileData));
      uploadClickHandler(res?.file_id);
    } catch(err) {
      setLoading(false);
      toast.error(err?.message || 'File upload failed');
    }
  };

  const uploadClickHandler = async (id) => {
    try{
    setLoading(true);
    const values = formik.values;
    const { document_type, name } = values;

    const reqBody = {
      document_type: parseInt(document_type),
      name,
      document: id,
      sender: senderId
    };
    const res = await dispatch(uploadSenderDocument(reqBody));
    updateDocs(res);
    setLoading(false);
    onClose();
  } catch (err){
    setLoading(false);
    console.log(err)
    toast.error(err?.message || 'File upload failed');
  }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important', paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldSelect
            formik={formik}
            name='document_type'
            type='text'
            label='Document Type'
            options={kycDocTypes}
            render={(doc) => <MenuItem value={doc.id}>{doc.name}</MenuItem>}
          />
          <TextFieldComponent formik={formik} name={'name'} label={'Document Name'} type={'text'} />

          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0.5}
          >
            <Typography>Upload</Typography>
            <Upload
              accept={allowedMediaTypes}
              name='document'
              maxSize={3145728}
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
              file={formik.values.document}
              error={formik.touched.document && Boolean(formik.errors.document)}
              helperText={formik.touched.document && formik.errors.document}
            />
          </Stack>
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button basis-30' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>

          <ButtonPrimary onClick={uploadFileClickHandler} disabled={!formik.isValid} loading={loading}>
            Upload
          </ButtonPrimary>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

// ----------------------------------------------------------------------
