import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../utils/common';
import { Box, Stack, Typography, LinearProgress} from '@mui/material';
import  capitalize from 'lodash/capitalize';
import { Toggle } from '../../../../components/toggle';

const FeesTable = ({
  fees,
  paginateTable,
  setPaginateTable,
  fetchFeesLoading,
}) => {

    const [visLoading, setVisLoading] = useState(false)
    const [statusLoading, setStatusLoading] = useState(false)


  const permission = useSelector((state) => state.profileReducer?.permission?.configurations?.fees?.outwardRemittanceFees?.permission);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  const user = useSelector((state) => state.profileReducer.user);
  const userCurrency = user?.mto?.currency ?? {};




  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Fees Added' : 'No Fees Found'}
      </Stack>
    );
  };

  const createDataRow = (fee) => {
    return {
      id: fee.id,
      feeName:  capitalize(user?.name),
      fixedCost: (() => {
        return formatCurrency(fee.fixed_cost_fee, userCurrency.decimal_place, userCurrency.code, userCurrency.symbol_prefix) || '--';
      })(),
      percentageCharge: `${Number(fee.percentage_fee).toFixed(2)} %` || '--',
      visibility: fee?.isVisible,
      status: fee?.isActive,
      actions: fee
    };
  };

  const rows = useMemo(() => (fees?.results?.length > 0 ? fees.results.map((slab) => createDataRow(slab)) : [{id:1, feeName:'TestName', fixedCost:'2 USD', percentageCharge:'2%', visibility:true, status:false}]), [fees.results]);

  const columns = useMemo(
    () => [
      {
        field: 'feeName',
        headerName: 'Fee Name',
        sortable: false,
        flex: 1,
        minWidth: 300,
        headerClassName: 'header',
        renderCell: (params) => {
            return (
                <Typography variant='body2' sx={tableCellStyle}>
                  {params.formattedValue}
                </Typography>
              );
        },
      },

      {
        field: 'fixedCost',
        headerName: 'Fixed Cost',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: 'percentageCharge',
        headerName: 'Percentage Charge',
        sortable: false,
        flex: 1,
        minWidth: 100,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
     

      {
        field: 'visibility',
        headerName: 'Visibility',
        sortable: false,
        flex: 1,
        minWidth: 150,
        // maxWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
            console.log(params,'icv2euwcbiwec');
            const onClick = async (data) => {
            //  Handle API call here
                // await handlePOSStatusChange(params.row.counterData);
            
            };
            return (
              <div className="d-flex gap-2 align-items-center">
                <Toggle
                  sx={{ m: 1 }}
                  onClick={onClick}
                  disabled={
                    visLoading 
                  }
                  defaultChecked={params.formattedValue}
                />
                  {/* <Typography variant='body2' sx={tableCellStyle}>
                  {params.formattedValue ? 'Visible' : 'Invisible'}
            </Typography> */}
              </div>
            );
          },
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        flex: 1,
        minWidth: 150,
        // maxWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
            console.log(params,'icv2euwcdcscsdbiwec');
            // console.log(params);
            const onClick = async (data) => {
            //  Handle API call here
                // await handlePOSStatusChange(params.row.counterData);
            
            };
            return (
              <div className="d-flex gap-2 align-items-center">
                <Toggle
                  sx={{ m: 1 }}
                  onClick={onClick}
                  disabled={
                    statusLoading 
                  }
                  defaultChecked={params.formattedValue}
                />
                  {/* <Typography variant='body2' sx={tableCellStyle}>
                  {params.formattedValue ? 'Active' : 'Inactive'}
            </Typography> */}
              </div>
            );
          },
      },
    
    ],
    []
  );

  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={tableStyle}
          density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchFeesLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={fees.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
    </div>
  );
};

export default FeesTable;
