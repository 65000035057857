import React, { useCallback, useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import { FEE, INTERNAL_TRANSFER } from "../../ApiUrl";
import SearchNotFound from "../SearchNotFound";
import { accountTypes } from "../../utils/common";

const validationSchema = yup.object({
  fromType: yup.object().required("Account type is required").nullable(),
  from: yup.object().when('fromType',{
    is:(fromType)=> fromType?.name === 'Sub Account',
    then: yup.object().required("From is required").nullable(),
  }),
  toType: yup.object().required("Account type is required").nullable(),
  to: yup.object().when('toType',{
    is:(toType)=> toType?.name === 'Sub Account',
    then: yup.object().required("To is required").nullable(),
  }),
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const InternalTransferModal = ({ onClose, isOpen, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const { user } = useSelector((state) => state.profileReducer) || {};
  const currency = user?.mto?.currency?.code;

  const addClickHandler = async (values) => {
    const { from, to, fixed_cost, percentage_charge } = values;
    try {
      setLoading(true);
      const response = await merchantApi.post(INTERNAL_TRANSFER, {
        from_amount: from,
        to_amount: to,
        fixed_cost_fee: fixed_cost,
        percentage_fee: percentage_charge,
        mto: user.mto.id,
      });
      console.log(response);
      onSubmit();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  };

  const formik = useFormik({
    initialValues: {
      fromType: '',
      from: null,
      toType: '',
      to: null,
      amount: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const getOption = useCallback((value) => {
    const { first_name, last_name, country } = value;
    return `${first_name} ${last_name} ${country ? `(${country.name})` : ""}`;
  }, []);

  const toggle = () => {
    // toggle modal to add subaccount
  };

  const onSendAccountTypeChange = () => {};

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            // width: '628px',
            // minHeight: '550px',
            // maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Create Internal Transfer
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={1}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <TextFieldSelect
                formik={formik}
                name={"fromType"}
                label={"From Account Type"}
                options={accountTypes}
                onChange={(event) => {
                  onSendAccountTypeChange(event);
                }}
                render={(account) => (
                  <MenuItem value={account}>{account.name}</MenuItem>
                )}
              />
           {formik.values.fromType?.name === 'Sub Account' &&   <Autocomplete
                disablePortal
                sx={{ flexGrow: 1, width: "100%" }}
                id="search"
                getOptionLabel={(option) => {
                  return getOption(option);
                }}
                value={formik.values.from}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                options={searchResults}
                onInputChange={(e, value) => setInputValue(value)}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("from", value);
                  }
                }}
                noOptionsText={
                  <SearchNotFound toggle={toggle} searchQuery={inputValue} />
                }
                renderInput={(params) => {
                  return (
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexGrow: 1,
                      }}
                      spacing={0}
                    >
                      <Typography>From</Typography>
                      <TextField
                        {...params}
                        variant="outlined"
                        name={"sub-account"}
                        placeholder={`Search Sub Account`}
                        type="text"
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.from && Boolean(formik?.errors?.from)
                        }
                        helperText={
                          formik?.touched?.from && formik?.errors?.from
                        }
                      />
                    </Stack>
                  );
                }}
                renderOption={(props, option) => {
                  const { id } = option;
                  return (
                    <li {...props} key={id}>
                      <Typography variant="body2">
                        {getOption(option)}
                      </Typography>
                    </li>
                  );
                }}
              />}
              <TextFieldSelect
                formik={formik}
                name={"toType"}
                label={"To Account Type"}
                options={accountTypes}
                onChange={(event) => {
                  onSendAccountTypeChange(event);
                }}
                render={(account) => (
                  <MenuItem value={account}>{account.name}</MenuItem>
                )}
              />
             {formik.values.toType?.name === 'Sub Account' && <Autocomplete
                disablePortal
                sx={{ flexGrow: 1, width: "100%" }}
                id="search"
                getOptionLabel={(option) => {
                  return getOption(option);
                }}
                value={formik.values.to}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id;
                }}
                options={searchResults}
                onInputChange={(e, value) => setInputValue(value)}
                onChange={(e, value) => {
                  if (value) {
                    formik.setFieldValue("to", value);
                  }
                }}
                noOptionsText={
                  <SearchNotFound toggle={toggle} searchQuery={inputValue} />
                }
                renderInput={(params) => {
                  return (
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        flexGrow: 1,
                      }}
                      spacing={0}
                    >
                      <Typography>To</Typography>
                      <TextField
                        {...params}
                        variant="outlined"
                        name={"sub-account"}
                        placeholder={`Search Sub Account`}
                        type="text"
                        onBlur={formik?.handleBlur}
                        error={
                          formik?.touched?.to && Boolean(formik?.errors?.to)
                        }
                        helperText={formik?.touched?.to && formik?.errors?.to}
                      />
                    </Stack>
                  );
                }}
                renderOption={(props, option) => {
                  const { id } = option;
                  return (
                    <li {...props} key={id}>
                      <Typography variant="body2">
                        {getOption(option)}
                      </Typography>
                    </li>
                  );
                }}
              />}

              <TextFieldComponent
                formik={formik}
                name={"amount"}
                type={"number"}
                label={"Amount"}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{currency}</InputAdornment>
                  ),
                }}
              />
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default InternalTransferModal;
