import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Tab, Tabs, Tooltip } from '@mui/material';
import Image from '../../components/image/Image';
import profile from '../../assets/img/team/vanilla-profile.jpg';
import signinBg from '../../assets/img/signinBg.png';
import Iconify from '../../components/Iconify';
import { Typography, Box, styled, Stack } from '@mui/material';
import { CustomAvatar } from '../../components/customAvatar';
import ResetPassword from './ResetPassword';
import Profile from './Profile';
import { capitalize } from 'lodash';
import { countryFlagApi } from '../../ApiUrl';
import EditProfileModal from './EditProfileModal';
import { useDispatch } from 'react-redux';
import { getProfileData } from '../../redux/actions/profile';
import { toast } from 'react-toastify';
const StyledRoot = styled('div')(({ theme }) => ({
  '&:before': {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    // backgroundColor: alpha('#000000', 1),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const StyledInfo = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(13),
  },
}));

const ProfileCard = ({ userProfileData }) => {
  const [currentTab, setCurrentTab] = useState('profile');
  const [showEditModal, setShowEditModal] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer?.user);
  const permission = useSelector((state) => state.profileReducer?.permission);
  const role = user?.role?.key;

  const { mto } = user;

  const toggleEditProfileModal = () => {
    setShowEditModal((prev) => !prev);
  };

  const onEditProfile = async () => {
    try {
      dispatch(getProfileData());
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
    toggleEditProfileModal();
  };

  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      disabled: !permission?.profile?.permission?.profileTab[role],
      icon: <Iconify icon='ic:round-account-box' />,
      component: (
        <Profile
          email={user?.email}
          address={user?.address}
          country={mto?.country?.name}
          role={user?.role?.name}
          company={mto?.name}
          timezone={user?.timezone}
          onEditClick={toggleEditProfileModal}
        />
      ),
    },
    {
      value: 'password',
      label: 'Password',
      disabled: !permission?.profile?.permission?.passwordTab[role],
      icon: <Iconify icon='carbon:password' />,
      component: <ResetPassword />,
    },
  ];

  return (
    <>
      <Card
        sx={{
          mb: 3,
          height: 280,
          position: 'relative',
        }}
      >
        <StyledRoot>
          <StyledInfo>
            <CustomAvatar
              src={user?.photoURL ?? profile}
              alt={user?.first_name}
              name={user?.first_name}
              sx={{
                mx: 'auto',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'common.white',
                width: { xs: 80, md: 128 },
                height: { xs: 80, md: 128 },
              }}
            />

            <Box
              sx={{
                ml: { md: 3 },
                mt: { xs: 1, md: 0 },
                color: 'common.white',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
                <Typography variant='h4' sx={{ color: 'white' }}>{`${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`}</Typography>
                <Tooltip title={mto?.country?.name} placement='top'>
                  <img alt={'flag'} src={`${countryFlagApi}${String(mto?.country?.code2).toLocaleLowerCase()}.svg`} style={{ width: '24px' }} />
                </Tooltip>
              </Stack>
              <Typography sx={{ opacity: 0.72 }}>{`${mto?.name} (${role})`}</Typography>
            </Box>
          </StyledInfo>

          <Image
            alt='cover'
            src={signinBg}
            sx={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: 'absolute',
              objectFit: 'cover',
            }}
          />
        </StyledRoot>

        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            '& .MuiTabs-flexContainer': {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-start',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab disabled={tab.disabled} iconPosition={'start'} key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>
      {TABS.map((tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>)}
      {showEditModal && <EditProfileModal onClose={toggleEditProfileModal} isOpen={showEditModal} onEdit={onEditProfile} />}
    </>
  );
};

export default ProfileCard;
