import PropTypes from 'prop-types';
import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from '@mui/material';
// components
import Image from '../../../components/image/Image';
import { getThumbnail, statusColors } from '../../../utils/common';
const mime = require('mime-types');

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, conversation, onOpenLightbox }) {
  const senderDetails =
    message?.agent !== null
      ? {
          type: 'me',
          name: `${message?.agent?.first_name} ${message?.agent?.last_name}`,
        }
      : {
          avatar: message.agent?.avatar,
          name: `${message?.user?.data?.first_name} ${message?.user?.data?.last_name}`,
        };

  const isMe = senderDetails.type === 'me';
  const isImage = message.attachments.length > 0;

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        <div>
          <InfoStyle
            variant='caption'
            sx={{
              ...(isMe && { justifyContent: 'flex-end' }),
            }}
          >
            {senderDetails.name},&nbsp;
            {formatDistanceToNowStrict(new Date(message.created_date), {
              addSuffix: true,
            })}
          </InfoStyle>

          <ContentStyle
            sx={{
              bgcolor: '#F0F0F0',
              ...(isMe && {
                // color: 'grey.800',
                bgcolor: alpha(statusColors.colors.complete, 0.3),
              }),
              // ...(isImage && { p: 0 }),
            }}
          >
            {isImage ? (
              <>
                <Image
                  alt='attachment'
                  src={getThumbnail(message.attachments[0].attachment)}
                  // title={message?.message}
                  onClick={() => {
                    // if (
                    //   mime
                    //     .lookup(message.attachments[0].attachment)
                    //     .split('/')[0] !== 'image'
                    // ) {
                    window.open(message.attachments[0].attachment, '_blank');
                    //   } else {
                    //     onOpenLightbox(message.attachments[0].attachment);
                    //   }
                  }}
                  sx={{
                    borderRadius: 1,
                    cursor: 'pointer',
                    mb: 0.5,
                    '&:hover': { opacity: 0.8 },
                  }}
                />
                <Typography variant='body2' sx={{ mt: 1 }}>
                  {message.message}
                </Typography>
              </>
            ) : (
              <Typography variant='body2'>{message.message}</Typography>
            )}
          </ContentStyle>
        </div>
      </Box>
    </RootStyle>
  );
}
