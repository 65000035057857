import React from "react";
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import QRCode from "react-qr-code";
import { ButtonSecondary, Iconify } from ".";
import { formatCurrency, statusColors } from "../utils/common";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

export default function RemittancePaymentStatusDialog({
  action,
  open,
  onClose,
  address,
  total,
  paid,
  currency,
  ...other
}) {
    console.log(Math.round((paid / total) * 100))
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(address);
    toast.success("Address copied successfully!");
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      {...other}
    >
      <DialogTitle sx={{ pb: 2, textAlign: "center" }}>
        Payment Status
      </DialogTitle>

      <DialogContent sx={{ typography: "body2" }}>
        <Stack>
          <QRCode
            style={{ width: 200, margin: "auto" }}
            value={address || ""}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle1" sx={{ mr: 1, textAlign: "center", fontWeight:500, mb: 1 }}>
            USDT Network
          </Typography>
        </Stack>
        <Stack direction={"row"} justifyContent={"center"} sx={{ mb: 1, justifyContent:"center", alignItems:'center' }}>
          <Typography variant="subtitle2" sx={{ mr: 1, wordBreak: "break-all", lineHeight: 'normal' }}>
            {address}
          </Typography>
          <Iconify
            onClick={copyToClipboard}
            icon="eva:copy-outline"
            sx={{ color: "text.disabled", cursor: "pointer",flexBasis:'10%' }}
          />
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{ mr: 1, color: statusColors.colors.rejected, lineHeight: 'normal' }}
        >
          *This address belongs to Algorand, to avoid loss of funds only deposit
          USDT using Algorand.
        </Typography>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mb: 1, mt: 3 }}
        >
          <Typography variant="subtitle2">Payment Progress</Typography>
          <Stack direction={"row"} alignItems={"baseline"}>
            <Typography variant="button" sx={{ fontSize: 24 }}>
              {formatCurrency(
                paid,
                currency?.decimal_place,
                currency?.symbol,
                currency?.symbol_prefix
              )}
            </Typography>
            <Typography variant="button">
              /{" "}
              {formatCurrency(
                total,
                currency?.decimal_place,
                currency?.symbol,
                currency?.symbol_prefix
              )}
            </Typography>
          </Stack>
        </Stack>
        <LinearProgress
          variant="determinate"
          value={Math.round((Number(paid) / Number(total)) * 100)}
          color={"success"}
          sx={{
            height: 8,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.16),
            borderRadius: "8px",
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          paddingX: "24px",
          paddingBottom: "15px",
        }}
      >
        <ButtonSecondary onClick={onClose} style={{ width: "100%" }}>
          Close
        </ButtonSecondary>
      </DialogActions>
    </Dialog>
  );
}
