import React, { useState, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonPrimary, ButtonSecondary, TextFieldComponent, TextFieldSelect, PhoneNumberInput } from '../../components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { MenuItem } from '@material-ui/core';
import * as API from '../../ApiUrl';
import { useSelector } from 'react-redux';
import { merchantApi } from '../../redux/api/apiConfig';
import { PasswordModal } from '../../components/passwordModal';

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  first_name: yup.string('Enter your first name').min(2, 'Please enter valid name').required('First Name is required!'),

  last_name: yup.string('Enter your last name').min(2, 'Please enter valid name').required('Last Name is required!'),

  email: yup.string('Enter your email').email('Please enter valid email').required('Email is required!'),

  role: yup.string('Select user').required('Userlevel is required'),
  phone_number: yup
    .string('Enter your phonenumber')
    // .matches(numricRegex, 'Only Number Required')
    // .min(10, 'Enter Valid Number')
    // .max(12, 'Phone Number Too Long ! Not Valid')
    .required('Phone Number is required'),

  dob: yup.string('Enter date of birth').required('Date of birth is required'),
  // store: yup.string('Select store').required('Userstore is required'),
  // counter: yup.string('Select counter').required('Counter is required'),
  document_type: yup.string('Select document type').required('Document type is required'),
  document_number: yup.string('Select document number').required('Document number is required'),
});

const AddSystemUserModal = ({ onClose, isOpen, onUserAdd }) => {
  const [loading, setloading] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [userRole, setUserRole] = useState([]);
  const [dialCode, setDialCode] = useState('');
  const [userDocTypes, setUserDocTypes] = useState([]);

  const curRole = useSelector((store) => store.profileReducer?.user?.role?.key);
  const countries = useSelector(store => store?.configReducer?.countries)
  console.log(countries)

  const getUserRole = useCallback(() => {
    merchantApi.get(API.USER_ROLES).then((res) => {
      let roles;
      if (curRole === 'admin') {
        roles = res.data.data;
      } else if (curRole === 'manager') {
        roles = res.data.data.filter((item) => !['admin', 'manager'].includes(item.key));
      } else {
        roles = [];
      }
      setUserRole(roles);
    });
  }, [curRole]);

  const getUserDocumentType = useCallback(async () => {
    try {
      const response = await merchantApi.get(API.USER_DOCUMENT_TYPE);
      setUserDocTypes(response.data.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // console.log(error);
    }
  }, []);

  const onSubmit = async (password) => {
    const data = { ...formik.values };
    data.country = countries.find((country) => country.phone == dialCode).id;
    data.phone_number = data.phone_number.replace(dialCode, '');
    try {
      setloading(true);
      const passwordRespose = await merchantApi.post(API.VERIFY_PASSWORD, {
        password,
      });
      const response = await merchantApi.post(API.SYSTEM_USER, data);
      toast.success('User created successfully');
      toggleShowPasswordModal();
      onUserAdd();
      setloading(false);
    } catch (error) {
      setloading(false);
      toast.error(error?.data?.data?.message || 'Transfer failed');
    }
  };

  useEffect(() => {
    getUserRole();
    getUserDocumentType();
  }, [getUserRole, getUserDocumentType]);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      role: '',
      phone_number: '',
      dob: '',
      // store: '',
      // counter: '',
      document_type: '',
      document_number: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    // isInitialValid: false,
    validateOnMount: true,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  const toggleShowPasswordModal = () => {
    console.log(formik.values, dialCode);
    setShowPasswordModal((state) => !state);
  };

  const onlyCountries = countries.map((country) => country.code2.toLowerCase());
  console.log(formik)

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'md'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle className='text-center'>
          <p className='dialog-title'>Create System User Account</p>
          <p className=''>Please make sure all the required field is carefully filled with valid information.</p>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Box
            sx={{
              display: 'grid',
              columnGap: '16px',
              rowGap: '12px',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            <TextFieldComponent required formik={formik} name={'first_name'} label={'First Name'} type={'text'} />

            <TextFieldComponent required formik={formik} name={'last_name'} label={'Last Name'} type={'text'} />

            <TextFieldComponent required formik={formik} name={'email'} label={'Email'} type={'email'} />

            <PhoneNumberInput
              onlyCountries={onlyCountries}
              formik={formik}
              label={'Phone Number'}
              name={'phone_number'}
              setPhoneNumber={(_, dialCode) => {
                setDialCode(dialCode);
              }}
            />

            <TextFieldComponent required formik={formik} name={'dob'} label={'Date of Birth'} type={'date'} />

            <TextFieldSelect
              formik={formik}
              name={'role'}
              type={'text'}
              label={'User Role'}
              options={userRole}
              render={(role) => <MenuItem value={role.id}>{role.name}</MenuItem>}
            />
            <TextFieldSelect
              formik={formik}
              name={'document_type'}
              type={'text'}
              label='Document Type'
              options={userDocTypes}
              onChange={(event) => {
                // setRender((prevState) => prevState + 1);
                // setSelectedProvince(event.target.value);
              }}
              render={(doctype) => <MenuItem value={doctype.value}>{doctype.name}</MenuItem>}
            />

            <TextFieldComponent required formik={formik} name={'document_number'} label={'Document Number'} type={'text'} />

            {/* {!!showStores && (
              <TextFieldSelect
                formik={formik}
                name={'store'}
                type={'text'}
                label={'Store'}
                options={userStore}
                render={(role) => (
                  <MenuItem value={role.id}>{role.name}</MenuItem>
                )}
              />
            )}
            {!!showCounters && (
              <TextFieldSelect
                formik={formik}
                name={'counter'}
                type={'text'}
                label={'Counter'}
                options={userCounters}
                render={(counter) => (
                  <MenuItem value={counter.id}>{counter.name}</MenuItem>
                )}
              />
            )} */}
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 20px' }}>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={toggleShowPasswordModal} disabled={!formik.isValid || loading}>
            Create User
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
      {showPasswordModal && (
        <PasswordModal
          isOpen={showPasswordModal}
          onClose={toggleShowPasswordModal}
          nextClickHandler={onSubmit}
          loading={loading}
          nextButtonText={'Submit'}
        />
      )}
    </>
  );
};

export default AddSystemUserModal;
