import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import * as API from '../../../../../ApiUrl';
import { Button } from 'rsuite';
import { DialogActions, DialogContent, MenuItem, Stack, TextField, Typography } from '@mui/material';
import {
  TextFieldComponent,
  TextFieldSelect,
  CountrySelect,
  PhoneNumberInput,
  ButtonSecondary,
  ButtonPrimary,
  DobSelect,
} from '../../../../../components';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { todayDate, minDate } from '../../../../../utils/common';

const validationSchema = yup.object({
  first_name: yup.string('Enter your first name').min(2, 'Please enter valid name').required('First Name is required!'),

  last_name: yup.string('Enter your last name').min(2, 'Please enter valid name').required('Last Name is required!'),

  primary_contact_no: yup.string('Enter your phonenumber'),
  // .matches(numricRegex, 'Only Number Required'),
  // .min(10, 'Enter Valid Number')
  // .max(12, 'Phone Number Too Long ! Not Valid'),
  // .required('Phone Number is required'),

  country: yup.string('Select Country'),
  // .required('Country is required'),
  province: yup.string('Select Province'),
  // .required('Province is required'),
  city: yup.string('Enter City'),
  // .required('City is required'),
  address: yup.string('Enter address'),
  // .required('Address is required'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  dob: yup.date('Enter date of birth').required('Date of birth is required').max(todayDate, 'DOB cannot be future date').min(minDate, 'Invalid date'),
});

const PersonalInfo = ({ onClose, handleNext, isEdit, personalInfo, loading }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [render, setRender] = useState(0);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const countryList = useSelector((state) => state?.configReducer?.countries);


  let mtoCountry = [];

  mtoCountry.push(useSelector((state) => state?.profileReducer?.user?.mto?.country?.code2));

  const getProvince = async (country) => {
    try {
      const response = await merchantApi.get(`${API.PROVINCE_LIST}?country=${country}&limit=1000`);
      setProvinceList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };
  const getCities = async (country) => {
    try {
      const response = await merchantApi.get(`${API.CITY_LIST}?country=${country}&region=${formik.values.province}&limit=1000`);
      setCityList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      getProvince(selectedCountry);
      setProvinceList([]);
      setCityList([]);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (render !== 0) {
      getCities(selectedCountry);
      setCityList([]);
      formik.setFieldValue('city', '');
    }
  }, [selectedProvince]);

  useEffect(() => {
    console.log(personalInfo);
    if (personalInfo.country) {
      const code = countryList.find((country) => country.id === personalInfo.country).code2;
      // setSelectedCountry(personalInfo.country);
      formik.setFieldValue('country', code);
      getProvince(personalInfo.country);
      if (personalInfo.province) {
        formik.setFieldValue('province', personalInfo.province);
        getCities(personalInfo.country);
      }

      if (personalInfo.city) {
        formik.setFieldValue('city', personalInfo.city);
      }
    }

    return () => {
      setProvinceList([]);
      setCityList([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      ...personalInfo,
      dob: personalInfo?.dob ? new Date(personalInfo.dob) : null,
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  const nextClickHandler = (status) => {
    let country;
    if (formik?.values?.country) {
      console.log(formik.values.country);
      country = countryList.find((country) => country.code2 === formik.values.country).id;
      console.log(country);
    }
    handleNext({
      address: formik.values.address,
      city: formik.values.city,
      province: formik.values.province,
      country,
      first_name: formik.values.first_name,
      last_name: formik.values.last_name,
      primary_contact_no: formik.values.primary_contact_no,
      status: 'draft',
      email: formik.values.email,
      dob: format(new Date(formik?.values?.dob), 'yyyy-MM-dd'),
    });
  };

  console.log(formik);
  return (
    <>
      <DialogContent sx={{ paddingTop: '20px !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldComponent formik={formik} name={'first_name'} type={'text'} label={'First Name'} />

          <TextFieldComponent formik={formik} name={'last_name'} type={'text'} label={'Last Name'} />

          <PhoneNumberInput formik={formik} label={'Phone Number'} name={'primary_contact_no'} setPhoneNumber={setPhoneNumber} />

          <CountrySelect
            formik={formik}
            disabled={true}
            label={'Country'}
            name={'country'}
            options={mtoCountry}
            countryList={countryList}
            onChange={(id) => {
              setRender((prevState) => prevState + 1);
              setSelectedCountry(id);
            }}
          />

          <TextFieldSelect
            formik={formik}
            name={'province'}
            label={'Province'}
            options={provinceList}
            onChange={(event) => {
              setRender((prevState) => prevState + 1);
              setSelectedProvince(event.target.value);
            }}
            render={(province) => <MenuItem value={province.id}>{province.name}</MenuItem>}
          />

          <TextFieldSelect
            formik={formik}
            name={'city'}
            label={'City'}
            options={cityList}
            render={(province) => <MenuItem value={province.id}>{province.name}</MenuItem>}
          />
          <DobSelect formik={formik} name='dob' required />
          {/* <Stack spacing={0} width={'100%'}>
            <Typography>Date of Birth *</Typography>
            <Stack>
              <DatePicker
                disableFuture
                format={'dd/MM/yyyy'}
                value={formik.values.dob}
                onChange={(value) => {
                  console.log(value);
                  formik.setTouched('dob');
                  formik.setTouched({
                    ...formik.touched,
                    dob: true,
                  });
                  formik.setFieldValue('dob', value);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="dob"
                    id={'dob'}
                    onChange={(value) => {
                      console.log(value);
                      formik.setTouched('dob', true);
                      formik.handleChange(value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    {...params}
                  />
                )}
              />
              {formik.touched.dob && Boolean(formik.errors.dob) && (
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '12px',
                  }}
                >
                  {formik.errors.dob}
                </p>
              )}
            </Stack>
          </Stack> */}
          <TextFieldComponent formik={formik} name={'email'} type={'email'} label={'Email'} sx />
        </Box>
        <Stack sx={{ gap: '12px' }}>
          <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Address'} multiline minRows={4} maxRows={4} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 20px' }}>
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        <ButtonPrimary loading={loading} onClick={nextClickHandler} disabled={!formik.isValid}>
          Next
        </ButtonPrimary>
      </DialogActions>
    </>
  );
};

export default PersonalInfo;
