import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { EditMonthlyFeesModal } from '../feesComponents';
import 'rsuite/dist/styles/rsuite-default.css';
import { Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import * as API from '../../../../ApiUrl';
import usePageTitle from '../../../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import OtherFeesTable from './components/OtherFeesTable';
import { ButtonPrimary } from '../../../../components';
import AddOtherFeesModal from '../feesComponents/addOtherFeesModal';
import FeeHistoryModal from '../../../../components/feeHistoryModal/FeeHistoryModal';

const OtherFees = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchFeesLoading, setFetchFeesLoading] = useState(true);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [feeToEdit, setFeeToEdit] = useState({});
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
  });
  const [showFeeHistoryModal, setShowFeeHistoryModal] = useState(false);

  usePageTitle('Other Fees', '');

  const toggleAddNewFeeModal = () => {
    if (showAddSlabModal) {
      getFees();
      setIsEdit(false);
      setFeeToEdit({});
    }
    setShowAddSlabModal((prevState) => !prevState);
  };

  const toggleFeeHistoryModal = () => {
    setShowFeeHistoryModal((prevState) => !prevState);
  };

  const deleteFee = async (id) => {
    setFetchFeesLoading(true);
    try {
      const response = await merchantApi.delete(`${API.FEE}${id}/`);
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFees();
      setFetchFeesLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error.response.data.message);
      setFetchFeesLoading(false);
    }
  };

  const getFees = useCallback(async () => {
    setFetchFeesLoading(true);
    try {
      const response = await merchantApi.get(`${API.FEE}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
        },
      });
      console.log(response);
      setSlabs(response.data);
      setFetchFeesLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchFeesLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getFees();
    }, 500);
    return () => clearTimeout(timer);
  }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search, getFees]);

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
        <Stack direction={'row'} justifyContent={'flex-end'} width={'100%'} mb={2}>
          <ButtonPrimary onClick={toggleAddNewFeeModal}>New Fee</ButtonPrimary>
        </Stack>
      <OtherFeesTable
        fetchFeesLoading={fetchFeesLoading}
        fees={slabs}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setFeeToEdit={setFeeToEdit}
        setIsEdit={setIsEdit}
        toggleAddNewFeeModal={toggleAddNewFeeModal}
        toggleFeeHistoryModal={toggleFeeHistoryModal}
        deleteFee={deleteFee}
      />
      {showAddSlabModal && (
        <AddOtherFeesModal
          onClose={toggleAddNewFeeModal}
          isOpen={showAddSlabModal}
          isEdit={isEdit}
          feeToEdit={feeToEdit}
          setFeeToEdit={setFeeToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleAddNewFeeModal();
            getFees();
          }}
          side={'outward'}
        />
      )}
      {showFeeHistoryModal && (<FeeHistoryModal fee={feeToEdit} isOpen={showFeeHistoryModal} onClose={toggleFeeHistoryModal} />) }
    </Box>
  );
};

export default OtherFees;
