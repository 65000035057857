import React, { useState } from 'react';
// form
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
// @mui
import { Typography, Paper, Stack, SpeedDial, SpeedDialAction } from '@mui/material';
import _ from 'lodash';
import { _socials } from '../../../utils/common';
import { Iconify, PreviewContainer, PreviewItem } from '../../../components';
import SharePaymentDetails from './SharePaymentDetails';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

//  ${infoObj.lei_code && `LEI: ${infoObj.lei_code},  `}
export default function PaymentMethodDetails({ info }) {
  const { user } = useSelector((state) => state.profileReducer);
  console.log(info)

  const { copy } = useCopyToClipboard();
  let details;
  let url;
  let mailSubject;
  let copyContent;
  if (info) {
    switch (info.type) {
      case 'bank':
        const infoObj = info.bank;
        mailSubject = `${_.capitalize(user?.mto?.name)} Bank Account Details`;
        copyContent = `${_.capitalize(user?.mto?.name)} Bank Account Details:
 ${infoObj.name && `Bank: ${infoObj.name},  `}
 ${infoObj.account_no && `A/C Number: ${infoObj.account_no},  `}
 ${infoObj.branch && `Branch: ${infoObj.branch},  `}
 ${infoObj.iban && `IBAN: ${infoObj.iban},  `}
 ${infoObj.ifsc_code && `IFSC: ${infoObj.ifsc_code},  `}
 ${infoObj.swift_code && `SWIFT: ${infoObj.swift_code},  `}
 ${infoObj.transit_number && `Transit Number: ${infoObj.transit_number},  `}  `;
        url = encodeURIComponent(copyContent);
        details = (
          <>
            {infoObj.name && <PreviewItem key={'name'} title={'Name'} content={infoObj.name} />}
            {infoObj.account_no && <PreviewItem key={'ac_no'} title={'Account Number'} content={infoObj.account_no} />}
            {infoObj.branch && <PreviewItem key={'branch'} title={'Branch'} content={infoObj.branch} />}
            {infoObj.iban && <PreviewItem key={'iban'} title={'IBAN'} content={infoObj.iban} />}
            {infoObj.ifsc_code && <PreviewItem key={'ifsc'} title={'IFSC Code'} content={infoObj.ifsc_code} />}
            {infoObj.lei_code && <PreviewItem key={'lei'} title={'LEI Code'} content={infoObj.lei_code} />}
            {infoObj.swift_code && <PreviewItem key={'swift'} title={'SWIFT Code'} content={infoObj.swift_code} />}
            {infoObj.transit_number && <PreviewItem key={'transit_no'} title={'Transit Number'} content={infoObj.transit_number} />}
            {infoObj.aba_number && <PreviewItem key={'aba_number'} title={'ABA Routing Number'} content={infoObj.aba_number} />}
            {infoObj.address && <PreviewItem key={'address'} title={'Address'} content={infoObj.address} />}
          </>
        );

        break;
      case 'venmo':
        mailSubject = `${_.capitalize(user?.mto?.name)} Venmo Account Details`;
        copyContent = `${_.capitalize(user?.mto?.name)} Venmo Account Details:
${info.venmo_id && `Venmo ID: ${info.venmo_id}`}`;
        url = encodeURIComponent(copyContent);
        details = <PreviewItem key={'venmo'} title={'Venmo Id'} content={info.venmo_id} />;

        break;
      case 'paypal':
        mailSubject = `${_.capitalize(user?.mto?.name)} PayPal Account Details`;
        copyContent = `${_.capitalize(user?.mto?.name)} PayPal Account Details:
${info.email && `PayPal Email: ${info.email}`}`;
        url = encodeURIComponent(copyContent);
        details = <PreviewItem key={'paypal'} title={'PayPal Email'} content={info.email} />;

        break;

        case 'digital_asset':
          mailSubject = `${_.capitalize(user?.mto?.name)} Digital Asset Details`;
          copyContent = `${_.capitalize(user?.mto?.name)} Digital Asset Details:
  ${info.display && `${info.display} Address: ${info.display}`}`;
          url = encodeURIComponent(copyContent);
          details = <PreviewItem key={'digital_asset'} title={`${info.display} Address`} content={info.blockchain_address} />;
  
          break;

      default:
        break;
    }
  }

  const handleCopy = (text) => {
    copy(text);
  };

  const shareClickHandler = (type) => {
    switch (type) {
      case 'copy':
        handleCopy(copyContent);
        break;
      case 'whatsapp': {
        window.open(`https://wa.me/?text=${url}`, '_blank');
        break;
      }
      case 'email': {
        window.open(`mailto:?subject=${mailSubject}&body=${url}`, '_blank');
        break;
      }

      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
      }}
    >
      <PreviewContainer
      // sx={{
      //   p: 1.5,
      //   borderRadius: 2,
      //   bgcolor: 'background.neutral',
      //   display: 'grid',
      //   gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
      //   gridGap: '1rem',
      //   flex: 1,
      // }}
      >
        {info && details}
      </PreviewContainer>
      <SpeedDial
        direction={'up'}
        ariaLabel='Share payment method info'
        icon={<Iconify icon='eva:share-fill' />}
        sx={{
          '& .MuiSpeedDial-fab , & .MuiSpeedDial-fab:hover': {
            width: 48,
            height: 48,
            backgroundColor: '#231F3A',
          },
        }}
      >
        {_socials.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={<Iconify icon={action.icon} sx={{ color: action.color }} />}
            tooltipTitle={action.name}
            tooltipPlacement='left'
            FabProps={{ color: 'default' }}
            onClick={() => shareClickHandler(action.value)}
          />
        ))}
      </SpeedDial>
    </Stack>
  );
}

// ----------------------------------------------------------------------
