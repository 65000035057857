import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../../ApiUrl';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { format } from 'date-fns';
import {  Divider, MenuItem, Stack, TextField, InputAdornment, Autocomplete, Card, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../routes';
import { reportTimePeriods } from '../../../reports/common';
import { formatDate } from '../../../../utils/common';
import { ButtonResetFilter, Iconify } from '../../../../components';
import { StyledDatePicker } from '../../../../components/datePicker';
import { FilterDropdown, Highlight, HighlightSkeleton } from '../../../reports/components';
import { CLEAR_INWARD_REMITTANCE, UPDATE_INWARD_REMITTANCE } from '../../../../redux/actionTypes';
import ProgrammedLiensTable from '../programmedLiensTable';
import ClosureTxnsModal from '../../../../components/closureTxnsModal';
import ContractFilesModal from '../../../../components/contractFilesModal/ContractFilesModal';
function ProgrammedContracts() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [highlights, setHighlights] = useState([{heading:'Total Pending Contracts',data: 23, meta:{color:'green'}  }, {heading:'Total Settled Contracts',data: 23, meta:{color:'brown'}  },{heading:'Total Borrowings Lifetime',data: 23, meta:{color:'brown'}  } ]);
  const [showContractFilesModal, setShowContractFilesModal] = useState(true);

  const [showClosureTxnsModal, setShowClosureTxnsModal] = useState(true);
  const [counts, setCounts] = useState({
    pending: 0,
    processed: 0,
    all: 0,
  });
  const { configReducer } = useSelector((state) => state);
  const filters = useSelector((state) => state.filters.remittances.inward);
  // const { coins = [] } = walletsReducer;
  const { remittanceStatuses: allStatuses = [] } = configReducer;

  const dispatch = useDispatch();
  const history = useHistory();
  const handleClearFilters = () => {
    dispatch({ type: CLEAR_INWARD_REMITTANCE });
  };

  const getCounts = useCallback(async (filters) => {
    console.log(filters);
    const created_date_before = formatDate(filters?.calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(filters?.calenderValue[0], 'yyyy-MM-dd');
    merchantApi(`${API.REMITTANCE_INCOMING}`, {
      params: {
        limit: 1,
        created_date_before,
        created_date_after,
      },
    })
      .then((response) => {
        const { count, pending, complete } = response.data;
        setCounts({
          pending,
          processed: complete,
          all: count,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
      });
  }, []);

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const { calenderValue, pageSize, currentPage, search, status, statusValue } = filters;
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');
    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      search,
      status: status?.id,
      created_date_after,
      created_date_before,
    };
    // If table status is all, only then the status filter value will be passed
    if (statusValue === 'processed' || statusValue === 'payout_done') {
      let completedId = allStatuses
        .filter((status) => status.sender_can_change_to.length === 0 && status.receiver_can_change_to.length === 0)
        .map((status) => status.id);
      delete params.status;
      let finalStatusIds = completedId.reduce((prev, curr) => {
        return `${prev},${curr}`;
      });
      if (statusValue === 'processed') {
        params.status_not = finalStatusIds;
      } else {
        params.status = finalStatusIds;
      }
      console.log(params, statusValue);
    }

    merchantApi
      .get(`${API.REMITTANCE_INCOMING}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [filters, allStatuses]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, filters.status, getTrans]);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('here');
      getCounts(filters);
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.calenderValue, getCounts]);

  const viewDetailsHandler = (id) => {
    // history.push(Routes.IncomingPayment.path + `/${id}`);
    toggleClosureTxnsModal()
};

const viewContractFilesHandler = (id) => {
  // history.push(Routes.IncomingPayment.path + `/${id}`);
  setShowContractFilesModal()
};

  const toggleClosureTxnsModal = () => {
    setShowClosureTxnsModal((state) => !state);
  };

  const toggleContractFilesModal = () => {
    setShowContractFilesModal((state) => !state);
  };



  return (
    <Box sx={{ marginTop: '1.5rem' }}>
      {(highlightLoading || highlights?.length > 0) ?
      <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2, overflow:'auto' }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={50}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>
       : null}
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          width={'100%'}
        >
          <Stack sx={{ flexDirection: 'row', gap: 1 }} divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}>
            <Stack sx={{ minWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
              <Stack sx={{ minWidth: '200px', flex: 1 }}>
                <TextField
                  fullWidth
                  size='small'
                  value={filters.search}
                  onChange={(e) =>
                    dispatch({
                      type: UPDATE_INWARD_REMITTANCE,
                      payload: {
                        search: e.target.value,
                      },
                    })
                  }
                  placeholder='Search...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: '200px', flex: 1 }}>
              <Autocomplete
                id='status'
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: UPDATE_INWARD_REMITTANCE,
                    payload: {
                      status: newValue ?? null,
                      statusValue: '',
                    },
                  });
                }}
                options={allStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select Status' {...params} size='small' />;
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <ProgrammedLiensTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          viewContractFiles={viewContractFilesHandler}
          counts={counts}
        />
        <ClosureTxnsModal isOpen={showClosureTxnsModal} onClose={toggleClosureTxnsModal}/>
        <ContractFilesModal isOpen={showContractFilesModal} onClose={toggleContractFilesModal}/>
    </Box>
  );
}

export default ProgrammedContracts;
