import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faExternalLinkAlt,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Nav,
  Card,
  Image,
  Table,
  Dropdown,
  Button,
  ProgressBar,
  Pagination,
  ButtonGroup,
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import {
  TiStarOutline,
  TiStarHalfOutline,
  TiStarFullOutline,
  TiMessages,
} from 'react-icons/ti';
import { Routes } from '../routes';
import {
  pageTraffic,
  pageRanking,
  invoiceList,
  refundList,
  fundingList,
  demoData,
  reportList,
  transactionList,
  withdrawlList,
  customersList,
  supportTable,
} from '../data/tables';
import transactions from '../data/transactions';
import commands from '../data/commands';
import { SelectPicker, ButtonToolbar } from 'rsuite';
import { FiPlus, FiSave } from 'react-icons/fi';
import save from '../assets/img/sideicon/save.svg';
import { BiEdit } from 'react-icons/bi';
import CustomerDetails from '../pages/CustomerDetails';
import _ from 'lodash';
import TablePagination from '@mui/material/TablePagination';

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? 'text-danger' : 'text-success';

  return value ? (
    <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
        {suffix}
      </span>
    </span>
  ) : (
    '--'
  );
};

export const TicketData = () => {
  const TableRow = (props) => {
    const { subject, subname, lastreply, status } = props;

    return (
      <tr>
        <td className="txt-oflo font-12  font-fam">
          {subject} <br /> <span className="text-black-50">{subname}</span>{' '}
        </td>
        <td className="txt-oflo font-12  font-fam">{lastreply}</td>
        <td className="txt-oflo font-12  font-fam">{status}</td>
        <td className="txt-oflo font-700 font-12 ">
          <Link to={Routes.TicketReply.path}>
            <Button variant="default" className="font-12">
              <TiMessages size="1.5em" /> Open Ticket
            </Button>
          </Link>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table
        responsive
        className="align-items-center table-flush font-fam border-1"
      >
        <thead>
          <tr>
            <th scope="col" className="font-12 font-fam">
              Subject
            </th>
            <th scope="col" className="font-12 font-fam">
              Last Reply
            </th>
            <th scope="col" className="font-12 font-fam">
              Status
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {supportTable.map((ul) => (
            <TableRow key={`support-table-${ul.id}`} {...ul} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const {
      id,
      source,
      sourceIcon,
      sourceIconColor,
      sourceType,
      category,
      rank,
      trafficShare,
      change,
    } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">
            {id}
          </Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon
            icon={sourceIcon}
            className={`icon icon-xs text-${sourceIconColor} w-30`}
          />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : '--'}</td>
        <td>{rank ? rank : '--'}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar
                variant="primary"
                className="progress-lg mb-0"
                now={trafficShare}
                min={0}
                max={100}
              />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map((pt) => (
              <TableRow key={`page-traffic-${pt.id}`} {...pt} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const {
      country,
      countryImage,
      overallRank,
      overallRankChange,
      travelRank,
      travelRankChange,
      widgetsRank,
      widgetsRankChange,
    } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image
              src={countryImage}
              className="image-small rounded-circle me-2"
            />
            <div>
              <span className="h6">{country}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{overallRank ? overallRank : '-'}</td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">{travelRank ? travelRank : '-'}</td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">{widgetsRank ? widgetsRank : '-'}</td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map((r) => (
              <TableRow key={`ranking-${r.id}`} {...r} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = () => {
  const totalTransactions = transactions.length;

  const TableRow = (props) => {
    const { invoiceNumber, subscription, price, issueDate, dueDate, status } =
      props;
    const statusVariant =
      status === 'Paid'
        ? 'success'
        : status === 'Due'
        ? 'warning'
        : status === 'Canceled'
        ? 'danger'
        : 'primary';

    return (
      <tr>
        <td>
          <Card.Link as={Link} to={Routes.Invoice.path} className="fw-normal">
            {invoiceNumber}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{subscription}</span>
        </td>
        <td>
          <span className="fw-normal">{issueDate}</span>
        </td>
        <td>
          <span className="fw-normal">{dueDate}</span>
        </td>
        <td>
          <span className="fw-normal">${parseFloat(price).toFixed(2)}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Bill For</th>
              <th className="border-bottom">Issue Date</th>
              <th className="border-bottom">Due Date</th>
              <th className="border-bottom">Total</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t) => (
              <TableRow key={`transaction-${t.invoiceNumber}`} {...t} />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalTransactions}</b> out of <b>25</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map((u) => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre>
            <Card.Link href={link} target="_blank">
              Read More{' '}
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" />
            </Card.Link>
          </pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table
          responsive
          className="table-centered rounded"
          style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>
                Name
              </th>
              <th className="border-0" style={{ width: '5%' }}>
                Usage
              </th>
              <th className="border-0" style={{ width: '50%' }}>
                Description
              </th>
              <th className="border-0" style={{ width: '40%' }}>
                Extra
              </th>
            </tr>
          </thead>
          <tbody>
            {commands.map((c) => (
              <TableRow key={`command-${c.id}`} {...c} />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const InvoicesData = () => {
  const TableRow = (props) => {
    const { invoiceno, name, date, amount, statusKey } = props;
    const status = {
      success: { color: 'success', label: 'Success' },
      pending: { color: 'warning', label: 'Pending' },
      cancel: { color: 'danger', label: 'Cancel' },
    };

    const statusColor = status[statusKey] ? status[statusKey].color : 'danger',
      statusLabel = status[statusKey] ? status[statusKey].label : 'Loading...';

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {invoiceno}
        </td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {name}
        </td>
        <td className="txt-oflo  font-12 text-center font-fam">{date}</td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {amount}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
          <Button className=" transparent_bg font-12 rounded-pill pt-2 pb-2 pe-4 ps-4 border-0">
            <span className={`text-${statusColor}`}> {statusLabel}</span>
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              INVOICE NO.
            </th>
            <th scope="col" className="font-11">
              NAME
            </th>
            <th scope="col" className="font-11">
              DATE
            </th>
            <th scope="col" className="font-11">
              AMOUNT
            </th>
            <th scope="col" className="font-11">
              STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          {invoiceList.map((il) => (
            <TableRow key={`invoice-list-${il.id}`} {...il} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const UserData = ({ users }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const [searchTerm, setsearchTerm] = useState("");

  const limit = 1;
  const [currentPage, SetcurrentPage] = useState(0);

  const offset = users ? Math.ceil(users.count / limit) : 0;
  if (offset === 1) return null;
  const pages = _.range(1, offset + 1);

  const pagination = (pageNo) => {
    SetcurrentPage(pageNo);
  };

  const TableRow = (users) => {
    const { id, first_name, last_name, email, user_role, is_active } = users;

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center">
          <TiStarOutline size="1.5em" />
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">{id}</td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {first_name} {last_name}
        </td>
        <td className="txt-oflo  font-700 font-12 text-center font-fam">
          {email}
        </td>
        <td className="txt-oflo  font-12 text-center font-fam">{user_role}</td>

        {/* <Link to={Routes.UserApproval.path}  >
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
        <BiEdit size="2em" className="text-black mt-2" />
        </td>
        </Link> */}
        <Link to={'/administration/userApproval/' + id}>
          <td className="txt-oflo font-700 font-12 text-center font-fam ">
            <BiEdit size="2em" className="text-black mt-2" />
          </td>
        </Link>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="currentColor"
                class="bi bi-star"
                viewBox="0 0 16 16"
              >
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
              </svg>
            </th>

            <th scope="col" className="font-11">
              #EMPLOYEE NUMBER
            </th>

            <th scope="col" className="font-11">
              NAME
            </th>
            <th scope="col" className="font-11">
              EMAIL
            </th>
            <th scope="col" className="font-11">
              USER LEVEL
            </th>
            {/* <th scope="col" className="font-11">DATE CREATED</th>
      <th scope="col" className="font-11">STATUS</th> */}
            <th scope="col" className="font-11">
              ACTION
            </th>
          </tr>
        </thead>

        {/* {
          users.results &&  <tbody>
          {users.results.filter((val)=>{
            if(searchTerm === "") {
              return val; 
            } else if (
              val.first_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
          }).map(ul => <TableRow key={`users.results-${ul.id}`} {...ul} />)}
        </tbody>
        } */}

        {users.results && (
          <tbody>
            {users.results
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ul) => (
                <TableRow key={`users.results-${ul.id}`} {...ul} />
              ))}
          </tbody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[7, 10, 20, 50]}
        component="div"
        count={users.count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <nav className="d-flex justofy-content-center">
        <ul className="pagination">
          {
            pages.map((page)=>(
              <li className={
                page === currentPage ? "page-item-active" : "page-item"
              }>
                <p className="page-link"
                onClick={()=> pagination(pages)}>{page}</p></li>

            ))
          }
         
        </ul>
      </nav> */}
    </Card>
  );
};

export const ReportData = () => {
  const TableRow = (props) => {
    const {
      transid,
      merchantid,
      fromva,
      tova,
      amount,
      curre,
      type,
      date,
      statusKey,
    } = props;
    const status = {
      active: { color: 'success', label: 'Active' },
      pending: { color: 'warning', label: 'Pending' },
      cancel: { color: 'danger', label: 'Cancel' },
    };

    const statusColor = status[statusKey] ? status[statusKey].color : 'danger',
      statusLabel = status[statusKey] ? status[statusKey].label : 'Loading...';

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {transid}
        </td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {merchantid}
        </td>
        <td className="txt-oflo  font-12 text-center font-fam">{fromva}</td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {tova}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {amount}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {curre}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {type}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {date}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
          <span className={`text-${statusColor}`}>● {statusLabel}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              TRANSACTION ID
            </th>
            <th scope="col" className="font-11">
              MERCHANT ID
            </th>
            <th scope="col" className="font-11">
              FORM (VA)
            </th>
            <th scope="col" className="font-11">
              TO (VA)
            </th>
            <th scope="col" className="font-11">
              AMOUNT
            </th>
            <th scope="col" className="font-11">
              CUR
            </th>
            <th scope="col" className="font-11">
              TYPE
            </th>
            <th scope="col" className="font-11">
              DATE
            </th>
            <th scope="col" className="font-11">
              STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          {reportList.map((rl) => (
            <TableRow key={`report-list-${rl.id}`} {...rl} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const RecieptData = ({ translist }) => {
  const TableRow = (translist) => {
    const { id, sender, status, type, receiver, coin, value } = translist;

    const statusk = {
      processed: { color: 'success', label: 'Processed' },
      fail: { color: 'danger', label: 'Failure' },
      pending: { color: 'warning', label: 'Pending' },
    };

    const statusColor = statusk[status] ? statusk[status].color : 'danger',
      statusLabel = statusk[status] ? statusk[status].label : 'Loading...';

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">{id}</td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {sender}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {receiver}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {coin}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {value}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {type}
        </td>
        {/* <td className="txt-oflo font-700 font-12 text-center font-fam">{status}</td> */}
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
          <span className={`text-${statusColor}`}>● {statusLabel}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              ID
            </th>
            <th scope="col" className="font-11">
              SENDER
            </th>
            <th scope="col" className="font-11">
              RECEIVER
            </th>
            <th scope="col" className="font-11">
              COIN
            </th>
            <th scope="col" className="font-11">
              VALUE
            </th>
            <th scope="col" className="font-11">
              TYPE
            </th>
            <th scope="col" className="font-11">
              TRANSACTION STATUS
            </th>
          </tr>
        </thead>
        {translist.results && (
          <tbody>
            {translist.results.map((ul) => (
              <TableRow key={`translist.results-${ul.id}`} {...ul} />
            ))}
          </tbody>
        )}
      </Table>
    </Card>
  );
};

export const RefundData = () => {
  const TableRow = (props) => {
    const { store, amount, notes, currency, customerid, date } = props;

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {store}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {date}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {customerid}
        </td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {amount}
        </td>
        <td className="txt-oflo  font-700 font-12 text-center font-fam">
          {notes}
        </td>
        <td className="txt-oflo  font-700 font-12 text-center font-fam">
          {currency}
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              STORE
            </th>
            <th scope="col" className="font-11">
              DATE
            </th>
            <th scope="col" className="font-11">
              CUSTOMER ID
            </th>
            <th scope="col" className="font-11">
              AMOUNT
            </th>
            <th scope="col" className="font-11">
              NOTES
            </th>
            <th scope="col" className="font-11">
              CURRENCY
            </th>
          </tr>
        </thead>
        <tbody>
          {refundList.map((rl) => (
            <TableRow key={`refund-list-${rl.id}`} {...rl} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

// export const FundingData = () => {
//   const TableRow = (props) => {
//     const { from, to, amount, date, statusKey} = props;
//     const status = {
//       success: { color: "success", label: "Success" },
//       pending: { color: "warning", label: "Pending" },
//       fail: { color: "danger", label: "Fail" }
//     };

//     const statusColor = status[statusKey] ? status[statusKey].color : 'danger'
//       , statusLabel = status[statusKey] ? status[statusKey].label : 'Loading...';

//     return (
//       <tr>

//         <td className="txt-oflo  font-12 text-center font-fam">{from}</td>
//         <td className="txt-oflo font-700 font-12 text-center font-fam">{to}</td>
//         <td className="txt-oflo font-700 font-12 text-center font-fam">{amount}</td>
//         <td className="txt-oflo font-700 font-12 text-center font-fam">{date}</td>
//         <td className="txt-oflo font-700 font-12 text-center font-fam ">
//         <span className={`text-${statusColor}`}>● {statusLabel}</span>

//         </td>
//       </tr>
//     );
//   };

//   return (
//     <Card border="none" className="shadow-none">
//       <Table responsive className="align-items-center table-flush font-fam">
//         <thead className="primary_color colorchange">
//           <tr>
//       <th scope="col" className="font-11">FORM </th>
//       <th scope="col" className="font-11">TO </th>
//       <th scope="col" className="font-11">AMOUNT</th>
//       <th scope="col" className="font-11">DATE</th>
//       <th scope="col" className="font-11">STATUS</th>
//           </tr>
//         </thead>
//         <tbody>
//           {fundingList.map(rl => <TableRow key={`funding-list-${rl.id}`} {...rl} />)}
//         </tbody>
//       </Table>
//     </Card>
//   );
// };

export const TransactionData = () => {
  const TableRow = (props) => {
    const { balvalue, currency, lastupdate, statusKey } = props;
    const status = {
      active: { color: 'success', label: 'Success' },
      pending: { color: 'warning', label: 'Pending' },
      cancel: { color: 'danger', label: 'Failed' },
    };

    const statusColor = status[statusKey] ? status[statusKey].color : 'danger',
      statusLabel = status[statusKey] ? status[statusKey].label : 'Loading...';

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {balvalue}
        </td>
        <td className="txt-oflo color-blue font-700 font-12 text-center font-fam">
          {currency}
        </td>
        <td className="txt-oflo  font-12 text-center font-fam">{lastupdate}</td>
        <td className="txt-oflo font-700 font-12 text-center font-fam ">
          <span className={`text-${statusColor}`}> {statusLabel}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="border-top-0 font-11">
              Balance Value
            </th>
            <th scope="col" className="border-top-0 font-11">
              Currency
            </th>
            <th scope="col" className="border-top-0 font-11">
              Last Update
            </th>
            <th scope="col" className="border-top-0 font-11">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {transactionList.map((tl) => (
            <TableRow key={`transaction-list-${tl.id}`} {...tl} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const WithdrawlData = () => {
  const TableRow = (props) => {
    const { numid, date, amount, valueincad, status, address, transid } = props;

    return (
      <tr>
        <td>
          {' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bicon bi bi-currency-bitcoin"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 13v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.5v1.25c0 .138.112.25.25.25h1a.25.25 0 0 0 .25-.25V13h.084c1.992 0 3.416-1.033 3.416-2.82 0-1.502-1.007-2.323-2.186-2.44v-.088c.97-.242 1.683-.974 1.683-2.19C11.997 3.93 10.847 3 9.092 3H9V1.75a.25.25 0 0 0-.25-.25h-1a.25.25 0 0 0-.25.25V3h-.573V1.75a.25.25 0 0 0-.25-.25H5.75a.25.25 0 0 0-.25.25V3l-1.998.011a.25.25 0 0 0-.25.25v.989c0 .137.11.25.248.25l.755-.005a.75.75 0 0 1 .745.75v5.505a.75.75 0 0 1-.75.75l-.748.011a.25.25 0 0 0-.25.25v1c0 .138.112.25.25.25L5.5 13zm1.427-8.513h1.719c.906 0 1.438.498 1.438 1.312 0 .871-.575 1.362-1.877 1.362h-1.28V4.487zm0 4.051h1.84c1.137 0 1.756.58 1.756 1.524 0 .953-.626 1.45-2.158 1.45H6.927V8.539z" />
          </svg>
        </td>
        <td className="txt-oflo font-700 font-12 text-center">{numid}</td>
        <td className="txt-oflo  font-12 text-center">{date}</td>
        <td className="txt-oflo color-blue font-700 font-12 text-center">
          {amount}
        </td>
        <td className="txt-oflo  color-blue font-700 font-12 text-center">
          {valueincad}
        </td>
        <td className="txt-oflo font-12 text-center">{status}</td>
        <td className="txt-oflo font-12 text-center">
          <b className="font-14">{address}</b>
          <br /> <span className="darkgrey">{transid}</span>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col"></th>
            <th scope="col" className="font-11">
              ID
            </th>
            <th scope="col" className="font-11">
              DATE
            </th>
            <th scope="col" className="font-11">
              AMOUNT
            </th>
            <th scope="col" className="font-11">
              VALUE IN CAD
            </th>
            <th scope="col" className="font-11">
              STATUS
            </th>
            <th scope="col" className="font-11">
              ADDRESS / TRANSACTION ID
            </th>
          </tr>
        </thead>
        <tbody>
          {withdrawlList.map((wl) => (
            <TableRow key={`withdrawl-list-${wl.id}`} {...wl} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const BalanceData = ({ balanceMap, userCurrency }) => {
  const TableRow = (props) => {
    const { coin, value, value_in_currency } = props;

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center">{coin}</td>
        <td className="txt-oflo color-blue font-700 font-12 text-center">
          {value}
        </td>
        <td className="txt-oflo  color-blue font-700 font-12 text-center">
          {value_in_currency} {userCurrency}
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              COIN
            </th>
            <th scope="col" className="font-11">
              VALUE
            </th>
            <th scope="col" className="font-11">
              VALUE IN {userCurrency}
            </th>
          </tr>
        </thead>
        <tbody>
          {balanceMap.map((wl) => (
            <TableRow key={`withdrawl-list-${wl.coin}`} {...wl} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const CustomerData = () => {
  const TableRow = (props) => {
    const { custid, name, email, phone } = props;

    return (
      <tr>
        <td className="txt-oflo font-700 font-12 text-center">
          <TiStarOutline size="1.5em" />
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {custid}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {name}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam color-blue">
          {email}
        </td>
        <td className="txt-oflo font-700 font-12 text-center font-fam">
          {phone}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to={Routes.CustomerDetails.path}>
                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                </Link>
              </Dropdown.Item>

              <Dropdown.Item>
                <Link to={Routes.Reports.path}>
                  <FontAwesomeIcon icon={faDollarSign} className="me-2" /> View
                  Transaction
                </Link>
              </Dropdown.Item>

              {/* <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="none" className="shadow-none ">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              <TiStarFullOutline size="1.5em" />
            </th>
            <th scope="col" className="font-11">
              CUSTOMER ID
            </th>
            <th scope="col" className="font-11">
              NAME
            </th>
            <th scope="col" className="font-11">
              EMAIL
            </th>
            <th scope="col" className="font-11">
              PHONE NUMBER
            </th>
            <th scope="col" className="font-11">
              ACTION
            </th>
          </tr>
        </thead>
        <tbody>
          {customersList.map((c) => (
            <TableRow key={`customers-list-${c.id}`} {...c} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export const InvoiceCreate = () => {
  const styles = { width: 224, display: 'block', marginBottom: 10 };

  return (
    <Card border="none" className="shadow-none ">
      <Table responsive className="align-items-center table-flush font-fam">
        <thead className="primary_color colorchange">
          <tr>
            <th scope="col" className="font-11">
              PRODUCT DESCRIPTION
            </th>
            <th scope="col" className="font-11">
              RATE/ITEM
            </th>
            <th scope="col" className="font-11">
              QTY
            </th>
            <th scope="col" className="font-11">
              TOTAL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="txt-oflo font-700 font-12 text-center font-fam">
              <label className="font-fam"></label>
              <SelectPicker
                size="lg"
                placeholder="Search an item"
                data={demoData}
                style={styles}
                className="rounded-pill"
              />
            </td>
            <td className="txt-oflo font-700 font-12 text-center font-fam">
              <div className="rounded-pill light-bg p-2">0.00</div>
            </td>
            <td className="txt-oflo font-700 font-12 text-center font-fam">
              01
            </td>
            <td className="txt-oflo font-700 font-12 text-center font-fam">
              $0.00
            </td>
          </tr>

          <Col xl={12} sm={12} xs={12} className="mt-2 mb-5">
            <Button apperance="subtle" className="color-blue font-fam">
              <FiPlus size="1.1em" /> Add Item
            </Button>
          </Col>
        </tbody>
      </Table>
      <Col xl={12} sm={12} xs={12} className="mt-5 ">
        <h4 className="pull-right text-black"> Total Amount: $0.00</h4>
      </Col>

      <Row className=" mt-4">
        <Col xl={6} sm={6} xs={12}>
          <div class="form-floating mb-2 ">
            <input
              type="text"
              class="form-control radi light_bg font-fam "
              placeholder="hello"
            />
            <label for="notes">Customer Notes</label>
          </div>
        </Col>

        <Col xl={6} sm={6} xs={12}>
          <div class="form-floating mb-2 ">
            <input
              type="text"
              class="form-control radi light_bg font-fam "
              placeholder="hello"
            />
            <label for="terms">Terms & Conditions</label>
          </div>
        </Col>
      </Row>
      <hr />
      <Col xs={12} xl={12}>
        <ButtonToolbar className="d-flex justify-content-center mt-5 w-50 pull-right">
          <Button appearance="default" className="rounded-pill w-50">
            <FiSave size="1.5em" /> Save Invoice
          </Button>
          <Button
            appearance="subtle"
            className="primary_color text-white rounded-pill w-50"
          >
            <img src={save} /> Finalize and Issue
          </Button>
        </ButtonToolbar>
      </Col>
    </Card>
  );
};
