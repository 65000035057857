import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Stack,
  Typography,
  Autocomplete,
} from "@mui/material";
import { TextField, MenuItem } from "@material-ui/core";
import { Button } from "rsuite";
import { Upload } from "../../../../../components/upload";
import { allowedMediaTypes } from "../../../../../utils/common";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextFieldComponent, TextFieldSelect } from "../../../../../components";
import SearchNotFound from "../../../../../components/SearchNotFound";

const AssignSubAccountModal = ({
  isOpen,
  onClose,
  updateClickHandler,
  mtoPatchLoading,
  canChangeTo = [],
}) => {
    const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState(null);
  const [searchResults, setSearchResults] = useState([])

  const yupObject = {
    status: yup.string("Select a status").required("Status is required."),

    remarks: yup.string("Write a remark"),
    document: yup.mixed("Select a document").when("status", {
      is: (value) => {
        console.log(value);
        return value === 3;
      },
      then: yup
        .mixed("Select a document")
        .required("Document image is required."),
    }),
    // .required("Document image is required."),
  };

  const validationSchema = yup.object(yupObject);

  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    // validateOnChange: true,
  });

  const getOption = useCallback((value) => {
    const { first_name, last_name, country } = value;
    return `${first_name} ${last_name} ${country ? `(${country.name})` : ""}`;
  }, []);

  useEffect(() => {

    // Code to fetch options and set in setSearchResults
  }, [inputValue]);

  const toggle = () => {
    // toggle modal to add subaccount
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"sm"}
      onClose={onClose}
      scroll={"body"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Assign Sub Account</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0 !important" }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: "repeat(1, 1fr)" },
          }}
        >
          <Autocomplete
            disablePortal
            sx={{ flexGrow: 1 }}
            id="search"
            getOptionLabel={(option) => {
              return getOption(option);
            }}
            value={value}
            isOptionEqualToValue={(option, value) => {
              return option?.id === value?.id;
            }}
            options={searchResults}
            onInputChange={(e, value) => setInputValue(value)}
            onChange={(e, value) => {
              if (value) {
                setValue(value)
              }
            }}
            noOptionsText={
              <SearchNotFound toggle={toggle} searchQuery={inputValue} />
            }
            renderInput={(params) => {
              return (
                <Stack
                  direction={"column"}
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexGrow: 1,
                  }}
                  spacing={1}
                >
                  <Typography>Sub Account</Typography>
                  <TextField
                    {...params}
                    variant="outlined"
                    name={'sub-account'}
                    placeholder={`Search Sub Account`}
                    type="text"
                  />
                </Stack>
              );
            }}
            renderOption={(props, option) => {
              const { id } = option;
              return (
                <li {...props} key={id}>
                  <Typography variant="body2">{getOption(option)}</Typography>
                </li>
              );
            }}
          />

          <TextFieldComponent
            multiline
            rows={3}
            formik={formik}
            name={"remarks"}
            label={"Remarks"}
            type={"text"}
          />
        </Box>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            className="btn white-btn action-button "
            onClick={onClose}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              updateClickHandler(
                formik.values.status,
                formik.values.remarks,
                formik.values.document
              )
            }
            loading={mtoPatchLoading}
            disabled={!formik.isValid}
            className={`btn action-button primary_color text-white`}
            appearance="primary"
          >
            Assign Sub Account
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AssignSubAccountModal;
