import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../../redux/api/apiConfig';
import * as API from '../../../ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { FilterDropdown, HighlightSkeleton } from '../components';
import { Box, Stack, MenuItem, Autocomplete, TextField, Card, Divider, OutlinedInput, InputAdornment } from '@mui/material';
import { reportTimePeriods } from '../common';
import { ButtonPrimary, ButtonSecondary, ButtonResetFilter, Iconify, TextFieldComponent } from '../../../components';
import { format as Format } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Highlight } from '../components';
import { StyledDatePicker } from '../../../components/datePicker';
import usePageTitle from '../../../hooks/usePageTitle';
import { formatDate } from '../../../utils/common';
import { UPDATE_TRANSACTION_REPORT } from '../../../redux/actionTypes';
import TotalPaymentsReportTable from './totalPaymentsReportTable';

function TotalPaymentsReport() {
  const [accountOptions, setAccountOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightLoading, setHighlightLoading] = useState(false);

  const [transactionReport, setTransactionReport] = useState({
    format: 'excel',
    calenderValue: [new Date(reportTimePeriods[1].from), null],
    period: reportTimePeriods[1],
    currentPage: 0,
    pageSize: 7,
    mtoAccount: null,
    currency: null,
    amount_max: 0,
    amount_min: 0,
  });
  const [translist, setTranslist] = useState({ count: 0 });
  const [highlights, setHighlights] = useState([]);

  const {
    profileReducer: { user: { mto: { currency: { code } = {} } = {} } = {} },
    walletsReducer: { wallets = [] } = {},
  } = useSelector((state) => state);

  const filters = useSelector((state) => state.filters.reports.transactionReport);

  const dispatch = useDispatch();

  let filteredWallets = [];

  filteredWallets = wallets?.filter((wallet) => wallet.trust_line_set);

  usePageTitle('Total Payments Report', 'Total Payments Analytics');
  const getAccounts = useCallback(async () => {
    try {
      const response = await merchantApi.get(`${API.MTO_ACCOUNTS}`, {
        params: {
          limit: 7,
        },
      });

      if (response.data) {
        if (!filters.mtoAccount) {
          dispatch({
            type: UPDATE_TRANSACTION_REPORT,
            payload: { mtoAccount: response.data.results[0] },
          });
        }

        setAccountOptions(response.data.results);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, []);

  const getReport = useCallback(async () => {
    setLoading(true);
    const { format, calenderValue, pageSize, currentPage, mtoAccount, currency, amount_max, amount_min } = filters;

    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

    const params = {
      created_date_after,
      created_date_before,
      file_format: format,
      limit: pageSize,
      offset: pageSize * currentPage,
      account: mtoAccount?.id,
      coin: currency?.coin?.id,
      amount_max: amount_max > 0 ? amount_max : '',
      amount_min: amount_min > 0 ? amount_min : '',
    };
    try {
      const response = await merchantApi.get(API.TRANSACTION_REPORT, {
        params,
      });
      setTranslist(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  }, [filters]);

  const getHighlights = useCallback(async () => {
    setHighlightLoading(true);
    try {
      const { mtoAccount, currency, calenderValue, pageSize, currentPage, amount_max, amount_min } = filters;

      const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
      const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

      const params = {
        account: mtoAccount?.id,
        coin: currency.coin.id,
        created_date_after,
        created_date_before,
        limit: pageSize,
        offset: pageSize * currentPage,
        amount_max: amount_max > 0 ? amount_max : '',
        amount_min: amount_min > 0 ? amount_min : '',
        transaction_type: '',
      };
      const response = await merchantApi.get(`${API.TRANSACTION_REPORT}highlight/`, {
        params,
      });
      setHighlights(response.data.data);
      setHighlightLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setHighlightLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    if (wallets) {
      const filteredWallets = wallets.filter((wallet) => wallet.trust_line_set);
      if (!filters.currency) {
        dispatch({
          type: UPDATE_TRANSACTION_REPORT,
          payload: { currency: filteredWallets?.[0] },
        });
      }
    }
  }, [wallets, dispatch, filters.currency]);

  useEffect(() => {
    // const { currency, mtoAccount } = filters;
    if (filters.mtoAccount && filters.currency) {
      getReport();
      console.log('sdc');
      getHighlights();
    }
  }, [
    filters.pageSize,
    filters.currentPage,
    filters.currency,
    filters.mtoAccount,
    // getHighlights,
    // getReport,
  ]);
  const resetFilters = () => {
    dispatch({
      type: UPDATE_TRANSACTION_REPORT,
      payload: {
        mto: [],
        status: [],
        format: 'excel',
        calenderValue: [new Date(reportTimePeriods[1].from), null],
        period: reportTimePeriods[1],
        currentPage: 0,
        pageSize: 7,
        sortModel: [],
        mtoAccount: accountOptions[0],
        currency: filteredWallets[0],
        amount_max: 0,
        amount_min: 0,
      },
    });
  };

  const download = (type) => {
    if (type === 'excel' && translist?.file) {
      window.open(translist?.file, '_blank');
    }
    if (type === 'pdf' && translist?.doc) {
      window.open(translist?.doc, '_blank');
    }
  };
  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>

        <Stack sx={{ width: '100%', gap: 1, mb: 2 }}>
          <Stack sx={{ padding: '5px 0' }} direction={'row'} justifyContent={'space-between'} gap={1} mt={2} width={'100%'}>
            <Stack
              sx={{
                flexDirection: 'row',
                gap: 1,
                flex: 1,
                overflow: 'auto',
                maxWidth: 'calc(100% - 320px)',
                px: 1,
                py: 1,
              }}
              divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}
            >
              <Stack sx={{ maxWidth: '150px', flex: 1 }}>
                <FilterDropdown
                  name='timeperiod'
                  type='text'
                  label='Timeperiod'
                  options={reportTimePeriods}
                  onChange={(e) => {
                    const name = e.target.value;
                    const period = reportTimePeriods.find((report) => report.name === name);

                    let toValue;
                    if (filters.calenderValue[1]) {
                      toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                    } else {
                      toValue = null;
                    }
                    dispatch({
                      type: UPDATE_TRANSACTION_REPORT,
                      payload: {
                        calenderValue: [new Date(period.from), toValue],
                        period,
                      },
                    });
                  }}
                  value={filters?.period?.name}
                  size={'small'}
                  render={(period, i) => (
                    <MenuItem key={period.name} value={period.name}>
                      {period.name}
                    </MenuItem>
                  )}
                />
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 1 }}>
                <Stack sx={{ width: '165px', flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[0]}
                    disableFuture
                    format={'dd/MM/yyyy'}
                    onChange={(newValue) => {
                      console.log(newValue > filters.calenderValue[1], newValue, filters.calenderValue[1]);
                      dispatch({
                        type: UPDATE_TRANSACTION_REPORT,
                        payload: {
                          calenderValue: [
                            new Date(Format(newValue, 'yyyy/MM/dd')),
                            newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                          ],
                        },
                      });
                    }}
                  />
                </Stack>

                <Stack sx={{ width: '165px', flex: 1 }}>
                  <StyledDatePicker
                    value={filters.calenderValue[1]}
                    disableFuture
                    // minDate={filters.calenderValue[0] ?? undefined}
                    format={'dd/MM/yyyy'}
                    onChange={(newValue) => {
                      console.log(newValue > filters.calenderValue[1], newValue, filters.calenderValue[0]);
                      dispatch({
                        type: UPDATE_TRANSACTION_REPORT,
                        payload: {
                          calenderValue: [
                            newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                            new Date(Format(newValue, 'yyyy/MM/dd')),
                          ],
                        },
                      });
                    }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='mtoAccount'
                    fullWidth
                    value={filters.mtoAccount}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: UPDATE_TRANSACTION_REPORT,
                        payload: {
                          mtoAccount: newValue ?? null,
                        },
                      });
                    }}
                    options={accountOptions}
                    getOptionLabel={(option) => `${option?.name}`}
                    // sx={{ flexGrow: 1, width: '100%' }}
                    renderInput={(params) => {
                      return <TextField placeholder='Select MTO account' {...params} size='small' />;
                    }}
                  />
                </Stack>

                {wallets && (
                  <Stack sx={{ minWidth: '205px', flex: 1 }}>
                    <Autocomplete
                      id='currency'
                      value={filters.currency}
                      onChange={(e, newValue) => {
                        dispatch({
                          type: UPDATE_TRANSACTION_REPORT,
                          payload: {
                            currency: newValue ?? null,
                          },
                        });
                      }}
                      options={filteredWallets}
                      getOptionLabel={(option) => `${option?.coin?.name}`}
                      renderInput={(params) => {
                        return <TextField placeholder='Select Currency' {...params} size='small' />;
                      }}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                gap: 1,
                position: 'absolute',
                right: '24px',
              }}
            >
              <ButtonResetFilter onClick={resetFilters} />

              <ButtonSecondary onClick={() => download('excel')}>
                <Iconify icon={'vscode-icons:file-type-excel'} sx={{ width: '20px' }} />
              </ButtonSecondary>
              {/* <ButtonSecondary onClick={() => download('pdf')}>
                <Iconify
                  icon={'vscode-icons:file-type-pdf2'}
                  sx={{ width: '20px' }}
                />
              </ButtonSecondary> */}
              <ButtonPrimary
                onClick={() => {
                  getReport();
                  getHighlights();
                }}
                loading={loading}
              >
                Fetch Report
              </ButtonPrimary>
            </Stack>
          </Stack>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography sx={{ width: '33%', flexShrink: 0 }}>More Filters</Typography>
              <Typography sx={{ color: 'text.secondary' }}>More filters to view specific data analytics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack sx={{ flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
                <Stack sx={{ flexGrow: 1, minWidth: '250px', maxWidth: '50%' }}>
                  <OutlinedInput
                    id='amount-from'
                    size='small'
                    value={filters.amount_min}
                    endAdornment={<InputAdornment position='end'>{code}</InputAdornment>}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value > 0) {
                        dispatch({
                          type: UPDATE_TRANSACTION_REPORT,
                          payload: {
                            amount_min: value,
                          },
                        });
                      } else {
                        dispatch({
                          type: UPDATE_TRANSACTION_REPORT,
                          payload: {
                            amount_min: 0,
                          },
                        });
                      }
                    }}
                    type={'number'}
                    aria-describedby='amount-from'
                    placeholder={'From'}
                    inputProps={{
                      'aria-label': 'amount-from',
                    }}
                  />
                </Stack>

                {/* {wallets && ( */}
                <Stack sx={{ flexGrow: 1, minWidth: '250px', maxWidth: '50%' }}>
                  <OutlinedInput
                    id='amount-to'
                    size='small'
                    value={filters.amount_max}
                    endAdornment={<InputAdornment position='end'>{code}</InputAdornment>}
                    type={'number'}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value > 0) {
                        dispatch({
                          type: UPDATE_TRANSACTION_REPORT,
                          payload: {
                            amount_max: value,
                          },
                        });
                      } else {
                        dispatch({
                          type: UPDATE_TRANSACTION_REPORT,
                          payload: {
                            amount_max: 0,
                          },
                        });
                      }
                    }}
                    aria-describedby='amount-to'
                    placeholder={'To'}
                    inputProps={{
                      'aria-label': 'amount-to',
                    }}
                  />
                </Stack>
                {/* )} */}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <TotalPaymentsReportTable translist={translist} loading={loading} accountTitle={filters?.mtoAccount?.name} />
      </Box>
    </>
  );
}

export default TotalPaymentsReport;
