import React from 'react';
import { Stack, Typography, TextField, MenuItem } from '@mui/material';

const CurrencySelect = ({
  title = 'Amount',
  formik,
  amountName = 'amount',
  currencyName = 'currency',
  options = [],
  optionChangeHandler = () => {},
  amountChangeHandler = () => {},
  disableOptions = false,
}) => {
  console.log('asacascacssc', options)
  return (
    <Stack spacing={0} width={'100%'}>
      <Typography>{title}</Typography>
      <Stack direction={'row'} spacing={0}>
        <TextField
          sx={{ flexGrow: 1 }}
          fullWidth
          placeholder='Enter Amount'
          variant='outlined'
          name={amountName}
          id={amountName}
          className='split-left'
          type={'number'}
          value={formik.values[`${amountName}`]}
          onChange={(e) => {
            amountChangeHandler(e.target.value);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched[`${amountName}`] && Boolean(formik.errors[`${amountName}`])}
          helperText={formik.touched[`${amountName}`] && formik.errors[`${amountName}`]}
        />

        <TextField
          select
          sx={{ flexBasis: '200px' }}
          fullWidth
          disabled={disableOptions}
          variant='outlined'
          name={currencyName}
          id={currencyName}
          className='split-right'
          type={'text'}
          value={formik.values[`${currencyName}`]}
          onChange={(e) => {
            console.log('ascwecvtebsfvdf',formik.values[`${currencyName}`]);
            optionChangeHandler(e.target.value);
            console.log('asdadadasvrby',e);
              formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched[`${currencyName}`] && Boolean(formik.errors[`${currencyName}`])}
          helperText={formik.touched[`${currencyName}`] && formik.errors[`${currencyName}`]}
          placeholder={'CUR'}
        >
          {options?.map((a, i) => (
            <MenuItem key={a?.coin?.id} value={a?.coin?.id}>
              {a?.coin?.symbol}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
};

export default CurrencySelect;
