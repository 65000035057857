import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../../../utils/common';
import { Box, Stack, Typography, LinearProgress, Tooltip } from '@mui/material';
import useDeleteModal from '../../../../../../hooks/useDeleteModal';
import { DeleteModal } from '../../../../../../components/deleteModal';
import HistoryIcon from '@mui/icons-material/History';


const InternalTransferFeesTable = ({
  slabs,
  paginateTable,
  setPaginateTable,
  fetchSlabsLoading,
  setSlabToEdit,
  setIsEdit,
  toggleAddNewSlabModal,
  toggleFeeHistoryModal,
  deleteSlab,
}) => {
  const [deleteId, setDeleteId] = useState('');

  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();

  const permission = useSelector((state) => state.profileReducer?.permission?.configurations?.fees?.inwardRemittanceFees?.permission);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  const user = useSelector((state) => state.profileReducer.user);
  const userCurrency = user?.mto?.currency ?? {};

  const confirmDelete = () => {
    deleteSlab(deleteId);
    toggleShowDeleteModal();
  };

  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Slabs Added' : 'No Slabs Found'}
      </Stack>
    );
  };

  const createDataRow = (slab) => {
    return {
      id: slab.id,
      range: (() => {
        return `${formatCurrency(slab.from_amount, userCurrency.decimal_place, userCurrency.code, userCurrency.symbol_prefix)} - ${formatCurrency(
          slab.to_amount,
          userCurrency.decimal_place,
          userCurrency.code,
          userCurrency.symbol_prefix
        )}`;
      })(),
      fixedCost: (() => {
        return formatCurrency(slab.fixed_cost_fee, userCurrency.decimal_place, userCurrency.code, userCurrency.symbol_prefix) || '--';
      })(),
      percentageCharge: `${Number(slab.percentage_fee).toFixed(2)} %` || '--',
      actions: slab,
    };
  };

  const rows = useMemo(() => (slabs?.results?.length > 0 ? slabs.results.map((slab) => createDataRow(slab)) : []), [slabs.results]);

  const columns = useMemo(
    () => [
      {
        field: 'range',
        headerName: 'Range',
        sortable: false,
        flex: 1,
        minWidth: 300,
        // maxWidth: 250,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },

      {
        field: 'fixedCost',
        headerName: 'Fixed Cost',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: 'percentageCharge',
        headerName: 'Percentage Charge',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },

      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        // flex: 1,
        minWidth: 200,
        // maxWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Stack direction='row' spacing={2}>
              <Stack>
                <Tooltip title='Edit' arrow>
                  <IconButton
                    disabled={!permission?.change[role]}
                    onClick={() => {
                      onEditClicked(params.value);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack>
                <Tooltip title='Delete' arrow>
                  <IconButton
                    disabled={!permission?.delete[role]}
                    onClick={() => {
                      setDeleteId(params.value.id);
                      toggleShowDeleteModal();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack>
                <Tooltip title='Fee History' arrow>
                  <IconButton
                    disabled={!permission?.change[role]}
                    onClick={() => {
                      onShowHistoryClicked(params.value);
                    }}
                  >
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const onEditClicked = (slab) => {
    setIsEdit(true);
    setSlabToEdit(slab);
    toggleAddNewSlabModal();
  };
  const onShowHistoryClicked = (fee) => {
    setSlabToEdit(fee);
    toggleFeeHistoryModal();
  }
  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={tableStyle}
          density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchSlabsLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={slabs.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={confirmDelete}
          text={'the slab'}
          loading={fetchSlabsLoading}
        />
      )}
    </div>
  );
};

export default InternalTransferFeesTable;
