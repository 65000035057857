import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../redux/api/apiConfig';
import 'rsuite/dist/styles/rsuite-default.css';
import { toast } from 'react-toastify';
import * as API from '../../ApiUrl';
import searchIcon from '../../assets/img/icons/searchIcon.svg';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import usePageTitle from '../../hooks/usePageTitle';
import { Box, Stack } from '@mui/material';
import { SuppliersTable } from './components';

const Suppliers = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
  });

  usePageTitle('Suppliers', 'View your suppliers');

  const getUsers = useCallback(async () => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.get(`${API.ADD_SENDER_BUS}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          search: paginateTable.search,
        },
      });
      console.log(response);
      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchUsersLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 500);

    return () => clearTimeout(timer);
  }, [paginateTable.currentPage, paginateTable.pageSize, paginateTable.search, getUsers]);

  const handleSearch = (value) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      search: value,
    });
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} mb={2}>
        <Stack sx={{ maxWidth: '25%', flex: 1 }}>
          <Form onSubmit={(e) => e.preventDefault()} className='w-100 search'>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>
                  <img src={searchIcon} alt='search' />
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  type='text'
                  placeholder='Search'
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Stack>
      </Stack>
      <SuppliersTable
        fetchUsersLoading={fetchUsersLoading}
        users={users}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
      />
     
    </Box>
  );
};

export default Suppliers;
