import React, { useState } from "react";
import { toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
  transactionStatusColors,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";
import { toast } from "react-toastify";
import { Iconify, Label } from "../../../../components";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import RemittanceQrDialog from "../../../../components/RemittanceQrDialog";
export default function PaymentDetailsTable({
  translist,
  fetchTranslistLoading,
  filters,
  currency,
}) {
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [selectedRem, setSelectedRem] = useState(null);
  const copyToClipboard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success("Copied to clipboard");
    } catch (err) {
      toast.error("Unable to copy to clipboard");
    }
  };

  const togglePaymentStatusModal = () => {
    setShowPaymentStatusModal((prev) => !prev);
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction.id,
      submissionDate: formatWithoutTimezone(
        transaction.created_date,
        "dd MMM yyyy HH:mm a"
      ),
      sender: (() => {
        return toLower(transaction?.sender_address);
      })(),

      sentValue: (() => {
        return formatCurrency(
          transaction.amount,
          currency?.decimal_place,
          currency?.symbol
        );
      })(),
      status: transaction?.status,
      type: transaction?.type,
      details: transaction,
    };
  };

  const rows =
    translist?.length > 0
      ? translist?.map((transaction) => createDataRow(transaction))
      : [];

  console.log(rows, translist);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            placement="bottom"
            sx={{ cursor: "default" }}
            title={params.formattedValue}
          >
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "submissionDate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "sender",
      headerName: "Sender Address",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(params?.formattedValue);
              }}
            >
              <Iconify icon={"eva:copy-outline"} />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "sentValue",
      headerName: "Sent Value",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 150,
      headerClassName: "header",
      renderCell: (params) => {
        let { display, value } = params.formattedValue;
        console.log(params?.formattedValue);
        switch (value) {
          case "pending":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[value]}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );
          case "processed":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[value]}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );

          case "failed":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[value]}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );

          case "expired":
            return (
              <Label
                variant={"ghost"}
                color={transactionStatusColors[value]}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );

          default:
            return (
              <Label
                variant={"ghost"}
                color={"application submitted"}
                sx={{ textTransform: "capitalize" }}
              >
                {display || "--"}
              </Label>
            );
        }
      },
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      flex: 1.5,
      minWidth: 120,
      headerClassName: "header",
      renderCell: (params) => {
        let { display, value } = params.formattedValue;
        console.log(params?.formattedValue);
        switch (value) {
          case "refund":
            return (
              <Label
                variant={"ghost"}
                color={'application submitted'}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );
          case "transfer":
            return (
              <Label
                variant={"ghost"}
                color={'pending'}
                sx={{ textTransform: "capitalize" }}
              >
                {display}
              </Label>
            );
          default:
            return (
              <Label
                variant={"ghost"}
                color={"application submitted"}
                sx={{ textTransform: "capitalize" }}
              >
                {display || "--"}
              </Label>
            );
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // align: 'left',
      flex: 1,
      minWidth: 140,
      // maxWidth: 140,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack>
            <Tooltip placement="bottom" title="View in Blockchain">
              <span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if(params?.row?.details?.blockchain_url) {
                      window.open(params?.row?.details?.blockchain_url, "_blank");
                    } else {
                      toast.error("Blockchain url not found");
                    }
                  }}
                >
                  <LinkRoundedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ marginTop: "1.5rem" }}>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "8px",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchTranslistLoading}
        />
      </Box>
      {showPaymentStatusModal && (
        <RemittanceQrDialog
          open={showPaymentStatusModal}
          onClose={() => {
            togglePaymentStatusModal();
          }}
          address={selectedRem?.stb_blockchain_address}
          total={selectedRem?.customer_paid_amount}
          paid={selectedRem?.paid_amount}
          currency={selectedRem?.send_currency}
        />
      )}
    </Box>
  );
}
