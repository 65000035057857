import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { ADD_SENDER_IND, ADD_FINANCIAL_DATA } from '../../../../../ApiUrl';
import { Dialog, DialogTitle } from '@mui/material';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonalInfo from './PersonalInfo';
import FinancialDetails from '../../../../../components/FinancialDetails';
import { useSelector } from 'react-redux';

const AddIndividualClient = ({ onClose, isOpen, isEdit, clientToEdit, onUserAdd }) => {
  const [isDraft, setIsDraft] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [financialDetails, setFinancialDetails] = useState();
  const [userId, setUserId] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({
    first_name: '',
    last_name: '',
    primary_contact_no: '',
    address: '',
    city: '',
    country: '',
    province: '',
    dob: null,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [financialLoading, setFinancialLoading] = useState(false);
  const [personalInfoLoading, setPersonalLoading] = useState(false);

  const id = useSelector((state) => state?.profileReducer?.user?.mto?.country?.id);

  useEffect(() => {
    console.log(isEdit);
    if (isEdit) {
      console.log(clientToEdit);
      const {
        first_name = '',
        last_name = '',
        primary_contact_no = '',
        address = '',
        city = {},
        country = {},
        province = {},
        finance_account,
        id,
        email,
        dob,
      } = clientToEdit;

      let countryCode = '';

      setPersonalInfo({
        first_name,
        last_name,
        primary_contact_no,
        address,
        city: city?.id,
        country: country?.id,
        province: province?.id,
        email,
        dob: dob ? new Date(dob) : null,
      });
      setUserId(id);
      console.log(finance_account[0]);
      setFinancialDetails(finance_account[0]);
    }

    setPersonalInfo((prev) => ({ ...prev, country: id }));

    return () => {
      setUserId(null);
      setPersonalInfo({});
      setFinancialDetails({});
    };
  }, []);

  const changeDraftState = () => {
    setIsDraft((prevState) => !prevState);
  };
  const getcompleted = (r) => {
    return r < activeStep;
  };

  const addSender = async (senderInfo) => {
    setPersonalLoading(true);
    if (!isEdit) {
      try {
        // let id = userId;
        if (!userId) {
          const response = await merchantApi.post(ADD_SENDER_IND, senderInfo);
          let id = response.data.data.id;
          setUserId(id);
          toast.success('User info added successfully');
        } else {
          const response = await merchantApi.patch(`${ADD_SENDER_IND}${userId}/`, senderInfo);
        }
        setActiveStep(activeStep + 1);
        setPersonalInfo(senderInfo);
      } catch (err) {
        console.log(err);
        setPersonalLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    } else {
      try {
        let id = clientToEdit.id;
        const response = await merchantApi.patch(`${ADD_SENDER_IND}${id}/`, {
          ...senderInfo,
          status: clientToEdit.status,
        });
        setActiveStep(activeStep + 1);
        setPersonalInfo(senderInfo);
      } catch (err) {
        console.log(err);
        setPersonalLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }
    setPersonalLoading(false);
  };

  const addFinancialData = async (data) => {
    const { status, type } = data;
    console.log(type);

    setFinancialLoading(true);
    if (!isEdit) {
      try {
        if (type) {
          const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?sender=${userId}`, data);
        }
        const response = await merchantApi.patch(`${ADD_SENDER_IND}${userId}/`, {
          status,
        });

        toast.success('User added successfully.');
        onUserAdd();
        onClose();
      } catch (err) {
        console.log(err);
        setFinancialLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    } else {
      try {
        if (!clientToEdit?.finance_account[0]?.id) {
          console.log('no finance account');
          if (type) {
            const patchResponse = await merchantApi.post(`${ADD_FINANCIAL_DATA}?sender=${userId}`, data);
          }
        } else {
          let id = clientToEdit.finance_account[0].id;
          const patchResponse = await merchantApi.patch(`${ADD_FINANCIAL_DATA}${id}/`, data);
        }
        const response = await merchantApi.patch(`${ADD_SENDER_IND}${userId}/`, {
          status,
        });
        toast.success('User update successful');
        onClose();
      } catch (err) {
        console.log(err);
        setFinancialLoading(false);
        toast.error(err?.data?.data?.message || 'Something went wrong');
      }
    }

    setFinancialLoading(false);
  };

  const handleNext = (data) => {
    if (activeStep === 0) {
      addSender(data);
    }
    if (activeStep === 1) {
      addFinancialData(data);
    }
  };

  const handleBack = () => {
    // seteditable(true);
    setActiveStep(activeStep - 1);
  };

  const getContent = (step) => {
    if (step === 0) {
      return (
        <PersonalInfo
          onClose={onClose}
          handleNext={handleNext}
          personalInfo={personalInfo}
          changeDraftState={changeDraftState}
          provinces={provinces}
          setProvinces={setProvinces}
          isEdit={isEdit}
          loading={personalInfoLoading}
        />
      );
    } else if (step === 1) {
      return (
        <FinancialDetails
          onClose={onClose}
          loading={financialLoading}
          handleNext={handleNext}
          handleBack={handleBack}
          isDraft={isDraft}
          isEdit={isEdit}
          changeDraftState={changeDraftState}
          financialDetails={financialDetails}
          setFinancialDetails={setFinancialDetails}
        />
      );
    }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          // width: '628px',
          // minHeight: '550px',
          // maxHeight: '90vh',
        },
      }}
    >
      <DialogTitle>
        <Stepper activeStep={activeStep}>
          <Step completed={getcompleted(0)}>
            <StepLabel>
              <span className='d-none d-sm-block'>Personal Info</span>
            </StepLabel>
          </Step>

          <Step completed={getcompleted(1)}>
            <StepLabel>
              <span className='d-none d-sm-block'>Financial Details(Optional)</span>
            </StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>
      {getContent(activeStep)}
    </Dialog>
  );
};

export default AddIndividualClient;
