import React from 'react';
import { Button } from 'rsuite';

const ButtonPrimary = ({ children, style, ...other }) => {
  return (
    <Button className='btn load text-white' style={{ width: 'fit-content', ...style }} {...other}>
      {children}
    </Button>
  );
};

export default ButtonPrimary;
