import React from 'react';
import { PreviewContainer, PreviewItem } from '../../../../../components';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
const BasicDetails = ({ client, type }) => {
  let details;
  if (type === 'individual') {
    const { first_name, last_name, xrpl_tag, city, province, country, status, primary_contact_number, email, kyc_status, dob } = client;
    details = (
      <>
        <PreviewItem title={'Name'} content={`${first_name} ${last_name}`} />
        <PreviewItem title={'Xrpl Tag'} content={xrpl_tag} />
        <PreviewItem title={'City'} content={city?.name} />
        <PreviewItem title={'Province'} content={province?.name} />
        <PreviewItem title={'Country'} content={country?.name} />
        <PreviewItem title={'Phone'} content={primary_contact_number} />
        <PreviewItem title={'Email'} content={email} />
        <PreviewItem title={'Date of Birth'} content={dob ? format(new Date(dob), 'MM-dd-yyyy') : null} />
        <PreviewItem title={'Status'} content={capitalize(status)} />
        <PreviewItem title={'KYC Status'} content={capitalize(kyc_status)} />
      </>
    );
  } else {
    const {
      business_name,
      xrpl_tag = '',
      city,
      province,
      country,
      status,
      dob,
      kyc_status,
      primary_contact_name,
      primary_designation,
      primary_contact_no,
      secondary_contact_no,
    } = client;
    details = (
      <>
        <PreviewItem title={'Business Name'} content={business_name} />
        <PreviewItem title={'Primary Contact Name'} content={primary_contact_name} />
        <PreviewItem title={'Primary Contact Designation'} content={primary_designation} />
        <PreviewItem title={'Xrpl Tag'} content={xrpl_tag} />
        <PreviewItem title={'City'} content={city?.name} />
        <PreviewItem title={'Province'} content={province?.name} />
        <PreviewItem title={'Country'} content={country?.name} />
        <PreviewItem title={'Date of Birth'} content={dob ? format(new Date(dob), 'MM-dd-yyyy') : null} />
        <PreviewItem title={'Primary Contact No'} content={primary_contact_no} />
        <PreviewItem title={'Secondary Contact No'} content={secondary_contact_no} />
        <PreviewItem title={'Status'} content={capitalize(status)} />
        <PreviewItem title={'KYC Status'} content={capitalize(kyc_status)} />
      </>
    );
  }

  return <PreviewContainer>{details}</PreviewContainer>;
};

export default BasicDetails;
