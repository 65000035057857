import axios from 'axios';
import { async } from 'validate.js';
import * as API from '../../ApiUrl';
import { GET_BUSINESSTYPE, SET_BUSINESSTYPE, GET_BUSINESSCATEGORY, SET_BUSINESSCATEGORY, SET_BUSINESS_DETAILS } from '../actionTypes';

const getbusiesstype = () => async (dispatch) => {
  dispatch({ type: GET_BUSINESSTYPE });

  const token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.get(`${API.BUSINESS_TYPE_API}`, config);
    console.log('response of the auth', res);
    dispatch({
      type: SET_BUSINESSTYPE,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};
const getbusinesscategory = () => async (dispatch) => {
  dispatch({ type: GET_BUSINESSCATEGORY });

  try {
    const res = await axios.get(`${API.CATEGORY_API}`);
    console.log('response of the auth', res);
    let d = [...res.data.data];
    let d1 = d.map((a) => ({ ...a, option: a.name }));
    dispatch({
      type: SET_BUSINESSCATEGORY,
      payload: d1,
    });
  } catch (err) {
    console.log(err, 'errr');
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

// merchantApi
// .get(API.ADD_MERCHANT_API)
// .then((response) => {
//   console.log(response)
//   setMerchantDetail(response.data);
// })
// .catch((error) => {
//   toast.error(error.response.data.message);
// });

const getBusinessDetails = () => async (dispatch) => {
  let token = localStorage.getItem('accessToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(`${API.ADD_MERCHANT_API}`, config);
    console.log('business Details', res);
    dispatch({
      type: SET_BUSINESS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
export { getbusiesstype, getbusinesscategory, getBusinessDetails };
