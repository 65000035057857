import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import DropdownSearch from '../../../../../components/DropdownSearch';
import { IconButton } from '@mui/material';
import Iconify from '../../../../../components/Iconify';
import { Button } from 'rsuite';
import { UploadDocument } from '../../../../../components/uploadDocumentModal';
import { AddNewCard } from '../../../../../components/addNewCard';

import { Stack, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { PreviewItem, PreviewContainer, CountrySelect } from '../../../../../components';
import { AddIndividualSenderClient, AddBusinessSenderClient } from '../../../../clients/senders/components';
import { DocumentCard } from '../../../../../components/documentCard';
import { toast } from 'react-toastify';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';

const SenderInfo = ({ handleNext, formik, type }) => {
  console.log(formik, type);
  const { remittanceDispatch, transferDetails } = useRemittance();

  const [sender, setSender] = useState(null);
  const [showAddIndividualSender, setShowAddIndividualSender] = useState(false);
  const [showAddBusinessSender, setShowAddBusinessSender] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');


  const countryList = useSelector((state) => state?.configReducer?.countries);

  const countryCodes = countryList?.reduce((acc, cur) => {
    acc.push(cur?.code2);
    return acc;
  }, []);

  const { walletsReducer } = useSelector((state) => state);
  const { coins = [] } = walletsReducer;

  const senderType = type.split('2')[0];

  const toggleShowAddIndividualSender = useCallback(() => {
    setShowAddIndividualSender((prevState) => !prevState);
  }, []);
  const toggleShowAddBusinessSender = useCallback(() => {
    setShowAddBusinessSender((prevState) => !prevState);
  }, []);

  let content;

  if (sender) {
    if (senderType === 'p') {
      content = (
        <>
          <PreviewItem title={'First Name'} content={sender?.first_name} />
          <PreviewItem title={'Last Name'} content={sender?.last_name} />
        </>
      );
    } else if (senderType === 'b') {
      content = (
        <>
          <PreviewItem title={'Business Name'} content={sender?.business_name} />
          <PreviewItem title={'Primary Contact Name'} content={sender?.primary_contact_name} />
        </>
      );
    }
  }

  useEffect(() => {
    setSender(formik?.values?.sender);
    setSelectedCountry(formik?.values?.target_country_id);
  }, [formik]);

  const nextClickHandler = () => {
    handleNext(selectedCountry);
  };
  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  const onDocumentSelect = (id) => {
    let newDocArray = formik?.values?.sender_document;
    if (newDocArray?.includes(id)) {
      newDocArray = newDocArray?.filter((docId) => Number(docId) !== Number(id));
    } else {
      newDocArray.push(Number(id));
    }
    formik.setFieldValue('sender_document', newDocArray);
  };

  const updateSender = async (response) => {
    setRefetch((prevState) => prevState + 1);
    const senderDocs = sender?.sender_document;
    senderDocs.push(response);
    const newSender = { ...sender, sender_document: senderDocs };
    setSender(newSender);
    formik.setFieldValue('sender', newSender);
  };

  const onDocumentDelete = async (id) => {
    try {
      const senderDocs = sender?.sender_document?.filter((doc) => doc.id !== id);
      const newSender = { ...sender, sender_document: senderDocs };
      let newDocArray = formik.values.sender_document;
      if (newDocArray.includes(id)) {
        newDocArray = newDocArray.filter((docId) => Number(docId) !== Number(id));
      }
      setSender(newSender);
      formik.setFieldValue('sender_document', newDocArray);
      formik.setFieldValue('sender', newSender);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  };

  const onSenderChange = useCallback(
    (sender) => {
      formik.setFieldValue('sender', sender);
      setSender(sender);

      formik.setFieldValue('sender_document', []);
    },
    [coins]
  );

  return (
    <>
      <Box>
        <Typography variant={'h6'}>Sender</Typography>
        <Stack direction={'column'} spacing={4}>
          <Box
            sx={{
              display: 'grid',
              columnGap: 16,
              rowGap: 24,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            {/* <Typography variant={'h6'}>Sender:</Typography> */}

            <Stack direction={'row'} alignItems={'flex-end'}>
              <DropdownSearch
                label='Sender'
                name='sender'
                type={type}
                toggle={senderType === 'p' ? toggleShowAddIndividualSender : toggleShowAddBusinessSender}
                value={sender}
                refetch={refetch}
                onChange={onSenderChange}
              />
              <IconButton
                sx={{ height: '60px' }}
                aria-label='add'
                onClick={senderType === 'p' ? toggleShowAddIndividualSender : toggleShowAddBusinessSender}
                // color={'red'}
              >
                <Iconify icon={'eva:plus-square-outline'} width={40} height={30} />
              </IconButton>
            </Stack>
            <CountrySelect
              formik={formik}
              label={'Target Country'}
              name={'target_country'}
              options={countryCodes}
              countryList={countryList}
              onChange={(id) => {
                // setRender((prevState) => prevState + 1);
                remittanceDispatch({
                  type: 'UPDATE_RECIPIENT_INFO',
                  payload: { recipient: null, financeAccount: null },
                });
                remittanceDispatch({ type: 'UPDATE_TRANSFER_DETAILS', payload: { recipient_mto: null } });

                // setRecipientInfo((prevState) => ({
                //   ...prevState,
                //   recipient: null,
                //   financeAccount: null,
                // }));
                formik.setFieldValue('target_country_id', id);
                setSelectedCountry(id);
              }}
            />
          </Box>
          {sender && (
            <PreviewContainer title={'Sender Details'}>
              {content}
              <PreviewItem title={'Phone'} content={sender?.primary_contact_no} />
              <PreviewItem title={'City'} content={sender?.city?.name} />

              <PreviewItem title={'Province'} content={sender?.province?.name} />
              <PreviewItem title={'Country'} content={sender?.country?.name} />
              <PreviewItem title={'Address'} content={sender?.address} />
            </PreviewContainer>
          )}

          {sender && (
            <Stack
              direction={'column'}
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flex: 1,
              }}
              spacing={0.5}
            >
              <Typography variant={'h6'}>Documents</Typography>
              <span>
                <span style={{ color: '#B72136', fontWeight: 'bold' }}>Click on images</span>
                &nbsp; to select one or more documents
              </span>

              {sender?.sender_document?.length === 0 ? (
                <AddNewCard onClick={toggleDocumentUpload} />
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
                      gridGap: '1rem',
                      width: '100%',
                    }}
                  >
                    {sender?.sender_document?.map((document, i) => (
                      <DocumentCard
                        isSelected={formik.values.sender_document.includes(document.id)}
                        documents={document}
                        onDocumentSelect={onDocumentSelect}
                        onDocumentDelete={onDocumentDelete}
                        canDelete={true}
                      />
                    ))}
                    <AddNewCard onClick={toggleDocumentUpload} />
                  </Box>
                </>
              )}
            </Stack>
          )}

          <Stack>
            <Typography variant={'h6'}>Sender Declaration</Typography>
            <FormControlLabel
              control={<Checkbox checked={formik.values.checkbox} />}
              label='This transaction is being done by the sender himself and not on behalf of someone else.'
              name='checkbox'
              onChange={formik.handleChange}
            />
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'end', paddingTop: '24px' }}>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance='primary'
            onClick={nextClickHandler}
            disabled={!formik.isValid || !formik.values.checkbox || sender?.sender_document?.document === null}
          >
            Next
          </Button>
        </Stack>
      </Box>
      {showAddIndividualSender && (
        <AddIndividualSenderClient
          onClose={toggleShowAddIndividualSender}
          isOpen={showAddIndividualSender}
          isEdit={false}
          clientToEdit={{}}
          // This is to trigger re-render after adding user to fetch new list
          onUserAdd={() => {
            setRefetch((state) => state + 1);
            // getUsers();
          }}
        />
      )}
      {showAddBusinessSender && (
        <AddBusinessSenderClient
          onClose={toggleShowAddBusinessSender}
          isOpen={showAddBusinessSender}
          isEdit={false}
          clientToEdit={{}}
          onUserAdd={() => {
            setRefetch((state) => state + 1);
            // getUsers();
          }}
        />
      )}
      {showDocumentUpload && (
        <UploadDocument isOpen={showDocumentUpload} onClose={toggleDocumentUpload} senderId={sender.id} getSenderAccounts={updateSender} />
      )}
    </>
  );
};

export default SenderInfo;
