import { Skeleton } from '@mui/material';
import React from 'react';

const ChatMessageItemSkeleton = ({ sx }) => {
  return (
    <Skeleton
      animation={'wave'}
      variant='rounded'
      height={35}
      // width={50}
      sx={{ width: '30%', marginBottom: 3, ...sx }}
    />
  );
};

export default ChatMessageItemSkeleton;
