import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Iconify } from '../../../components';
import { DocumentDetails, BasicDetails, TransactionDetails } from './components/clientDetails';
import { capitalize } from 'lodash';
import { Box, Tab, Tabs, Stack, Skeleton, Typography } from '@mui/material';
import back from '../../../assets/img/icons/back.svg';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../redux/api/apiConfig';
import { SENDER_IND, ADD_SENDER_BUS } from '../../../ApiUrl';
import usePageTitle from '../../../hooks/usePageTitle';
import Image from '../../../components/image/Image';
import bg from '../../../assets/illustrations/notFound.svg';

const ClientsSenderDetails = () => {
  const [currentTab, setCurrentTab] = useState('details');
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  let componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const filters = Object.fromEntries(query);

  console.log(filters);
  const { id, type } = filters;

  usePageTitle(`${capitalize(type)} Client Details`, `View ${type} client details`);

  const getClient = useCallback(async () => {
    if (type && id) {
      console.log(id, type);
      const URL = type === 'individual' ? SENDER_IND : ADD_SENDER_BUS;
      try {
        setLoading(true);
        const response = await merchantApi.get(`${URL}${id}/`);
        console.log(response);
        setClient(response.data);
        setLoading(false);
      } catch (error) {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setLoading(false);
      }
    }
  }, [id, type]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  const PREVIEW_TABS = [
    {
      value: 'details',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <BasicDetails ref={componentRef} client={client} type={type} />,
    },
    {
      value: 'documents',
      icon: <Iconify icon={'ion:documents'} width={20} height={20} />,
      component: <DocumentDetails client={client} getClient={getClient} type={type} />,
    },
    // {
    //   value: 'wallet transactions',
    //   icon: <Iconify icon={'material-symbols:history-rounded'} width={20} height={20} />,
    //   component: <TransactionDetails client={client} type={type} />,
    // },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  return (
    <Box sx={{ padding: '24px ', mb: '1.5rem' }}>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <span onClick={backClickHandler} className='back mb-2'>
          <img src={back} alt='back' />
          <span>Back</span>
        </span>
      </Stack>

      <Stack
        sx={{
          mb: 2,
          justifyContent: 'space-between',
          alignItems: { md: 'center', xs: 'start' },
          flexDirection: { md: 'row', xs: 'column' },
          gap: 2,
        }}
      >
        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
        >
          {PREVIEW_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalize(tab.value)} iconPosition='start' icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
      </Stack>
      {!loading && !client ? (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 2, mt: 4 }}>
          <Image src={bg} sx={{ width: { sm: '200px', md: '300px' } }} />
          <Typography variant='h6'>No Client Details Found!</Typography>
        </Stack>
      ) : (
        <>
          {!loading ? (
            PREVIEW_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })
          ) : (
            <Skeleton variant='rounded' animation={'wave'} height={type === 'individual' ? 150 : 200} />
          )}
        </>
      )}
    </Box>
  );
};

export default ClientsSenderDetails;
