import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Typography, CardHeader, Stack, IconButton, Tooltip } from '@mui/material';
import { Iconify } from '../../components';
import usePageTitle from '../../hooks/usePageTitle';
import EditIcon from '@mui/icons-material/Edit';

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

const Profile = ({ email = '', country = '', role = 'Admin', company = '', address = '', onEditClick, timezone = '' }) => {
  usePageTitle('', '');

  return (
    <Card sx={{ width: { xs: '100%', md: '30%' } }}>
      <CardHeader
        title='About'
        action={
          <Tooltip title={'Edit Profile'} placement='top'>
            <IconButton aria-label='edit-profile' onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction='row' alignItems={'center'}>
          <StyledIcon icon='eva:email-fill' />
          <Typography variant='body2'>{email}</Typography>
        </Stack>

        <Stack direction='row' alignItems={'center'}>
          <StyledIcon icon='ic:round-business-center' />
          <Typography variant='body2'>{role} at &nbsp;</Typography>
          <Typography variant='subtitle2'>{company}</Typography>
        </Stack>
        <Stack direction='row' alignItems={'center'}>
          <StyledIcon icon='eva:pin-fill' />
          <Typography variant='body2'>{address}</Typography>
        </Stack>
        <Stack direction='row' alignItems={'center'}>
          <StyledIcon icon='file-icons:moment-timezone' />
          <Typography variant='body2'>{timezone}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Profile;
