import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { ButtonPrimary, ButtonSecondary, TextFieldComponent, TextFieldSelect } from '../../../../../components';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { MTO_ACCOUNTS } from '../../../../../ApiUrl';
import { toast } from 'react-toastify';

const NewAccount = ({ isOpen, onClose }) => {
  const [submitLoading, setSubmitLoading] = useState(false);

  const { accountTypes } = useSelector((state) => state.configReducer);
  let user = JSON.parse(localStorage.getItem('user-info'));

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string('Enter your account name').min(2, 'Please enter valid name').required('Account name is required!'),

        type: yup.string('Enter account type').min(2, 'Please enter valid type').required('Account type is required!'),

        remarks: yup.string('Enter remarks'),
      }),
    []
  );
  const submitClickHandler = async (values) => {
    console.log(values);
    try {
      setSubmitLoading(true);
      const response = await merchantApi.post(MTO_ACCOUNTS, {
        mto_type: values.type,
        name: values.name,
        description: values.remarks,
        mto: user.mto.id,
      });
      console.log(response);
      toast.success('Account created successfully');
      setSubmitLoading(false);
      onClose();
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
      toast.error(error?.data?.data?.message || 'Account creation failed');
    }
  };

  const formik = useFormik({
    initialValues: { type: 'operating' },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: submitClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'sm'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>New Account</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ paddingTop: '5px !important' }}>
          <Stack spacing={2} direction={'column'}>
            <TextFieldComponent formik={formik} name='name' type='text' label='Name' />

            <TextFieldSelect
              formik={formik}
              name='type'
              type='text'
              label='Type'
              options={accountTypes}
              render={(account, i) => <MenuItem value={account.value}>{account.name}</MenuItem>}
              disabled
            />

            <TextFieldComponent formik={formik} name={'remarks'} label={'Remarks'} type={'text'} multiline maxRows={3} minRows={3} />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: '24px',
            paddingBottom: '15px',
          }}
        >
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary loading={submitLoading} disabled={!formik.isValid} type={'submit'}>
            Create
          </ButtonPrimary>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewAccount;
