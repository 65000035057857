import React from 'react';
import { LinearProgress, Box, Typography, Tooltip, IconButton, Link, Tabs, Tab } from '@mui/material';
import { formatWithoutTimezone, rowsPerPageOptions, tableCellStyle } from '../../../utils/common';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { formatCurrency } from '../../../utils/common';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';
import { startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TRANSACTION } from '../../../redux/actionTypes';

export default function Transactiontable({ translist, fetchTranslistLoading, resetTransactions = null }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.transactions);
  const handleChangePage = (newPage) => {
    dispatch({ type: UPDATE_TRANSACTION, payload: { currentPage: newPage } });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  const createDataRow = (transaction) => {
    return {
      id: transaction?.reference_no,
      lastUpdate: formatWithoutTimezone(transaction?.created_date, 'dd MMM yyyy HH:mm a'),
      counterParty: transaction?.other_party || '--',
      amount: `${transaction.amount_type === 'credit' ? '+' : '-'} ${formatCurrency(
        transaction.amount_type === 'credit' ? transaction?.credit : transaction?.debit,
        transaction?.coin?.decimal_place,
        transaction?.coin?.symbol
      )}`,
      balance: formatCurrency(transaction?.balance_post_transaction, transaction?.coin?.decimal_place, transaction?.coin?.symbol),
      relatedTo: {
        transactionType: transaction?.transaction_type,
        relatedTo: transaction?.related_transaction,
      },
      transactionData: transaction,
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      // maxWidth: 200,
      headerClassName: 'header',
      // align: 'center',
      // headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Updated',
      flex: 1,
      minWidth: 180,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
   

    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'balance',
      headerName: 'Balance',
      flex: 1.5,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        const { flow_type, type } = params?.row?.transactionData?.transaction_type ?? {};
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {startCase(`${flow_type} ${type}`)}
          </Typography>
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Tooltip placement='top' title={params?.row?.transactionData?.remarks}>
            <Typography variant='body2' style={tableCellStyle}>
              {params?.row?.transactionData?.remarks ?? '--'}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'relatedTo',
      headerName: 'Related To',
      flex: 1.5,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        const { transactionType, relatedTo } = params.formattedValue;
        const type = transactionType?.type;
        const flow_type = transactionType?.flow_type;
        const linkClickHandler = () => {
          if (type === 'remittance') {
            if (flow_type === 'inward') {
              history.push(Routes.IncomingPayment.path + `/${relatedTo.id}`);
            } else if (flow_type === 'outward') {
              history.push(Routes.OutwardRemittance.path + `/${relatedTo.id}`);
            }
          } else if (type === 'redemption') {
            if (flow_type === 'received') {
              history.push(Routes.RedemptionsReceived.path + `/details/${relatedTo.id}`);
            } else if (flow_type === 'raised') {
              history.push(Routes.RedemptionsRaised.path + `/details/${relatedTo.id}`);
            }
          }
        };
        return relatedTo?.ref_no === '' ? (
          '--'
        ) : (
          <Link component='span' variant='subtitle2' color='text.primary' sx={{ cursor: 'pointer', fontWeight: '500' }} onClick={linkClickHandler}>
            {relatedTo?.ref_no === '' ? '--' : relatedTo?.ref_no}
          </Link>
        );
      },
    },

    {
      field: 'counterParty',
      headerName: 'Counter Party',
      flex: 1.5,
      minWidth: 300,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params?.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1.5,
      minWidth: 100,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Tooltip placement='top' title='View in Blockchain'>
            <span onClick={(e) => e.stopPropagation()}>
              <IconButton
                disabled={!params?.row?.transactionData?.blockchain_url}
                href={params?.row?.transactionData?.blockchain_url}
                target={'_blank'}
              >
                <LinkRoundedIcon />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const TABS = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'transfer',
      label: 'Remittances',
    },
    {
      value: 'refund',
      label: 'Refunds',
    },
    {
      value: 'withdraw',
      label: 'Settlements',
    },

    {
      value: 'internal',
      label: 'Internal',
    },
    {
      value: 'fund',
      label: 'Fund',
    },
  ];

  const handleTabChange = (e, value) => {
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: { currentPage: 0, type: value },
    });
    // setpaginateTable((prev) => ({
    //   ...prev,
    //   currentPage: 0,
    //   type: value,
    // }));
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={filters.type}
          onChange={handleTabChange}
          sx={{
            px: 2,
            bgcolor: '#F4F6F8',
            borderRadius: '8px 8px 0 0',
          }}
        >
          {TABS.map((tab) => (
            <Tab value={tab.value} disableRipple key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          density={'compact'}
          rows={rows}
          columns={columns}
          // rowHeight={102}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
