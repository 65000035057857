import React, { useState } from 'react';
import {  Box, Card, Divider, IconButton, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Highlight, HighlightSkeleton } from '../../../reports/components';
import InfoCard from '../../../../components/infoCard/InfoCard';
import { Iconify } from '../../../../components';
import { EditMonthlyFeesModal } from '../../../configurations/fees/feesComponents';

const BusinessDetails = ({ statuses, type }) => {

  const [highlightLoading, setHighlightLoading] = useState(false);
  const [showEditMonthlyFeesModal, setShowEditMonthlyFeesModal] = useState(false)
  const [highlights, setHighlights] = useState([{heading:'Total Pending Liens',data: 23, meta:{color:'green'}  }, {heading:'Total Settled Liens',data: 23, meta:{color:'brown'}  },{heading:'Total Borrowings Lifetime',data: 23, meta:{color:'brown'}  } ]);

  const toggleEditMonthlyFeesModal = () => {
      setShowEditMonthlyFeesModal(prev => !prev)
  }


  return (
    <Box sx={{ marginTop: '1.5rem' }}>
          <Stack direction="row"
                sx={{  mb: 3 , gap:3, flexDirection:{xs:'column', sm:'row'}}}>
          {/* {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (

                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={50}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })} */}
                    <InfoCard
                    title={'Monthly Fee'}
                    number={'24 USD'}
                    icon="la:hand-holding-usd"
                    color={'info'}
                    action={<IconButton onClick={toggleEditMonthlyFeesModal} ><Iconify  icon='eva:edit-fill'/></IconButton>}
                    />
  
          </Stack>
        
         <Accordion sx={{ width: '100%' }} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Business Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>Business Info</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Personal Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>Personal Info</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Business Docs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>Business Docs</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Personal Docs</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>Personal Docs</Typography>
            </AccordionDetails>
          </Accordion>

          {showEditMonthlyFeesModal && (
        <EditMonthlyFeesModal
          onClose={toggleEditMonthlyFeesModal}
          isOpen={showEditMonthlyFeesModal}
          isEdit={true}
          slabToEdit={{}}
          onSubmit={() => {
            toggleEditMonthlyFeesModal();
          }}
        />
      )}
        </Box>
  );
};

export default BusinessDetails;
