import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Stack,
  Autocomplete,
  Typography,
  TextField,
  Alert,
} from '@mui/material';
import { Button } from 'rsuite';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { REMITTANCE_MTO } from '../../../../../ApiUrl';
import { toast } from 'react-toastify';

function SelectMtoModal({
  isOpen,
  onClose,
  nextClickHandler,
  mtoPatchLoading,
  createRemittanceRes,
  mto,
}) {
  const [mtoOptions, setMtoOptions] = useState([]);
  const [selectedMto, setSelectedMto] = useState(null);
  const [msg, setMsg] = useState('');

  const getMtoOptions = async () => {
    console.log(createRemittanceRes);
    const {
      receiver: { country },
      receive_currency,
    } = createRemittanceRes;
    try {
      const response = await merchantApi.get(
        `${REMITTANCE_MTO}?country=${country.id}&coin=${receive_currency.id}`
      );
      console.log(response);
      if (response.data.length === 0) {
        setMsg('No MTO available');
      }
      setMtoOptions(response.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(createRemittanceRes);
    getMtoOptions();
  }, []);

  useEffect(() => {
    setSelectedMto(mto);
  }, [mto]);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'sm'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Select MTO</DialogTitle>
      <DialogContent sx={{ paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
          }}
        >
          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0}
          >
            <Typography>Account</Typography>
            <Autocomplete
              // disablePortal
              id="mto"
              value={selectedMto}
              // isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, newValue) => setSelectedMto(newValue)}
              options={mtoOptions}
              getOptionLabel={(option) => `[${option.id}] ${option.name}`}
              sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
          {msg !== '' && (
            <Alert
              className="my-2"
              style={{
                borderRadius: '8px',
                color: 'rgb(95, 33, 32)',
                backgroundColor: 'rgb(253, 237, 237)',
              }}
              severity="error"
            >
              {msg}
            </Alert>
          )}
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            className="btn white-btn action-button "
            onClick={handleCloseModal}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            onClick={() => nextClickHandler(selectedMto)}
            loading={mtoPatchLoading}
            disabled={!selectedMto}
            className={`btn action-button primary_color text-white`}
            appearance="primary"
          >
            Next
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default SelectMtoModal;
