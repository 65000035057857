import React from 'react';
import { Stack } from '@mui/material';
import { PreviewItem, Label } from '../../../../components';
import { format } from 'date-fns';
import { formatWithoutTimezone, transactionStatusColors } from '../../../../utils/common';

const HistoryItem = ({ status, type }) => {
  let label;
  // const { value, display } = status?.status?;
  console.log(status);
  const value = status?.status?.value;
  const display = type === 'raised' ? status?.status?.sender_name : status?.status?.receiver_name;

  switch (value) {
    case 'pending':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );
      break;

    case 'failed':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );
      break;

    case 'processed':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );

      break;
    case 'rejected':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );
      break;
    case 'expired':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );
      break;

    case 'payout_done':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );

      break;

    case 'redeem_failed':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );

      break;
    case 'redeem_complete':
      label = (
        <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );

      break;

    default:
      label = (
        <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
          {display}
        </Label>
      );
      break;
  }

  return (
    <Stack
      key={status.id}
      spacing={3}
      direction={'row'}
      sx={{
        borderRadius: '8px',
        borderColor: '#000',
        backgroundColor: '#F0F0F0',
        padding: '1rem',
        marginTop: '1.5rem',
        width: { lg: '70%', xs: '100%' },
        justifyContent: 'space-between',
      }}
    >
      {/* {label} */}
      <Stack spacing={0} direction={'row'}>
        <PreviewItem title={'Status'} content={label || ''} sx={{ minWidth: '250px' }} />
        <PreviewItem title={'Remarks'} content={status?.remarks || ''} />
      </Stack>
      <Stack>
        <PreviewItem title={'Date'} content={formatWithoutTimezone(status?.modified_date, 'dd MMM yyyy HH:mm a')} />
      </Stack>
    </Stack>
  );
};

export default HistoryItem;
