import * as actionTypes from '../actionTypes';
import { updateObject } from './utility';

const initialState = {
  loading: false,
  notifications: [],
  error: null,
  hasMore: true,
};

const setNotifications = (action, state) => {
  return updateObject(state, {
    loading: false,
    notifications: action.payload,
  });
};
const setNewNotifications = (action, state) => {
  return updateObject(state, {
    loading: false,
    newNotifications: action.payload,
  });
};

const setNextNotifications = (action, state) => {
  const { page, notifications, hasMore } = action.payload;
  return {
    ...state,
    hasMore,
    notifications:
      page === 0 ? notifications : [...state.notifications, ...notifications],
  };
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION_HISTORY:
      return setNotifications(action, state);
    case actionTypes.SET_NEW_NOTIFICATION_AVAILABLE:
      return setNewNotifications(action, state);
    case actionTypes.SET_NEXT_NOTIFICATIONS:
      return setNextNotifications(action, state);
    default:
      return state;
  }
};

export default notificationReducer;
