import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as API from '../../../ApiUrl';
import { ButtonPrimary } from '../../../components';
import { Transfer } from '../../../components/transfer';
import { PasswordModal } from '../../../components/passwordModal';
import { AccountsTable, NewAccount } from './components';
import { merchantApi } from '../../../redux/api/apiConfig';
import { Box, Stack } from '@mui/material';
import usePageTitle from '../../../hooks/usePageTitle';

function OutwardRemittance() {
  const [accountList, setAccountList] = useState([]);
  const [fetchAccountsLoading, setFetchAccountsLoading] = useState(false);
  const [paginateTable, setpaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
    type: '',
    coin: '',
    store: '',
    status: 'processed',
    fromDate: '',
    toDate: '',
  });
  const [showCreateNewAccount, setShowCreateNewAccount] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [transferData, setTransferData] = useState({});
  const [transferLoading, setTransferLoading] = useState(false);

  usePageTitle('Sub Accounts', 'View your sub accounts');
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const { permission, permissionLoading } = useSelector((state) => state.profileReducer);
  const getAccounts = useCallback(async () => {
    setFetchAccountsLoading(true);
    try {
      const response = await merchantApi.get(`${API.MTO_ACCOUNTS}`, {
        params: {
          offset: paginateTable.currentPage * paginateTable.pageSize,
          limit: paginateTable.pageSize,
          // search: paginateTable.search,
          // type: paginateTable.type,
          // coin: paginateTable.coin,
          // store: paginateTable.store,
          status: paginateTable.status,
          // modified_date_after: paginateTable.fromDate,
          // modified_date_before: paginateTable.toDate,
        },
      });

      setFetchAccountsLoading(false);
      if (response.data) {
        setAccountList(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      setFetchAccountsLoading(false);
    }
  }, [paginateTable]);

  const deleteAccount = async (id) => {
    try {
      const response = await merchantApi.delete(`${API.MTO_ACCOUNTS}${id}`);
      toast.success('Account deleted successfully');
      getAccounts();
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Deleting account failed');
    }
  };

  useEffect(() => {
    getAccounts();
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    paginateTable.type,
    paginateTable.coin,
    paginateTable.store,
    paginateTable.status,
    paginateTable.fromDate,
    paginateTable.toDate,
    // resetData,
    getAccounts,
  ]);

  const toggleCreateNewAccount = useCallback(() => {
    setShowCreateNewAccount((state) => {
      if (state) {
        // fetch accounts on closing the modal
        getAccounts();
      }
      return !state;
    });
  }, []);

  const toggleShowTransfer = () => {
    setShowTransfer((state) => !state);
  };

  const toggleShowPasswordModal = () => {
    setShowPasswordModal((state) => !state);
  };

  const nextClickHandler = (data) => {
    setTransferData(data);
    toggleShowPasswordModal();
    toggleShowTransfer();
  };

  const transferClickHandler = async (password) => {
    try {
      setTransferLoading(true);
      const passwordRespose = await merchantApi.post(API.VERIFY_PASSWORD, {
        password,
      });
      const response = await merchantApi.post(API.MTO_TRANSFER, transferData);
      toast.success('Funds transferred successfully');
      setTransferLoading(false);
      toggleShowPasswordModal();
    } catch (error) {
      setTransferLoading(false);
      toast.error(error?.data?.data?.message || 'Transfer failed');
    }
  };

  return (
    <>
      {showCreateNewAccount && <NewAccount isOpen={showCreateNewAccount} onClose={toggleCreateNewAccount} />}

      {showTransfer && <Transfer isOpen={showTransfer} onClose={toggleShowTransfer} nextClickHandler={nextClickHandler} />}

      {showPasswordModal && (
        <PasswordModal
          isOpen={showPasswordModal}
          onClose={toggleShowPasswordModal}
          nextClickHandler={transferClickHandler}
          loading={transferLoading}
          nextButtonText={'Transfer'}
        />
      )}
      <Box sx={{ padding: '0 24px 24px' }}>
        {/* {true ? ( */}
        {/* <FullLoader /> */}
        {/* ) : ( */}
        <>
          <Stack direction={'row'} gap={2} sx={{ justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
            {permission?.accounts?.account?.permission?.add[role] && <ButtonPrimary onClick={toggleCreateNewAccount}>New Account</ButtonPrimary>}
            {permission?.accounts?.account?.permission?.transfer[role] && <ButtonPrimary onClick={toggleShowTransfer}>Transfer</ButtonPrimary>}
          </Stack>
          <AccountsTable
            accountList={accountList}
            fetchAccountsLoading={fetchAccountsLoading}
            setpaginateTable={setpaginateTable}
            paginateTable={paginateTable}
            deleteAccount={deleteAccount}
          />
        </>
        {/* )} */}
      </Box>
    </>
  );
}

export default OutwardRemittance;
