
export const Routes = {
  // pages
  Signin: { path: "/signin" },
  DashboardOverview: { path: "/" },
  DevDashboard: { path: "/dev" },
  // DashboardOverview: { path: "/dashboard/dashboardoverview" },
  Profile: { path: "/profile" },
  Statement: { path: "/merchantprofile/statement" },
  // Reports
  Reports: { path: "/reports" },
  TotalPaymentsReport: { path: "/reports/total-payments" },
  MTOLedgerDetails: { path: "/reports/mto-ledger-details" },
  WalletMovement: { path: "/reports/wallet-movement" },
  MTOLedgerSummary: { path: "/reports/mto-ledger-summary" },
  TransactionChargesReport: { path: "/reports/transaction-charges" },
  LienReport: { path: "/reports/lien-report" },
  IncomingPaymentReport: { path: "/reports/remittance-report/inward" },
  AverageBalancesReport: { path: "/reports/average-balances" },
  AvailableRedemptionReport: { path: "/reports/pending-redemption-report" },

  // Suppliers
  Suppliers: {path:'/suppliers'},
  SupplierDetail: {path:'/suppliers/:id'},

  // Programmed Liens
  ProgrammedLiens: {path:'/programmed-liens'},
  
  // Clients
  SenderIndividualClients: { path: "/clients/senders/individual" },
  SenderBusinessClients: { path: "/clients/senders/business" },
  ReceiverIndividualClients: { path: "/clients/receivers/individual" },
  ReceiverBusinessClients: { path: "/clients/receivers/business" },
  Deposits: { path: "/clients/senders/deposits" },
  DepositsHistory: { path: "/clients/senders/deposits/:id" },
  ClientsSenderDetails: { path: "/clients/senders/details" },

  // KYC
  UserKyc: { path: "/kyb" },
  UserChatGeneral: { path: "/support/customer/" },
  UserChat: { path: "/support/customer/ticket/:conversationKey" },

  UserKyb: {path:'/kyb'},
  UserKybDetails: {path:'/kyb/:id'},
  

  // Remittance
  EditDraftRemittance: { path: "/payment/outward/add/:id" },
  OutwardRemittanceBase: { path: '/payment/outward' },
  OutwardRemittance: { path: '/payment/outward' },
  ClientRemittance: { path: '/payment/outward/pending' },
  OutwardRemittanceDetails: {
    path: '/payment/outward/:id',
  },
  AddOutwardRemittance: { path: '/payment/outward/add' },
  IncomingPayment: { path: '/payment/incoming' },
  IncomingPaymentDetails: { path: '/payment/incoming/:id' },
  InternalTransfer: { path: '/payment/internal-transfer/'},

  // Redeem
  RedemptionsRaised: { path: "/redemptions/raised" },
  CreateRedeemRequest: { path: "/redemptions/create" },
  RedemptionsReceived: { path: "/redemptions/received" },
  RedemptionRaisedDetails: { path: "/redemptions/raised/details/:id" },
  RedemptionReceivedDetails: { path: "/redemptions/received/details/:id" },
  AvailableRedemptions: { path: "/redemptions/available" },

  // Configurations
  IncomingPaymentFees: { path: "/configurations/fees/transaction/incoming-payments" },
  OutgoingPaymentFees: { path: "/configurations/fees/transaction/outgoing-payments" },
  InternalTransferFees: { path: "/configurations/fees/transaction/internal-transfers" },
  MonthlyFees: { path: "/configurations/fees/monthly"},
  MarkupFees: { path: "/configurations/fees/mark-up"},
  OtherFees: { path: "/configurations/fees/others"},
  ExchangeOffers: { path: "/configurations/exchange-offers" },
  ExchangeOfferHistory: {
    path: "/configurations/exchange-offers/:id",
  },
  FinancialAccounts: { path: "/configurations/financial-accounts" },
  KycLevels: { path: "/configurations/kyc-levels" },

  // Account
  SubAccounts: { path: "/accounts/sub-accounts" },
  Liquidity: { path: "/accounts/liquidity" },
  LiquidityHistory: { path: "/accounts/liquidity/:id" },
  AccountBalance: { path: "/accounts/balance/:id" },
  Currencies: { path: "/accounts/currencies" },

  // Transactions
  Transactions: { path: "/transactions" },
  Balance: { path: "/transactions/balance" },
  ReportTransactions: { path: "/transactions/reporttransactions" },

  History: { path: "/transaction/history" },
  SystemUser: { path: "/administration/systemUser" },
  StoreMenu: { path: "/administration/store" },
  CounterMenu: { path: "/administration/counter/:id" },
  UserApproval: { path: "/administration/userApproval/:id" },
  AddSystemUser: { path: "/administration/addSystemUser" },
  BusinessDetails: { path: "/administration/updatebusinessdetails" },
  BusinessDetailsEdit: { path: "/kycdone" },
  Invoices: { path: "/invoices" },
  Support: { path: "/support/platform" },
  Customer: { path: "/customer" },
  CustomerDetails: { path: "/customerdetails" },
  NewTicket: { path: "/support/newticket" },
  TicketReply: { path: "/support/ticketreply/:id" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  // Billing: { path: "/examples/billing" },
  // Invoice: { path: "/examples/invoice" },
  Signup: { path: "/stb/sign-up" },
  Signupthanks: { path: "/signupthanks" },
  ForgotPassword: { path: "/stb/forgot-password" },
  ChangePassword: { path: "/stb/change-password" },
  ResetPassword: { path: "/stb/reset-password" },
  Thankyou: { path: "/stb/thankyou" },
  Lock: { path: "/stb/lock" },
  NotFound: { path: "/stb/404" },
  ServerError: { path: "/stb/500" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
