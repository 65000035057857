import React from 'react';
import { Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { ButtonToolbar, Col } from '@themesberg/react-bootstrap';
import { CustomerData } from '../components/Tables';
import Modals from '../components/CustomerModals';

export default () => {
  return (
    <div className='page-wrapper mt-2'>
      <div className='user'>
        <div className='users  bg-white p-4 radi'>
          <div className='row align-items-center pb-4'>
            <div className='col-lg-10'>
              <ButtonToolbar className='d-flex '>
                <Button appearance='default' className='color-blue font-fam font-12 rounded-pill pe-4 ps-4'>
                  All
                </Button>
                <Button appearance='default' className='font-fam font-12 ms-2 pe-4 ps-4'>
                  New Customer
                </Button>
              </ButtonToolbar>
            </div>
            {/* <div className="col-lg-2">
					<Modals />
				</div> */}
          </div>
          <Col xs={12} xl={12} className='mb-4'>
            <CustomerData />
          </Col>
        </div>
      </div>
    </div>
  );
};
