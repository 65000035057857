import React, { useState, useEffect } from 'react';
// , TextFieldComponent
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TextFieldComponent, TextFieldSelect, Radio, CurrencySelect } from '../../../../../components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MenuItem, Stack, RadioGroup, FormControl, FormControlLabel, Typography } from '@mui/material';
import SenderInfo from './Sender';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';

const ClientInfo = ({ handleNext }) => {
  const { configReducer } = useSelector((state) => state);
  const { remittanceDispatch, clientInfo } = useRemittance();

  const { remittanceTypes, purposeTypes: remittancePurposes } = configReducer;

  const [type, setType] = useState(clientInfo.type);

  const yupObject = {
    type: yup.string('Select a type').required('Type is required.'),

    purpose: yup.string('Select a purpose').required('Purpose is required.'),

    target_country: yup.string('Select a country').required('Country is required.'),

    sender: yup.object().required('Sender is required').nullable(),

    other_purpose: yup.string('Enter purpose').when('purpose', {
      is: (val) => val == 4,
      then: yup.string('Enter purpise type').required('Purpose type is required.'),
    }),
    sender_document: yup.array().min(1, 'Select atleast one document'),
  };

  const validationSchema = yup.object(yupObject);

  const formik = useFormik({
    initialValues: { ...clientInfo },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    // validateOnChange: true,
  });

  const nextClickHandler = (id) => {
    console.log(formik);
    remittanceDispatch({ type: 'UPDATE_CLIENT_INFO', payload: { ...formik.values, target_country_id: id } });

    handleNext();
  };

  const onTypeChange = (newType) => {
    console.log(type, newType, newType.split('2'), type.split('2'), newType.split('2')[0] !== type.split('2')[0]);
    if (newType.split('2')[0] !== type.split('2')[0]) {
      formik.setFieldValue('sender', null);
      remittanceDispatch({ type: 'UPDATE_RECIPIENT_INFO', payload: { ...formik.values, send_currency: '', send_amount: '' } });
    }
    if (newType.split('2')[1] !== type.split('2')[1]) {
      console.log('jere');
      remittanceDispatch({
        type: 'UPDATE_RECIPIENT_INFO',
        payload: { ...formik.values, recipient: null, financeAccount: null, receive_amount: '', receive_currency: '' },
      });
    }
    setType(newType);
  };

  return (
    <>
      <Box sx={{ padding: '24px ' }}>
        <Stack direction={'column'} spacing={4} mb={4}>
          <Stack spacing={1}>
            <Typography variant={'h6'}>General</Typography>

            <FormControl>
              <Typography id='type'>Remittance Type</Typography>
              <RadioGroup
                row
                sx={{ justifyContent: 'space-between' }}
                aria-labelledby='type'
                name='type'
                id='type'
                value={formik.values.type}
                onChange={(e) => {
                  console.log(e.target.value);
                  formik.setFieldValue('type', e.target.value);
                  onTypeChange(e.target.value);
                }}
              >
                {remittanceTypes?.map((rem) => (
                  <FormControlLabel value={rem.value.toLowerCase()} control={<Radio type={rem.value.toLowerCase()} value={formik.values.type} />} label={rem.name} />
                ))}
              </RadioGroup>
            </FormControl>
            <Box
              sx={{
                display: 'grid',
                columnGap: 16,
                rowGap: 24,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              <TextFieldSelect
                formik={formik}
                name='purpose'
                type='text'
                label='Purpose of Remittance'
                options={remittancePurposes}
                render={(purpose) => (
                  <MenuItem id={purpose.name} value={purpose.id}>
                    {purpose.name}
                  </MenuItem>
                )}
              />
              {formik.values.purpose === remittancePurposes.length && <TextFieldComponent formik={formik} name='other_purpose' type='text' label='Specify the purpose' />}
            </Box>
          </Stack>
        </Stack>

        <SenderInfo type={type} formik={formik} handleNext={nextClickHandler} />
      </Box>
    </>
  );
};

export default ClientInfo;
