import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { Routes } from '../../../routes';
import { send } from '../../../utils/xrpl_functions';
import * as API from '../../../ApiUrl';
import { RedemptionTable } from './components/redemptionTable';
import { merchantApi } from '../../../redux/api/apiConfig';

import StatusModal from '../../payments/incoming/components/statusModal/StatusModal';
import PassphraseModal from '../../payments/incoming/components/passphraseModal/PassphraseModal';
import { ButtonResetFilter, Iconify } from '../../../components';
// import { RedeemSummary } from './components/redeemSummary';
import { format } from 'date-fns';
import { Autocomplete, Box, Divider, MenuItem, Stack, TextField, InputAdornment } from '@mui/material';
import usePageTitle from '../../../hooks/usePageTitle';
import { reportTimePeriods } from '../../reports/common';
import { formatDate } from '../../../utils/common';
import { StyledDatePicker } from '../../../components/datePicker';
import { FilterDropdown } from '../../reports/components';
import { CLEAR_REQUEST_RAISED, UPDATE_REQUEST_RAISED } from '../../../redux/actionTypes';

function RedemptionRequestRaised() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState('');
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const { walletsReducer, configReducer } = useSelector((state) => state);
  const filters = useSelector((state) => state.filters.settlements.requestRaised);
  const { coins = [] } = walletsReducer;
  const { redeemStatuses } = configReducer;

  const history = useHistory();
  const dispatch = useDispatch();
  usePageTitle('Settlements', 'View your settlements');

  const handleClearFilters = () => {
    dispatch({ type: CLEAR_REQUEST_RAISED });
  };

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const { calenderValue, pageSize, currentPage, search, coin, status } = filters;
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');
    merchantApi
      .get(`${API.REDEEM}`, {
        params: {
          offset: currentPage * pageSize,
          limit: pageSize,
          search,
          coin: coin?.id,
          status: status?.id,
          created_date_after,
          created_date_before,
        },
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        console.log(response);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, filters.coin, filters.status, filters.calenderValue, getTrans]);

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatus = (transaction) => {
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${API.REDEEM}${transactionData.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
      // getCounts();
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const viewDetailsHandler = (id) => {
    history.push(Routes.RedemptionsRaised.path + `/details/${id}`);
  };

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          width={'100%'}
        >
          <Stack sx={{ flexDirection: 'row', gap: 1 }} divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}>
            <Stack sx={{ minWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: UPDATE_REQUEST_RAISED,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_REQUEST_RAISED,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_REQUEST_RAISED,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
              {coins && (
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='currency'
                    value={filters.coin}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: UPDATE_REQUEST_RAISED,
                        payload: {
                          currentPage: 0,
                          coin: newValue,
                        },
                      });
                    }}
                    options={coins}
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Currency' {...params} size='small' />;
                    }}
                  />
                </Stack>
              )}
              <Stack sx={{ minWidth: '200px', flex: 1 }}>
                <TextField
                  fullWidth
                  size='small'
                  value={filters.search}
                  onChange={(e) =>
                    dispatch({
                      type: UPDATE_REQUEST_RAISED,
                      payload: {
                        search: e.target.value,
                      },
                    })
                  }
                  placeholder='Search...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: '200px', flex: 1 }}>
              <Autocomplete
                id='status'
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: UPDATE_REQUEST_RAISED,
                    payload: {
                      status: newValue ?? null,
                    },
                  });
                }}
                options={redeemStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select Status' {...params} size='small' />;
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>

        <RedemptionTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          updateStatus={updateStatus}
        />
      </Box>

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          canChangeTo={transactionData?.current_status?.receiver_can_change_to}
        />
      )}
    </>
  );
}

export default RedemptionRequestRaised;
