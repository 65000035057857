import React, { useState, useEffect } from 'react';
import { ADD_MERCHANT_API, MERCHANT_PROFILE, GET_DOCUMET_TYPE, KYB_START, UPLOAD_KYB_DOC } from '../../../../ApiUrl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { textFieldLabelProps } from '../../../../utils/common';
import { TextField, MenuItem } from '@material-ui/core';
import { getStatus } from '../../../../redux/actions/kybActions';
import { Loader, Button } from 'rsuite';
import { Stack } from '@mui/material';

const UploadKybDocs = ({ getStatus, handleNext, handleBack, setDocInfo, docInfo }) => {
  const [initialDocType, setInitialDocType] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [frontDetails, setFrontDetails] = useState('');
  const [backDetails, setBackDetails] = useState('');
  const [docList, setDocList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getdoc_list();
  }, []);

  console.log(docInfo);

  const getdoc_list = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(GET_DOCUMET_TYPE);
      setDocList(data.data);
      console.log(docInfo.docTypeEdit);
      if (docInfo.docTypeEdit) {
        const docId = data.data.find((doc) => doc.id === parseInt(docInfo.fileData.get('document_type')))?.id;
        console.log(data.data, docInfo.fileData.get('document_type'));
        setDocumentType(docId);
        setInitialDocType(docId);
      } else {
        const docId = data.data.find((doc) => doc.name === docInfo.docType)?.id;
        console.log(docInfo.docType);
        setDocumentType(docId);
        setInitialDocType(docId);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }

    setLoading(false);
  };
  const handleUploadfront = (event) => {
    setFrontDetails(event.target.files[0]);
  };

  const handleUploadback = (event) => {
    setBackDetails(event.target.files[0]);
  };

  const handleUpload = async () => {
    setUploadLoading(true);
    var filedata = new FormData();
    if (frontDetails) {
      console.log(frontDetails);
      filedata.append('front_part_of_document', frontDetails);
    } else if (docInfo.frontEdit) {
      filedata.append('front_part_of_document', docInfo?.fileData?.get('front_part_of_document'));
    }
    if (backDetails) {
      console.log(backDetails);
      filedata.append('back_part_of_document', backDetails);
    } else if (docInfo.backEdit) {
      filedata.append('back_part_of_document', docInfo?.fileData?.get('back_part_of_document'));
    }
    if (documentType !== initialDocType) {
      console.log(documentType);
      filedata.append('document_type', parseInt(documentType));
      console.log(filedata);
    } else if (docInfo.docTypeEdit) {
      filedata.append('document_type', docInfo?.fileData?.get('document_type'));
    }

    console.log(filedata);
    // setloading(true);
    let token = localStorage.getItem('accessToken');

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `Bearer ${token}`,
      },
    };
    const frontEdit = frontDetails ? Boolean(frontDetails) : docInfo.frontEdit;
    const backEdit = backDetails ? Boolean(backDetails) : docInfo.backEdit;
    const docTypeEdit = documentType !== initialDocType ? true : docInfo.docTypeEdit;
    console.log(docTypeEdit);
    try {
      const res = await axios.patch(`${UPLOAD_KYB_DOC}`, filedata, config);
      setUploadLoading(false);
      toast.success('Documents uploaded successfully');
      getStatus();
      handleNext();
      setDocInfo({
        fileData: filedata,
        frontUrl: frontEdit ? '' : docInfo.frontUrl,
        backUrl: backEdit ? '' : docInfo.backUrl,
        docType: docTypeEdit ? '' : docInfo.docType,
        frontEdit,
        backEdit,
        docTypeEdit: docTypeEdit,
      });
      // setStage('welcome');
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
      setUploadLoading(false);
    }
    // setloading(false);
  };

  return (
    <>
      <form>
        <TextField
          select
          // fullWidth
          style={{ width: '50%', minWidth: '200px' }}
          variant='outlined'
          className='mt-4 bg-white'
          id='docType'
          name='docType'
          InputLabelProps={textFieldLabelProps}
          label='Document Type'
          value={documentType}
          onChange={(event) => {
            setDocumentType(event.target.value);
          }}
          onBlur={(event) => {
            // kybFormik.handleChange(event);
          }}
          // error={
          // kybFormik.touched.docType && Boolean(kybFormik.errors.docType)
          // }
          // helperText={kybFormik.touched.docType && kybFormik.errors.docType}
        >
          {docList.map((doc) => (
            <MenuItem value={doc.id}>{doc.name}</MenuItem>
          ))}
        </TextField>
        {documentType !== '' ? (
          <Stack>
            <div className='doc-upload'>
              <label className='mt-4 text-primary'>1. Front Part of Document</label>
              <input
                type='file'
                className='mt-4'
                accept='image/*, application/pdf '
                // style={{ display: "none" }}
                // value={docInfo?.front_part_of_document ? 'Front_Part.png' : ''}
                id='docupload'
                onChange={(e) => handleUploadfront(e)}
              />
              <ImageThumb
                image={frontDetails ? frontDetails : docInfo?.frontEdit ? docInfo?.fileData?.get('front_part_of_document') : docInfo?.frontUrl}
                isEdit={frontDetails ? true : docInfo.frontEdit}
              />
            </div>

            <div className='doc-upload'>
              <label className='mt-4 text-primary'>2. Back Part of Document</label>
              <input
                type='file'
                className='mt-4'
                accept='image/*, application/pdf '
                // value={fileDetails2}
                // style={{ display: "none" }}
                id='docupload'
                onChange={(e) => handleUploadback(e)}
              />
              <ImageThumb
                image={backDetails ? backDetails : docInfo?.backEdit ? docInfo?.fileData?.get('back_part_of_document') : docInfo?.backUrl}
                isEdit={backDetails ? true : docInfo.backEdit}
              />
            </div>
          </Stack>
        ) : (
          ''
        )}
      </form>
      <footer>
        <Button className='btn white-btn action-button basis-30' onClick={handleBack} appearance='subtle'>
          Back
        </Button>

        <Button
          onClick={handleUpload}
          loading={uploadLoading}
          // disabled={ !frontDetails || !backDetails}
          appearance='primary'
          className={`btn action-button primary_color text-white`}
        >
          <span>Update and continue</span>
        </Button>
      </footer>
    </>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(UploadKybDocs);

const ImageThumb = ({ image = '', isEdit }) => {
  console.log(image, isEdit);
  return (
    <img
      style={{
        marginTop: '1rem',
        width: '50%',
        height: '50%',
        minWidth: '40px',
        maxWidth: '80px',
        minHeight: '40px',
        maxHeight: '80px',
      }}
      src={isEdit && image ? URL?.createObjectURL(image || '') : image}
      alt='Doc'
    />
  );
};
