import React from 'react';
import PropTypes from 'prop-types';
//
import documentImg from '../../../assets/img/icons/document.png';
import Image from '../../image';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  if (!file) {
    return null;
  }

  console.log(file);

  const type = file.type.split('/')[0];

  let imgUrl;

  if (typeof file === 'string') {
    imgUrl = file;
  } else {
    console.log(type);
    if (type === 'image') {
      imgUrl = file.preview;
    } else {
      imgUrl = documentImg;
    }
  }

  console.log(imgUrl);

  if (type === 'image')
    return (
      <Image
        alt='file preview'
        src={imgUrl}
        sx={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    );
  return (
    <Image
      alt='file preview'
      src={documentImg}
      ratio={'21/9'}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
