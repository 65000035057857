import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Stack, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { Button } from 'rsuite';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function PassphraseModal({ isOpen, onClose, submitClickHandler, backClickHandler, passphraseLoading, showBackButton = false }) {
  const [showPassword, setShowPassword] = useState(false);
  const [passphrase, setPassphrase] = useState('');

  console.log(isOpen, onClose, submitClickHandler, backClickHandler, passphraseLoading);

  const handleClickShowPassword = () => {
    setShowPassword((value) => !value);
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'sm'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Enter Passphrase</DialogTitle>
      <DialogContent sx={{ paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
          }}
        >
          <Stack spacing={0}>
            <Typography>Passphrase</Typography>
            <TextField
              fullWidth
              variant='outlined'
              name={'passphrase'}
              id={'passphrase'}
              // InputLabelProps={textFieldLabelProps}
              type={showPassword ? 'text' : 'password'}
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Stack>
            <Button className='btn white-btn action-button ' onClick={handleCloseModal} appearance='subtle'>
              Cancel
            </Button>
          </Stack>
          <Stack direction={'row'}>
            {showBackButton && (
              <Button className='btn white-btn action-button ' onClick={backClickHandler} appearance='subtle'>
                Back
              </Button>
            )}
            <Button
              className={`btn action-button primary_color text-white`}
              appearance='primary'
              onClick={() => submitClickHandler(passphrase)}
              disabled={passphrase === ''}
              loading={passphraseLoading}
            >
              Submit
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default PassphraseModal;
