import React, { useState, useEffect } from 'react';
import { CurrenciesTable } from './components';
import 'rsuite/dist/styles/rsuite-default.css';
import { Col, Button } from '@themesberg/react-bootstrap';
import { useLocation } from 'react-router-dom';
import * as API from '../../../ApiUrl';

import axios from 'axios';
import { useSelector } from 'react-redux';
import reload from '../../../assets/img/icons/reload.svg';
import { Box, Stack } from '@mui/material';

import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Form, InputGroup } from '@themesberg/react-bootstrap';

import searchIcon from '../../../assets/img/icons/searchIcon.svg';
import dropDown from '../../../assets/img/icons/dropDown.svg';
import { PreviewContainer, PreviewItem } from '../../../components';
import usePageTitle from '../../../hooks/usePageTitle';

const Currencies = () => {
  const [loading, setLoading] = useState(false);
  const [paginateTable, setPaginateTable] = useState({
    search: '',
  });

  usePageTitle('Currencies', 'View your currencies');

  const handleClearFilter = () => {
    setPaginateTable({
      ...paginateTable,
      search: '',
    });
  };

  const wallets = useSelector((state) => state.walletsReducer.wallets);
  const mto = useSelector((state) => state.profileReducer.user.mto);

  const handleSearch = (e) => {
    setPaginateTable({
      ...paginateTable,
      search: e,
    });
  };

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Stack direction={'row'} width={'100%'} gap={2} mb={2}>
        <Stack sx={{ maxWidth: '25%', flex: 1 }}>
          <Form onSubmit={(e) => e.preventDefault()} className='w-100 search'>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>
                  <img src={searchIcon} alt='search' />
                </InputGroup.Text>
                <Form.Control
                  value={paginateTable.search}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  type='text'
                  placeholder='Search'
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Stack>
        <Stack sx={{ maxWidth: '25%', width: 'fit-content' }}>
          <Button
            className=' w-xs-100  text-dark w-100'
            style={{
              background: 'none',
              border: 'none',
              width: '100%',
              boxShadow: 'none',
            }}
            onClick={handleClearFilter}
          >
            Reset Filters
            <img src={reload} style={{ marginLeft: '7px' }} alt='reset filter' />
          </Button>
        </Stack>
      </Stack>
      <PreviewContainer sx={{ mb: 2 }}>
        <PreviewItem title={'Blockchain Address'} content={wallets?.[0]?.address} copy={true} />
        <PreviewItem title={'Master Tag Number'} content={mto?.xrpl_tag} copy={true} />
      </PreviewContainer>

      <CurrenciesTable loading={loading} paginationdetails={paginateTable} />
    </Box>
  );
};

export default Currencies;
