import React from 'react';
import { Typography } from '@mui/material';
import { Button } from 'rsuite';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

const AddNewOfferModal = ({
  onClose,
  isOpen,
  onSubmit,
  text = '',
  loading = false,
}) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'xs'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ paddingBottom: '0 !important' }}>
          <Typography
            sx={{ color: '#667085' }}
            variant={'subtitle1'}
          >{`This will permanently delete ${text}.`}</Typography>

          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              className="btn white-btn action-button "
              onClick={onClose}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              className={`btn action-button primary_color text-white`}
              appearance="primary"
              type={'submit'}
              onClick={onSubmit}
              loading={loading}
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewOfferModal;
