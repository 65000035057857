import { remittanceInitialState } from '../InitialStates';

const remittanceReducer = (state, action) => {
  console.log(action, state);
  switch (action.type) {
    case 'UPDATE_CLIENT_INFO': {
      return { ...state, clientInfo: { ...state.clientInfo, ...action.payload } };
    }

    case 'UPDATE_RECIPIENT_INFO': {
      console.log(state, { ...state, recipientInfo: { ...state.recipientInfo, ...action.payload } });
      return { ...state, recipientInfo: { ...state.recipientInfo, ...action.payload } };
    }

    case 'UPDATE_TRANSFER_DETAILS': {
      console.log(state, { ...state, transferDetails: { ...state.transferDetails, ...action.payload } });
      return { ...state, transferDetails: { ...state.transferDetails, ...action.payload } };
    }

    case 'RESET_REMITTANCE_INFO': {
      return remittanceInitialState;
    }

    case 'TOGGLE_DISCARD': {
      return { ...state, formState: { openConfirmDiscard: !state.formState.openConfirmDiscard } };
    }

    default:
      return state;
  }
};

export default remittanceReducer;
