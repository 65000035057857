import React from 'react';
import { TextField, Stack } from '@mui/material';
const FilterDropdown = ({
  formik,
  name = '',
  type = 'text',
  label = '',
  size = 'normal',
  value,
  options = [],
  onChange = () => {},
  render = () => {},
  ...others
}) => {
  return (
    <Stack spacing={0} width={'100%'}>
      <TextField
        select
        fullWidth
        placeholder={'asdsd'}
        variant='outlined'
        name={name}
        id={name}
        size={size}
        type={type}
        // InputLabelProps={textFieldLabelProps}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        {...others}
      >
        {options.map(render)}
      </TextField>
    </Stack>
  );
};

export default FilterDropdown;
