import { useCallback, useEffect } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';
import { debounce } from 'lodash';

import { Transfer } from '../../../../../components/transfer';
import { PasswordModal } from '../../../../../components/passwordModal';
import { Stack, Typography, TextField, Autocomplete, Box, MenuItem, Alert } from '@mui/material';
import { CurrencySelect, TextFieldSelect, TextFieldComponent } from '../../../../../components';
import { toast } from 'react-toastify';
import { BalanceCard } from '../../../../../components/balanceCard';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import { CALCULATE_FEE, MTO_ACCOUNTS, MTO_TRANSFER, REMITTANCE_MTO, REMITTANCE_RATE, SENDER_IND, VERIFY_PASSWORD } from '../../../../../ApiUrl';
import PaymentDetails from './PaymentDetails';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';
import { useParams } from 'react-router-dom';

const TransferDetails = ({ handleBack, handleNext }) => {
  const { clientInfo, recipientInfo, transferDetails, remittanceDispatch } = useRemittance();
  console.log(transferDetails)
  const params = useParams();
  console.log(useRemittance());
  const validationSchema = yup.object({
    recipient_mto: yup.object().required('Recipient is required').nullable(),
    send_currency: yup.string('Select a sender currency').required('Sender currency is required.'),
    send_amount: yup.number('Enter sender amount').positive('Amount must be greater than 0').required('Sender amount is required.'),
    receive_amount: yup.number('Enter receiver amount').positive('Amount must be greater than 0').required('Receiver amount is required.'),
    receive_currency: yup.string('Select a receiver currency').required('Receiver currency is required.'),
    deposit_type: yup.string('Select a deposit type').required('Deposit type is required.'),

    other_deposit: yup.string('Enter deposit type').when('deposit_type', {
      is: (val) => val == 4,
      then: yup.string('Enter deposit type').required('Deposit type is required.'),
    }),
  });


  const [mtoOptions, setMtoOptions] = useState([]);
  const [selectedMto, setSelectedMto] = useState(null);
  const [feeMsg, setMsg] = useState('');
  const [mtoMsg, setMtoMsg] = useState('');
  const [receiverWallets, setReceiverWallets] = useState([]);
  const [fee, setFee] = useState(null);

  const [currency, setCurrency] = useState({ send: '', receive: '' });
  const [amountChange, setAmountChange] = useState('send');

  const [showTransfer, setShowTransfer] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [transferData, setTransferData] = useState({});
  const [transferLoading, setTransferLoading] = useState(false);
  const [sender, setSender] = useState(null);

  const { walletsReducer, profileReducer, configReducer } = useSelector((state) => state);

  const { depositTypes } = configReducer;

  const { coins = [], wallets: reduxWallets } = walletsReducer;
  const {
    user: {
      mto: {
        currency: { code: symbol },
        supported_coin,
      },
    },
  } = profileReducer;

  const wallets = reduxWallets.filter((wallet) => wallet?.coin?.symbol !== 'XRP');


  const getMtoOptions = async (receive_currency) => {
    // const { send_currency, receive_currency } = transferDetails;
    try {
      const response = await merchantApi.get(`${REMITTANCE_MTO}`, {
        params: {
          // country: clientInfo.target_country_id,
          // base_currency: send_currency,
          quote_currency: receive_currency,
        },
      });
      if (response.data.length === 0) {
        setMtoMsg('No MTO available');
      } else {
        setMtoMsg('');
      }
      onRecipientMtoChange(response.data[0]);
      setMtoOptions(response.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  const calculateFee = async (params) => {
    const { send_amount = 0, receive_amount = 0 } = params;
    if (send_amount > 0 || receive_amount > 0) {
      try {
        const response = await merchantApi.post(CALCULATE_FEE, { ...params });
        setFee(response.data.data);
        setMsg('');
        return response.data.data;
      } catch (err) {
        setMsg('No offer found for the target country for the request currency pair.');
        toast.error(err?.data?.data?.message || 'Unable to fetch rates at the moment, please try again later.');
        console.log(err);
      }
    } else return null;
  };

  const currencyChangeHandler = async (currency, type) => {
    switch (type) {
      case 'send':
        const { send_amount, receive_currency } = formik.values;
        if (send_amount) {
          try {
            const res = await calculateFee({
              receipt_mto: selectedMto.id,
              send_amount: send_amount,
              send_currency: currency,
              receive_currency,
            });
            if (res) {
              formik.setFieldValue('receive_amount', res.receive_amount);
            } else {
              formik.setFieldValue('receive_amount', '');
            }
          } catch (err) {
            toast.error(err?.data?.data?.message || 'Unable to fetch rates at the moment, please try again later.');
          }
        }
        break;

      case 'receive':
        const { receive_amount, send_currency } = formik.values;
        if (receive_amount) {
          try {
            const res = await calculateFee({
              receipt_mto: selectedMto.id,
              receive_amount,
              send_currency,
              receive_currency: currency,
            });
            if (res) {
              formik.setFieldValue('send_amount', res.send_amount);
            } else {
              formik.setFieldValue('send_amount', '');
            }
          } catch (err) {
            console.log(err);
            toast.error(err?.data?.data?.message || 'Unable to fetch rates at the moment, please try again later.');
          }
        }
        break;

      default: {
        return;
      }
    }
  };

  const toggleShowTransfer = () => {
    setShowTransfer((state) => !state);
  };

  const toggleShowPasswordModal = () => {
    setShowPasswordModal((state) => !state);
  };

  const transferNextClickHandler = (data) => {
    setTransferData(data);
    toggleShowPasswordModal();
    toggleShowTransfer();
  };

  const transferClickHandler = async (password) => {
    try {
      setTransferLoading(true);
      const passwordRespose = await merchantApi.post(VERIFY_PASSWORD, {
        password,
      });
      const response = await merchantApi.post(MTO_TRANSFER, transferData);
      toast.success('Funds transferred successfully');
      setTransferLoading(false);
      toggleShowPasswordModal();
      // refreshBalances();
    } catch (error) {
      setTransferLoading(false);
      toast.error(error?.data?.data?.message || 'Transfer failed');
    }
  };

  const getSenderClientBalance = async () => {
    try {
      const response = await merchantApi.get(`${SENDER_IND}${clientInfo.sender.id}/`);

      setSender(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong.');
      console.log(error);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getSenderClientBalance();
    } else {
      setSender(clientInfo.sender);
    }
  }, [params]);

  useEffect(() => {

    const { currency, send_currency, receive_currency, recipient_mto, fee } = transferDetails;
    console.log(transferDetails);
    const { recipient } = recipientInfo;
    setFee(fee);
    setSelectedMto(recipient_mto);   
    getMtoOptions(receive_currency);
    setCurrency(currency);
    if (recipient) {
   const  wallets = recipient.country.coins.map((coin) => ({ coin }));
      setReceiverWallets(wallets);
    }
  }, []);

  const formik = useFormik({
    initialValues: { ...transferDetails },
    validateOnBlur: true,
    validationSchema: validationSchema,
    // isInitialValid: false,
    validateOnMount: true,
  });
  const nextClickHandler = () => {
    // setTransferDetails({ ...formik.values, currency, fee });
    remittanceDispatch({ type: 'UPDATE_TRANSFER_DETAILS', payload: { ...formik.values, fee, currency } });
    handleNext();
  };

  const backClickHandler = () => {
    console.log(formik.values);
    // setTransferDetails({ ...formik.values, currency, fee });
    remittanceDispatch({ type: 'UPDATE_TRANSFER_DETAILS', payload: { ...formik.values, fee, currency } });

    handleBack();
  };

  let receiveDecimalPlace = 2;
  if (formik.values.receive_currency) {
    receiveDecimalPlace = coins.find((coin) => coin.id === formik.values.receive_currency)?.decimal_place;
  }

  const onRecipientMtoChange = (mto) => {
    setSelectedMto(mto);
    formik.setFieldValue('recipient_mto', mto);
    const { send_currency, send_amount } = formik.values;
    if (send_currency && send_amount) {
      sendAmountChange(send_amount);
      return;
    }
    // else if (receive_currency && receive_amount) {
    //   receiveAmountChange(receive_amount);
    // }
  };
  const receiveAmountChange = useCallback(
    async (value) => {
      let decimalPlace = 2;
      const send_currency = formik.values.send_currency;
      const receive_currency = formik.values.receive_currency;
      if (receive_currency) {
        decimalPlace = coins.find((coin) => coin.id === receive_currency)?.decimal_place;
      }
      formik.setFieldValue('receive_amount', value);
      if (selectedMto && value) {
        try {
          const res = await calculateFee({
            receipt_mto: selectedMto.id,
            receive_amount: value,
            send_currency,
            receive_currency,
          });
          if (res) {
            formik.setFieldValue('send_amount', res.send_amount);
          } else {
            formik.setFieldValue('send_amount', '');
          }
        } catch (err) {
          console.log(err);
          toast.error(err?.data?.data?.message || 'Unable to fetch rates at the moment, please try again later.');
        }
      }
    },
    [coins, selectedMto, formik.values.send_currency, formik.values.receive_currency]
  );

  const sendAmountChange = useCallback(
    async (value) => {
      let decimalPlace = 2;
      const send_currency = formik.values.send_currency;
      const receive_currency = formik.values.receive_currency;
      if (send_currency) {
        decimalPlace = coins.find((coin) => coin.id === send_currency)?.decimal_place;
      }

      formik.setFieldValue('send_amount', value);
      if (selectedMto && value) {
        try {
          const res = await calculateFee({
            receipt_mto: selectedMto.id,
            send_amount: value,
            send_currency,
            receive_currency,
          });
          if (res) {
            formik.setFieldValue('receive_amount', res.receive_amount);
          } else {
            formik.setFieldValue('receive_amount', '');
          }
        } catch (err) {
          console.log(err);
          toast.error(err?.data?.data?.message || 'Unable to fetch rates at the moment, please try again later.');
        }
      }
    },
    [coins, formik.values.send_currency, formik.values.receive_currency, selectedMto]
  );

  useEffect(() => {
    let timer;

    console.log('send', amountChange);
    if (amountChange === 'send') {
      timer = setTimeout(() => {
        sendAmountChange(formik.values.send_amount);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [formik.values.send_amount, sendAmountChange, amountChange]);

  useEffect(() => {
    let timer;
    console.log('receive', amountChange);
    if (amountChange === 'receive') {
      timer = setTimeout(() => {
        receiveAmountChange(formik.values.receive_amount);
      }, 500);
    }
    return () => clearTimeout(timer);
  }, [formik.values.receive_amount, receiveAmountChange, amountChange]);

  const depositTypeName = depositTypes.find((deposit) => deposit.id === formik?.values?.deposit_type)?.name;

  console.log(formik);
  return (
    <>
      <Box sx={{ padding: '24px ' }}>
        <Stack spacing={4} direction={'column'}>
          <Box
            sx={{
              display: 'grid',
              columnGap: '16px',
              rowGap: '24px',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            <Stack spacing={1}>
              <Stack
                direction={'column'}
                sx={{
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  flex: 1,
                }}
                spacing={0}
              >
                <Typography>Recipient MTO</Typography>
                <Autocomplete
                  // disablePortal
                  id='mto'
                  value={selectedMto}
                  getOptionLabel={(option) => `[${option.id}] ${option.name}`}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={mtoOptions}
                  onChange={(e, newValue) => onRecipientMtoChange(newValue)}
                  sx={{ flexGrow: 1, width: '100%' }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
              {mtoMsg && <Alert severity='error'>{mtoMsg}</Alert>}
            </Stack>
          </Box>
          <Stack
            spacing={3}
            direction={{
              sm: 'row',
              xs: 'column',
            }}
            alignItems={'stretch'}
          >
            <Stack spacing={1} sx={{ flex: 1 }} direction={'column'}>
              <CurrencySelect
                title={'Amount to Remit'}
                formik={formik}
                options={[{coin:supported_coin}]}
                optionChangeHandler={(id) => {
                  if (id != 'default') {
                    const code = coins.find((cur) => cur.id === id).symbol;
                    setCurrency((prevState) => ({
                      ...prevState,
                      send: code,
                    }));

                    getMtoOptions(transferDetails.receive_currency);
                    currencyChangeHandler(id, 'send');
                  } else {
                    setCurrency((prevState) => ({
                      ...prevState,
                      send: '',
                    }));
                  }
                  // onCurrencyChange(id);
                }}
                amountName='send_amount'
                amountChangeHandler={() => {
                  setAmountChange('send');
                }}
                currencyName='send_currency'
              />

              {feeMsg && <Alert severity='error'>{feeMsg}</Alert>}
            </Stack>

            <Stack spacing={1} sx={{ flex: 1 }} direction={'column'}>
              <CurrencySelect
                title={'Amount to Receive'}
                formik={formik}
                options={receiverWallets}
                optionChangeHandler={(id) => {
                  if (id != 'default') {
                    const code = coins.find((cur) => cur.id === id).symbol;
                    setCurrency((prevState) => ({
                      ...prevState,
                      receive: code,
                    }));
                    currencyChangeHandler(id, 'receive');
                  } else {
                    setCurrency((prevState) => ({
                      ...prevState,
                      receive: '',
                    }));
                  }
                }}
                amountName='receive_amount'
                amountChangeHandler={() => {
                  setAmountChange('receive');
                }}
                currencyName='receive_currency'
              />
              <Stack>
                {fee?.offer ? (
                  <Typography>{`1 ${currency.send} = ${fee?.offer} ${currency.receive}`}</Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: 'grid',
              columnGap: '16px',
              rowGap: '24px',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              },
            }}
          >
            <Stack width={'100%'} spacing={1}>
              <TextFieldSelect
                formik={formik}
                name='deposit_type'
                type='text'
                label='Customer Paid In'
                options={depositTypes}
                render={(deposit) => <MenuItem value={deposit.id}>{deposit.name}</MenuItem>}
              />
              {depositTypeName?.toLowerCase() === 'wallet' ? (
                <>
                  <BalanceCard
                    title='Customer Balance'
                    insufficientBalance={sender?.wallet_balance < formik?.values?.send_amount + Number(fee?.inward_fee)}
                    balance={`${Number(sender?.wallet_balance).toFixed(sender?.currency?.decimal_place)} ${sender?.currency?.symbol}`}
                    loading={false}
                    add={false}
                    refresh={false}
                  />
                </>
              ) : (
                ''
              )}
              {depositTypeName?.toLowerCase() === 'others' && (
                <TextFieldComponent
                  formik={formik}
                  name='other_deposit'
                  type='text'
                  label={'Others'}
                  placeholder='Specify the deposit type'
                  multiline
                  maxRows={3}
                  minRows={3}
                />
              )}
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'grid',
              columnGap: 16,
              rowGap: 24,
              gridTemplateColumns: 'repeat(1, 1fr)',
            }}
          >
            {selectedMto && fee ? <PaymentDetails fee={fee} setFee={setFee} sendCurrency={formik.values.receive_currency} /> : ''}
          </Box>
        </Stack>

        <Stack direction={'row'} sx={{ justifyContent: 'space-between', paddingTop: '24px' }}>
          <Button className='btn white-btn action-button ' onClick={backClickHandler} appearance='subtle'>
            Back
          </Button>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance='primary'
            onClick={nextClickHandler}
            disabled={
              !formik.isValid
            }
          >
            Next
          </Button>
        </Stack>
      </Box>

      {showTransfer && (
        <Transfer
          isOpen={showTransfer}
          onClose={toggleShowTransfer}
          nextClickHandler={transferNextClickHandler}
          coinToSelect={formik.values.send_currency}
        />
      )}

      {showPasswordModal && (
        <PasswordModal
          isOpen={showPasswordModal}
          onClose={toggleShowPasswordModal}
          nextClickHandler={transferClickHandler}
          loading={transferLoading}
          nextButtonText={'Transfer'}
        />
      )}
    </>
  );
};

export default TransferDetails;
