import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Iconify } from '../../../components';
import { ViewDetails } from './viewDetails/ViewDetails';
import { DocumentDetails } from './documentDetails';
import StatusHistory from './statusHistory';
import { capitalize } from 'lodash';
import { Box, Tab, Tabs, Stack } from '@mui/material';
import back from '../../../assets/img/icons/back.svg';
import { Button } from 'rsuite';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import PreviewPDF from '../../../components/previewPDF/PreviewPDF';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../redux/api/apiConfig';
import { REDEEM, REDEEM_RECEIVED_LIST } from '../../../ApiUrl';
import { Routes } from '../../../routes';

const RedemptionDetails = ({ type }) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [transaction, setTransaction] = useState(null);
  let componentRef = useRef();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const { id } = params;
  const isInward = location.pathname.includes('received');

  const getTransasction = async () => {
    const URL = isInward ? REDEEM_RECEIVED_LIST : REDEEM;
    try {
      const response = await merchantApi.get(`${URL}${id}/`);
      setTransaction(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getTransasction();
    if (location?.state?.isEdit) {
      setCurrentTab('documents');
    }
  }, []);

  const PREVIEW_TABS = [
    {
      value: 'details',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <ViewDetails ref={componentRef} transaction={transaction} isInward={isInward} />,
    },
    // {
    //   value: 'documents',
    //   icon: <Iconify icon={'ion:documents'} width={20} height={20} />,
    //   component: (
    //     <DocumentDetails
    //       transaction={transaction}
    //       getTransasction={getTransasction}
    //       isInward={isInward}
    //       // idEdit={location?.state?.isEdit}
    //     />
    //   ),
    // },
    {
      value: 'status history',
      icon: <Iconify icon={'material-symbols:history-rounded'} width={20} height={20} />,
      component: <StatusHistory statuses={transaction?.status_history} type={isInward ? 'received' : 'raised'} />,
    },
    // transaction.status_history
  ];
  const { coins = [] } = useSelector((state) => state.walletsReducer);

  const backClickHandler = () => {
    isInward ? history.push(Routes.RedemptionsReceived.path) : history.push(Routes.RedemptionsRaised.path);
  };

  return (
    <Box sx={{ padding: '24px ' }}>
      <span onClick={backClickHandler} className='back mb-4'>
        <img src={back} alt='back' />
        <span>Back</span>
      </span>
      {/* <h3 className="m-0 page-title">Transaction Details</h3>{' '} */}
      <Stack justifyContent={'space-between'} alignItems={{ md: 'center', xs: 'start' }} direction={{ md: 'row', xs: 'column' }} spacing={2}>
        <Tabs
          allowScrollButtonsMobile
          variant='scrollable'
          scrollButtons='auto'
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
        >
          {PREVIEW_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalize(tab.value)} iconPosition='start' icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        {currentTab === 'details' && transaction ? (
          <Stack spacing={2} direction={'row'}>
            {/* <ReactToPrint
              trigger={() => (
                <Button className="btn load text-white">
                  <Iconify sx={{ mr: 1 }} icon={'eva:printer-outline'} />
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              documentTitle={transaction?.reference_number}
            />

            <PreviewPDF coins={coins} transaction={transaction} /> */}

            {/* <Pdf
              targetRef={componentRef}
              filename={`${transaction.reference_number}.pdf`}
              x={30}
            >
              {({ toPdf }) => (
                <Button
                  // color="error"
                  className="btn load text-white"
                  onClick={toPdf}
                >
                  <Iconify sx={{ mr: 1 }} icon={'eva:download-outline'} />
                  Download
                </Button>
              )}
            </Pdf> */}
          </Stack>
        ) : (
          ''
        )}
      </Stack>
      {transaction &&
        PREVIEW_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
    </Box>
  );
};

export default RedemptionDetails;
