import { combineReducers } from 'redux';
import profileReducer from './profile';
import kybReducer from './kybflow';
import businessReducer from './business';
import authReducer from './auth';
import notificationReducer from './notification';
import configReducer from './config';
import walletsReducer from './wallet';
import chat from './chat';
import filters from './filters';

export default combineReducers({
  profileReducer,
  kybReducer,
  businessReducer,
  authReducer,
  notificationReducer,
  configReducer,
  walletsReducer,
  chat,
  filters,
});
