import React, { useCallback, useEffect, useState } from 'react';
import { Card, Typography, CardHeader, CardContent, Box } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
import { merchantApi } from '../../../redux/api/apiConfig';
import { KYC_LEVELS } from '../../../ApiUrl';
import { toast } from 'react-toastify';
import usePageTitle from '../../../hooks/usePageTitle';
import { capitalize } from 'lodash';

// ----------------------------------------------------------------------

export default function KycLevels({ subheader, ...other }) {
  const [kycLevels, setKycLevels] = useState([]);

  const getKycLevels = useCallback(async () => {
    try {
      const response = await merchantApi.get(KYC_LEVELS);
      // console.log(response);
      setKycLevels(response.data);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch KYC levels.');
    }
  }, []);

  useEffect(() => {
    getKycLevels();
  }, [getKycLevels]);

  usePageTitle('KYC Levels', 'View your kYC level details');

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Card sx={{}}>
        {/* <CardHeader title={'KYC Levels'} subheader={subheader} /> */}

        <CardContent
          sx={{
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none',
            },
          }}
        >
          <Timeline>
            {kycLevels.map((item, index) => (
              <KycItem key={item.id} item={item} isLast={index === kycLevels.length - 1} />
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Box>
  );
}

// ----------------------------------------------------------------------

function KycItem({ item, isLast }) {
  const { id, name, description } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (id === 1 && 'primary') ||
            (id === 2 && 'success') ||
            (id === 3 && 'secondary') ||
            // (id === '4' && 'warning') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant='subtitle2'>{capitalize(name)}</Typography>

        <Typography sx={{ color: '#4a4a4a' }} variant='caption'>
          {description}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
