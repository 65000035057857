import React from 'react';
import { startCase, toLower } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, rowsPerPageOptions, tableCellStyle } from '../../../../../utils/common';
import createAvatar from '../../../../../utils/createAvatar';
import RedeemIcon from '@mui/icons-material/Redeem';
import { Routes } from '../../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_AVAILABLE_SETTLEMENT } from '../../../../../redux/actionTypes';

export default function AvailableRedemptionsTable({ translist, fetchTranslistLoading }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.settlements.availableSettlements);

  const newRedemptionClickHandler = (transaction) => {
    const {
      mto: { id: mtoId },
      coin: { id: coinId },
    } = transaction;
    history.push({
      pathname: Routes.CreateRedeemRequest.path,
      search: `?mto=${mtoId}&coin=${coinId}`,
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_AVAILABLE_SETTLEMENT,
      payload: { pageSize: newSize },
    });
  };

  const createDataRow = (transaction, index) => {
    return {
      id: index,
      // type: capital(transaction?.type) || 'P2P',
      mto: startCase(toLower(transaction.mto.name)),
      currency: transaction.coin.symbol,
      amount: (() => {
        return formatCurrency(transaction.amount, transaction.coin?.decimal_place, transaction.coin?.symbol);
      })(),
      // currency: transaction.currency.code,
      details: transaction,
    };
  };

  const rows = translist?.length > 0 ? translist.map((transaction, index) => createDataRow(transaction, index + 1)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      // flex: 1,
      minWidth: 80,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      field: 'mto',
      headerName: 'MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'amount',
      headerName: 'Pending Settlement Amount',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // flex: 1,
      minWidth: 100,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2}>
            <Stack>
              <Tooltip placement='bottom' title='Settle'>
                <span>
                  <IconButton
                    onClick={() => {
                      newRedemptionClickHandler(params.row.details);
                    }}
                  >
                    <RedeemIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pageSize={filters.pageSize}
          pagination
          rowsPerPageOptions={rowsPerPageOptions}
          rowCount={translist.length}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
