import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { Button } from 'rsuite';

import { useHistory } from 'react-router-dom';

const MessageModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);

  // const history = useHistory();

  // const onOkClick = () => {};

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={() => {}}
      // onBackdropClick="false"
      className='wallet-configure'
      maxWidth='md'
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '560px',
          minHeight: '200px',
          maxHeight: '80vh',
          padding: '0 2rem',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Activate Later.</p>
      </DialogTitle>
      <DialogContent className='mt-3'>
        <span className=''>You can access this menu again by going to your profile section. Thanks.</span>
      </DialogContent>

      <DialogActions className='footer-content'>
        <Button className={`btn action-button primary_color text-white basis-70 `} appearance='primary' onClick={onClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageModal;
