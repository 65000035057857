import React from 'react';
import { useSelector } from 'react-redux';
import BulletPointer from '../../../../components/BulletPointer';
import { statusColors } from '../../../../utils/common';
import { Chip, Typography } from '@mui/material';
import { Stack } from '@mui/system';

const colors = statusColors.colors;
const bgColors = statusColors.bgColors;

const KybMenu = ({ statusText, stage }) => {
  const kybData = useSelector((state) => state.kybReducer);
  const errorMessages = kybData?.status?.data?.action_suggested;
  console.log(statusText);

  return (
    <aside className='kyb-menu'>
      <header className='kyb-menu-header'>
        <div>
          <BulletPointer fg={colors[`${statusText.toLowerCase()}`]} bg={bgColors[`${statusText.toLowerCase()}`]} />
          <span>Status</span>
        </div>
        <span>
          <Chip
            style={{
              fontSize: '13px',
              fontWeight: '600',
              backgroundColor: bgColors[`${statusText.toLowerCase()}`],
              color: colors[`${statusText.toLowerCase()}`],
            }}
            label={statusText}
            // color="success"
          />
        </span>
      </header>
      <section className='kyb-menu-items'>
        {errorMessages.length > 0 ? (
          <Stack spacing={1}>
            <span>Suggestions</span>
            {errorMessages.map((msg) => {
              return (
                <div>
                  <BulletPointer fg={colors[`${statusText.toLowerCase()}`]} bg={bgColors[`${statusText.toLowerCase()}`]} />
                  <span style={{ color: statusColors.colors.rejected }}>{msg}</span>
                </div>
              );
            })}
          </Stack>
        ) : (
          ''
        )}

        {/* {errorMessages.map((msg) => {
          return (
            <div>
              <BulletPointer
                fg={statusColors.colors.rejected}
                bg={statusColors.bgColors.rejected}
              />
              <span style={{ color: statusColors.colors.rejected }}>{msg}</span>
            </div>
          );
        })} */}
        {/* <ul>
          <li
            // onClick={() => {
            //   setStage('welcome');
            // }}
            className={`${stage === 'welcome' ? 'active' : ''}`}
          >
            Welcome
          </li>
          <li
            // onClick={() => {
            //   setStage('verify');
            // }}
            className={`${stage === 'verify' ? 'active' : ''}`}
          >
            Verify my account
          </li>
        </ul> */}
      </section>
    </aside>
  );
};

export default KybMenu;
