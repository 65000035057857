import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const HighlightSkeleton = ({ titleWidth = '85%', descriptionWidth = '55%', subtitleWidth = '25%' }) => {
  return (
    <Stack alignItems='center' justifyContent='center' direction={'row'} sx={{ gap: 1, width: 1, minWidth: 200 }}>
      <Skeleton animation={'wave'} variant='circular' width={60} height={60} />
      <Stack sx={{ width: '60%', gap: 0.2 }}>
        <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '1.5rem', width: titleWidth }} />
        <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '1rem', width: descriptionWidth }} />
        <Skeleton animation={'wave'} variant='text' sx={{ fontSize: '0.9rem', width: subtitleWidth }} />
      </Stack>
    </Stack>
  );
};

export default HighlightSkeleton;
