import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import * as API from '../../../../ApiUrl';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { Box } from '@mui/material';
import FeesTable from '../feesTable';

function Fees() {
  const [fees, setFees] = useState([]);
  const [fetchFeesLoading, setFetchFeesLoading] = useState(true);
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: '',
  });


  const filters = useSelector((state) => state.filters.remittances.inward);

  const getFees = useCallback(async () => {
    setFetchFeesLoading(true);
    const {  pageSize, currentPage, search } = filters;

    let params = {
      offset: currentPage * pageSize,
      limit: pageSize,
      search,
     
    };


    merchantApi
      .get(`${API.FEE}`, {
        params,
      })
      .then((response) => {
        setFetchFeesLoading(false);
        if (response.data) {
          setFees(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchFeesLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getFees();
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, filters.status, getFees]);


  return (
    <Box sx={{ marginTop: '1.5rem' }}>
     
     <FeesTable
        fetchFeesLoading={fetchFeesLoading}
        fees={fees}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
      />
    </Box>
  );
}

export default Fees;
