import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, formatWithoutTimezone, rowsPerPageOptions, tableCellStyle, tableStyle } from '../../../../../utils/common';
import { Box, Stack, Typography, LinearProgress, Tooltip, Avatar } from '@mui/material';
import createAvatar from '../../../../../utils/createAvatar';
import  capitalize from 'lodash/capitalize';
import HistoryIcon from '@mui/icons-material/History';
const MonthlyFeesTable = ({
  slabs,
  paginateTable,
  setPaginateTable,
  fetchSlabsLoading,
  setSlabToEdit,
  setIsEdit,
  toggleAddNewSlabModal,
  toggleFeeHistoryModal,
  deleteSlab,
}) => {
  const permission = useSelector((state) => state.profileReducer?.permission?.configurations?.fees?.outwardRemittanceFees?.permission);
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  const user = useSelector((state) => state.profileReducer.user);
  const userCurrency = user?.mto?.currency ?? {};


  const NoRowsOverlay = () => {
    return (
      <Stack height='100%' alignItems='center' justifyContent='center'>
        {paginateTable.search === '' ? 'No Fees Added' : 'No Fees Found'}
      </Stack>
    );
  };

  const createDataRow = (slab) => {
    return {
      id: slab.id,
      supplier:  capitalize(user?.primary_shareholder),
      fixedCost: (() => {
        return formatCurrency(slab.fixed_cost_fee, userCurrency.decimal_place, userCurrency.code, userCurrency.symbol_prefix) || '--';
      })(),
      percentageCharge: `${Number(slab.percentage_fee).toFixed(2)} %` || '--',
      firstPaymentDate: formatWithoutTimezone(
        slab.created_date,
        "dd MMM yyyy HH:mm a"
      ),
      actions: slab,
    };
  };

  const rows = useMemo(() => (slabs?.results?.length > 0 ? slabs.results.map((slab) => createDataRow(slab)) : []), [slabs.results]);

  const columns = useMemo(
    () => [
      {
        field: 'supplier',
        headerName: 'Supplier',
        sortable: false,
        flex: 1,
        minWidth: 300,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
              <Avatar alt={params.formattedValue} sx={{ mr: 2, bgcolor: createAvatar(params.formattedValue).color }}>
                {createAvatar(params.formattedValue).name}
              </Avatar>
              <Typography
                variant='body2'
                sx={{
                  color: 'black',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: '500',
                }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
          );
        },
      },

      {
        field: 'fixedCost',
        headerName: 'Fixed Cost',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: 'percentageCharge',
        headerName: 'Percentage Charge',
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Typography variant='body2' sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: "firstPaymentDate",
        headerName: "First Payment Date",
        flex: 1,
        minWidth: 200,
        // maxWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },

      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        // flex: 1,
        minWidth: 150,
        // maxWidth: 140,
        headerClassName: 'header',
        renderCell: (params) => {
          return (
            <Stack direction='row' spacing={2}>
              <Stack>
                <Tooltip title='Edit' arrow>
                  <IconButton
                    disabled={!permission?.change[role]}
                    onClick={() => {
                      onEditClicked(params.value);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              <Stack>
              <Tooltip title='Fee History' arrow>
                <IconButton
                  disabled={!permission?.change[role]}
                  onClick={() => {
                    onShowHistoryClicked(params.value);
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const onEditClicked = (slab) => {
    setIsEdit(true);
    setSlabToEdit(slab);
    toggleAddNewSlabModal();
  };

  const onShowHistoryClicked = (fee) => {
    setSlabToEdit(fee);
    toggleFeeHistoryModal();
  }

  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <div>
      <Box
        sx={{
          height: 615,
          width: 1,
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={tableStyle}
          density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchSlabsLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={slabs.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </Box>
    </div>
  );
};

export default MonthlyFeesTable;
