import React from 'react';
import { Button } from 'rsuite';

const ButtonGhost = ({ size = 'xs', children, onClick, ...params }) => {
  return (
    <Button appearance='ghost' size={size} onClick={onClick} {...params}>
      {children}
    </Button>
  );
};

export default ButtonGhost;
