import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "rsuite";
import { Card } from "react-bootstrap";
import { TextFieldComponent, TextFieldPassword } from "../../components";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  Paper,
} from "@mui/material";

import {
  getCurrencyTypes,
  getDepositTypes,
  getPurposeTypes,
  getCountriesData,
  getRemittanceStatuses,
  getRedeemStatuses,
  getKycDocTypes,
  getRemittanceTypes,
} from "../../redux/actions/config";
import {
  getPermissions,
  getProfileData,
  verifyOtp,
} from "../../redux/actions/profile";
import { Routes } from "../../routes";
import * as API from "../../ApiUrl";
import stblogo from "../../assets/img/icons/InvestvertexWhite.png";
import { getWallets, getCoins } from "../../redux/actions/wallet";
import Image from "../../components/image";
import OtpDialog from "../../components/otpModal/OtpModal";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Signin = ({ changepage }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [token, setToken] = useState(null);

  console.log(process.env.REACT_APP_STB_ENV);

  const onSubmit = async (values) => {
    console.log(values, "hehe");
    setLoading(true);
    try {
      const response = await axios.post(API.LOGIN_API, {
        ...values,
        token: captchaToken,
      });
      const token = response.data.data.token;
      setToken(token);
      // localStorage.setItem('token', token);
      localStorage.setItem("user-info", JSON.stringify(response.data.data));
      setOpenOtpModal(true)

      // toast.success("Signed in successfully");
      setLoading(false);
      // history.replace("/");
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  const onCaptchaClick = async (value) => {
    setCaptchaToken(value);
    setIsCaptchaValid(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
  });

  const onOtpSubmit = async (otp) => {
    try {
      await dispatch(verifyOtp(otp, token));
      localStorage.setItem('accessToken', token?.access);
      localStorage.setItem('refreshToken', token?.refresh);
      getAllData();
    } catch (error) {
      toast.error("Invalid OTP");
    }
  };

  const getAllData = () => {
    dispatch(getCountriesData());
    dispatch(getProfileData());
    dispatch(getPermissions());
    dispatch(getCurrencyTypes());
    dispatch(getDepositTypes());
    dispatch(getPurposeTypes());
    dispatch(getRemittanceStatuses());
    dispatch(getRedeemStatuses());
    dispatch(getKycDocTypes());
    dispatch(getRemittanceTypes());
    dispatch(getWallets());
    dispatch(getCoins());
    history.replace("/");
  };

  return (
    <main>
      <section className="vh-100 position-relative background">
        <Image
          src={stblogo}
          sx={{
            width: "250px",
            height: "200px",
            position: "absolute",
            top: "80px",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <Dialog
            open={true}
            disableEscapeKeyDown={true}
            fullWidth
            maxWidth={"xs"}
            // className="wallet-configure"
            BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0)" } }}
            PaperProps={{
              style: {
                boxShadow: "none",
                borderRadius: "8px",
              },
            }}
          >
            <DialogTitle className="text-center">
              <p className="dialog-title fw-bolder">Sign in</p>
              <p>Welcome to InvestVertex</p>
            </DialogTitle>
            <DialogContent className="merchant-wallet mt-2 pb-0">

            <Paper>
              <Stack>
                
              </Stack>
            </Paper>
              <Stack spacing={1} direction={"column"}>
                <TextFieldComponent
                  formik={formik}
                  name={"email"}
                  type={"email"}
                  label={"Email"}
                />
                <TextFieldPassword
                  formik={formik}
                  name={"password"}
                  label={"Password"}
                />
              </Stack>
            </DialogContent>
            <div
              className=" text-primary mb-1 fw-bolder"
              style={{ paddingRight: "1.5rem" }}
            >
              <Link
                to={Routes.ForgotPassword.path}
                className="text-end pull-right"
              >
                Forgot password?
              </Link>
            </div>
            <DialogActions className="captcha">
              {/* {process.env.REACT_APP_STB_ENV === 'TEST' && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onCaptchaClick}
              />
            )} */}
              <Button
                disabled={!formik.isValid || !isCaptchaValid}
                className={`btn action-button primary_color mt-0 text-white grow-1 w-100 mt-4 ${
                  !formik.isValid || !isCaptchaValid ? "disabled" : null
                }`}
                loading={loading}
                // type="submit"
                onClick={formik.handleSubmit}
                id={"login-button"}
              >
                Sign In
              </Button>
            </DialogActions>
            <div className="d-flex justify-content-center align-items-center mb-4">
              <span className="fw-normal">
                Dont have an account?
                <Card.Link
                  onClick={() => changepage("signup")}
                  className="fw-bold text-primary"
                >
                  {` Sign up `}
                </Card.Link>
              </span>
            </div>
          </Dialog>

          <OtpDialog
            title={"Enter OTP"}
            open={openOtpModal}
            onClose={() => setOpenOtpModal(false)}
            onSubmit={onOtpSubmit}
          />
        </form>
      </section>
    </main>
  );
};

export default Signin;
