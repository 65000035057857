import React from 'react';
import { Skeleton, Stack } from '@mui/material';

const BarChartLoader = () => {
  return (
    <Stack sx={{ margin: '10px 15px', gap: 1 }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-around', alignItems: 'flex-end' }}>
        <Skeleton animation={'wave'} variant='text' width={10} height={100} />
        <Skeleton animation={'wave'} variant='text' width={10} height={100} /> <Skeleton animation={'wave'} variant='text' width={10} height={150} />
        <Skeleton animation={'wave'} variant='text' width={10} height={180} />
        <Skeleton animation={'wave'} variant='text' width={10} height={90} /> <Skeleton animation={'wave'} variant='text' width={10} height={100} />
      </Stack>
      <Skeleton animation={'wave'} variant='text' sx={{ width: '100%' }} />
    </Stack>
  );
};

export default BarChartLoader;
