import React, { createContext, useContext, useEffect, useReducer } from 'react';
import remittanceReducer from '../reducers/remittanceReducer';
import { remittanceInitialState } from '../InitialStates';

const RemittanceContext = createContext(remittanceInitialState);

const RemittanceProvider = ({ children }) => {
  const [remittanceState, remittanceDispatch] = useReducer(remittanceReducer, remittanceInitialState);

  //   useEffect(() => {
  //     products !== undefined &&
  //       productsDispatch({
  //         type: "SET_INITIAL_DATA",
  //         payload: { products },
  //       });
  //   }, [products]);

  const value = {
    ...remittanceState,
    remittanceDispatch,
  };

  // Imp console.log, please ignore.
  // console.log('This runs everytime, so can put all filter functions here');

  return (
    <RemittanceContext.Provider value={value} displayName='Create Remittance'>
      {children}
    </RemittanceContext.Provider>
  );
};

const useRemittance = () => useContext(RemittanceContext);

export { RemittanceProvider, useRemittance };
