import React from 'react';
// @mui
import { Box, Stack, Divider, Typography, RadioGroup } from '@mui/material';
// import SvgColor from 'src/components/svg-color/SvgColor';
import SvgColor from '../../../components/svg-color';

import { MaskControl, StyledCard, StyledWrap } from './styles';
// import Block from 'src/components/block/Block';
import _ from 'lodash';
// ----------------------------------------------------------------------

export default function PaymentMethods({ options, formik }) {
  const { values, setFieldValue } = formik;
  // selected={values.account === method.type}
  return (
    <Box>
      <RadioGroup
        name='themeMode'
        onChange={(e) => {
          console.log(e.target.value);
          setFieldValue('payment_method', e?.target?.value);
        }}
      >
        <StyledWrap>
          {options.map((method, idx) => (
            <StyledCard key={idx} selected={values.payment_method === method.value}>
              <SvgColor src={`/assets/icons/payments/${method.value}.svg`} />
              <Stack sx={{ ml: 2 }}>
                <Typography variant='body1' sx={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
                  {_.startCase(method.name.toLowerCase())}
                </Typography>
              </Stack>

              <MaskControl value={method.value} />
            </StyledCard>
          ))}
        </StyledWrap>
      </RadioGroup>
      {/* </Block> */}
    </Box>
  );
}
