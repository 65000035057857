import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';

import { Stack, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import { TextFieldComponent } from '../../components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { merchantApi } from '../../redux/api/apiConfig';
import { MTO_PROFILE, TIMEZONES } from '../../ApiUrl';

const validationSchema = yup.object({
  address: yup.string('Enter address').required('Address is required'),
  timezone: yup.object().required('Timezone is required').nullable(),
});

const EditProfileModal = ({ onClose, isOpen, onEdit }) => {
  const [loading, setLoading] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const { user } = useSelector((state) => state.profileReducer) || {};

  const addClickHandler = async (values) => {
    const { address, timezone } = values;

    try {
      setLoading(true);
      const response = await merchantApi.patch(MTO_PROFILE, {
        address,
        timezone: timezone.value,
      });
      console.log(response);
      toast.success('Profile updated successfully');
      onEdit();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const formik = useFormik({
    initialValues: {
      address: user?.address,
      timezone: { value: user?.timezone },
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  console.log(formik);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  const getTimezones = async () => {
    try {
      const response = await merchantApi.get(TIMEZONES);
      setTimezoneOptions(response.data.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  useEffect(() => {
    getTimezones();
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={'xs'}
        onClose={handleCloseModal}
        scroll={'body'}
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Edit Profile</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ paddingTop: '5px !important', paddingBottom: '0 !important' }}>
            <Stack spacing={1} direction={'column'} alignItems={'center'} mb={1}>
              <Autocomplete
                id='timezone'
                value={formik.values.timezone}
                onChange={(e, newValue) => {
                  console.log(e, newValue);
                  formik.setFieldValue('timezone', newValue);
                }}
                options={timezoneOptions}
                getOptionLabel={(option) => option?.value ?? ''}
                isOptionEqualToValue={(option, value) => {
                  console.log(option, value);
                  return option.value === value.value;
                }}
                sx={{ flexGrow: 1, width: '100%', my: 2 }}
                autoSelect={true}
                renderInput={(params) => {
                  return (
                    <Stack
                      direction={'column'}
                      sx={{
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        flexGrow: 1,
                      }}
                    >
                      <Typography>Timezone*</Typography>
                      <TextField
                        placeholder='Select Timezone'
                        {...params}
                        name='timezone'
                        onBlur={formik?.handleBlur}
                        error={formik?.touched?.timezone && Boolean(formik?.errors?.timezone)}
                        helperText={formik?.touched?.timezone && formik?.errors?.timezone}
                      />
                    </Stack>
                  );
                }}
              />

              <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Address'} multiline minRows={4} maxRows={4} />
            </Stack>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance='primary'
                type={'submit'}
                disabled={!formik.isValid}
                loading={loading}
              >
                Update
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default EditProfileModal;
