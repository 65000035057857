import React from "react";
import { useHistory } from "react-router-dom";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";

import createAvatar from "../../../../utils/createAvatar";
import { Label, ButtonGhost } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_INWARD_REMITTANCE } from "../../../../redux/actionTypes";
import InfoIcon from "@mui/icons-material/Info";

export default function ProgrammedLiensTable({
  translist,
  fetchTranslistLoading,
  viewDetails,
  viewContractFiles,
  counts,
}) {
  const dispatch = useDispatch();
  const allStatus = useSelector(
    (state) => state.configReducer.remittanceStatuses
  );
  const filters = useSelector((state) => state.filters.remittances.inward);

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };


  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction.reference_number,

      submissionDate: formatWithoutTimezone(
        transaction.created_date,
        "dd MMM yyyy HH:mm a"
      ),

      comments: transaction?.comments?.[0],
      lienAmount: (() => {
        return formatCurrency(
          transaction.send_amount,
          transaction.send_currency?.decimal_place,
          transaction.send_currency?.symbol
        );
      })(),
      settledAmount: (() => {
        return formatCurrency(
          transaction.send_amount,
          transaction.send_currency?.decimal_place,
          transaction.send_currency?.symbol
        );
      })(),
      lender: (() => {
        const senderType = type.split("2")[0];
        if (senderType === "p") {
          return startCase(
            toLower(
              `${transaction.sender.first_name} ${transaction.sender.last_name}`
            )
          );
        } else {
          return startCase(toLower(transaction.sender.business_name));
        }
      })(),
      contractFiles: transaction?.contract_files,

      // currency: transaction.currency.code,
      status: transaction?.current_status,
      closureTxn: transaction?.blockchain_url,
     
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Contract ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: "default" }} title={params.formattedValue}>
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "submissionDate",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {`${params.formattedValue}:  View All >`}
          </Typography>
        );
      },
    },
  
    {
      field: "lienAmount",
      headerName: "Contract Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
        field: "settledAmount",
        headerName: "Settled Amount",
        flex: 1,
        minWidth: 150,
        // maxWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
      },

    {
        field: "lender",
        headerName: "Lended By",
        flex: 1.5,
        minWidth: 200,
        // maxWidth: 200,
        textOverflow: "ellipsis",
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
              <Avatar
                alt={params.formattedValue}
                sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
              >
                {createAvatar(params.formattedValue).name}
              </Avatar>
              <Stack>
                <Typography
                  variant="body2"
                  sx={{
                    color: "black",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: "500",
                  }}
                >
                  {params.formattedValue}
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
      {
        field: "contractFiles",
        headerName: "Contract Files",
        flex: 1,
        minWidth: 150,
        // maxWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <ButtonGhost
            appearance="ghost"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              viewContractFiles();
            }}
          >
            View Files
          </ButtonGhost>
            // <Typography onClick={() =>viewContractFiles(params.formattedValue)} variant="body2" style={tableCellStyle}>
            //   View
            // </Typography>
          );
        },
      },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      flex: 1.5,
      minWidth: 250,
      headerClassName: "header",
      renderCell: (params) => {
        const { receiver_name, text_colour, background_colour } =
          params.formattedValue;
        return (
          <Stack sx={{flexDirection:'row', alignItems:'center'}}>
          <Label
            variant={"ghost"}
            textColor={text_colour}
            backgroundColor={background_colour}
            sx={{ textTransform: "capitalize" }}
          >
            {receiver_name}
          </Label>
           <Tooltip
           placement="top"
           sx={{ cursor: "default" }}
           title={"Reason for the status"}
         >
           <IconButton size={"small"}>
             <InfoIcon fontSize={"inherit"}/>
           </IconButton>
         </Tooltip>
         </Stack>
        );
      },
    },
    {
        field: "closureTxn",
        headerName: "Closure Txns (Internal Txn)",
        flex: 1,
        minWidth: 150,
        // maxWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <ButtonGhost
            appearance='ghost'
            size='xs'
            onClick={(e) => {
              e.stopPropagation();
              viewDetails();
            }}
          >
            Expand
          </ButtonGhost>
          );
        },
      },

   
   
  ];

  const TABS = [
    {
      value: "unsettled",
      label: "Unsettled",
      color: "rejected",
      count: counts.pending,
    },

    {
      value: "partially_settled",
      label: "Partially Settled",
      color: "application submitted",
      count: counts.processed,
    },
    {
      value: "fully_settled",
      label: "Fully Settled",
      color: "complete",
      count: counts.processed,
    },
    { value: "", label: "All", color: "pending", count: counts.all },
  ];

  const onStatusFilterChanges = (newStatus) => {
    let status;
    console.log(newStatus);
    if (newStatus !== "") {
      status = allStatus.find((status) => status.value === newStatus) ?? "";
    }
    // setFilterStatus(newStatus);
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: 0, status: null, statusValue: newStatus },
    });
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filters.statusValue}
          onChange={(e, value) => onStatusFilterChanges(value)}
          sx={{
            px: 2,
            bgcolor: "#F4F6F8",
            borderRadius: "8px 8px 0 0",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              sx={{ textTransform: "capitalize" }}
              disableRipple
              key={tab.label}
              value={tab.value}
              icon={
                <Label
                  sx={{ marginRight: "5px", cursor: "pointer" }}
                  variant={"ghost"}
                  color={tab.color}
                >
                  {tab.count}
                </Label>
              }
              label={tab.label}
              iconPosition={"start"}
            />
          ))}
        </Tabs>

        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            viewDetails(row.row.details.id);
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
