import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../../redux/api/apiConfig';
import 'rsuite/dist/styles/rsuite-default.css';
import { toast } from 'react-toastify';
import * as API from '../../ApiUrl';
import searchIcon from '../../assets/img/icons/searchIcon.svg';
import { Form, InputGroup } from '@themesberg/react-bootstrap';
import usePageTitle from '../../hooks/usePageTitle';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import { ButtonPrimary, Iconify } from '../../components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PersonalInfo } from './components';

const UserKyc = () => {
  const [users, setUsers] = useState([]);
  const [fetchUsersLoading, setFetchUsersLoading] = useState(true);
  const [selectedKycId, setSelectedKycId] = useState('');
  const location = useLocation();

  usePageTitle('User KYB', 'View your user KYB details');

  const [currentTab, setCurrentTab] = useState('personalInfo');


  const getUsers = useCallback(async () => {
    setFetchUsersLoading(true);
    try {
      const response = await merchantApi.get(`${API.KYC_USERS}`);
      if (location?.state?.userId) {
        let kycId = response.data.results.find((kyc) => kyc?.user?.data?.id == location?.state?.userId)?.id;
        setSelectedKycId(kycId);
      }
      console.log(response);
      setUsers(response.data);
      setFetchUsersLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
      setFetchUsersLoading(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 500);
    return () => clearTimeout(timer);
  }, [getUsers]);

  const TABS = [
    {
      value: 'personalInfo',
      label: 'Personal Info',
      icon: <Iconify icon='ic:round-account-box' />,
      component: (
    //    <PersonalInfo/>
            <></>
),
    },
    {
      value: 'personalDocs',
      label: 'Personal Docs',
      icon: <Iconify icon='carbon:password' />,
      component:<>Personal Docs</>,
    },
    {
        value: 'businessInfo',
        label: 'Business Info',
        icon: <Iconify icon='carbon:password' />,
        component:<>Business Info</>,
      },
      {
        value: 'busiessDocs',
        label: 'Business Docs',
        icon: <Iconify icon='carbon:password' />,
        component:<>Business Docs</>,
      },
  ];


  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
          sx={{
            bgcolor: 'background.paper',
            '& .MuiTabs-flexContainer': {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-start',
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab disabled={tab.disabled} iconPosition={'start'} key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>

        {TABS.map((tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>)}
        </Box>
  );
};

export default UserKyc;
