import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Card, Grid, Table, Divider, TableRow, TableBody, TableHead, TableCell, Stack, Typography, TableContainer } from '@mui/material';
import { PreviewContainer, PreviewItem } from '../../../../components';
import { formatCurrency } from '../../../../utils/common';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

export const ViewDetails = React.forwardRef(({ transaction, backClickHandler, isInward }, ref) => {
  // const { depositTypes } = useSelector((state) => state.configReducer);
  const { coins = [] } = useSelector((state) => state.walletsReducer);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const StyledRowResult = styled(TableRow)(({ theme }) => ({
    '& td': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  }));

  return (
    <>
      <Box ref={ref} sx={{ mt: 5 }}>
        <Card sx={{ p: 3 }}>
          <PreviewItem
            title={isInward ? 'Remittances sent to' : 'Remittances received from'}
            content={`
                ${isInward ? transaction.sender_mto?.name ?? '' : transaction.requester_mto?.name ?? ''}
                ${isInward ? transaction?.sender_mto?.address ?? '' : transaction?.requester_mto?.address ?? ''}`}
            sx={{ mb: 5 }}
          />

          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ minWidth: 960 }}>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': {
                    backgroundColor: 'transparent',
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>

                  <TableCell align='left'>Reference Number</TableCell>

                  <TableCell align='left'>Sender Client</TableCell>

                  <TableCell align='left'>Receiver Client</TableCell>

                  <TableCell width={160} align='right'>
                    Received Amount
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  '& td': {
                    fontWeight: 500,
                    color: '#667085',
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '14px',
                  },
                }}
              >
                {transaction.remittance.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>

                    <TableCell align='left'>{row.reference_number}</TableCell>

                    <TableCell align='left'>
                      {row.type.split('2')[0] === 'p' ? `${row.sender.first_name} ${row.sender.last_name}` : row.sender.business_name}
                    </TableCell>

                    <TableCell align='left'>
                      {row.type.split('2')[1] === 'p' ? `${row.receiver.first_name} ${row.receiver.last_name}` : row.receiver.business_name}
                    </TableCell>

                    <TableCell align='right'>
                      {formatCurrency(row.receive_amount, row.receive_currency.decimal_place, row.receive_currency.symbol)}
                    </TableCell>
                  </TableRow>
                ))}
                {/* <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    <Box sx={{ mt: 2 }} />
                    Subtotal
                  </TableCell>

                  <TableCell
                    align="right"
                    width={120}
                    sx={{ typography: 'body1' }}
                  >
                    <Box sx={{ mt: 2 }} />
                    {transaction.amount}
                  </TableCell>
                </StyledRowResult> */}

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align='right'>
                    <Typography variant='h6' sx={{ color: 'black' }}>
                      Total
                    </Typography>
                  </TableCell>

                  <TableCell align='right' width={140}>
                    <Typography variant='h6' sx={{ color: 'black' }}>
                      {formatCurrency(transaction.amount, transaction.coin.decimal_place, transaction.coin.symbol)}
                    </Typography>
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </>
  );
});
