import { Box, Paper, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { format } from 'date-fns';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
import { Label, PreviewItem } from '../../../components';
import usePageTitle from '../../../hooks/usePageTitle';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../redux/api/apiConfig';
import { LIQUIDITY } from '../../../ApiUrl';
import { capitalize } from 'lodash';
import back from '../../../assets/img/icons/back.svg';
import bg from '../../../assets/illustrations/notFound.svg';
import Image from '../../../components/image/Image';
import { formatWithoutTimezone } from '../../../utils/common';
import { Routes } from '../../../routes';

const LiquidityHistory = () => {
  const [loading, setLoading] = useState(true);
  const [liquidity, setLiquidity] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  const getLiquidityDetails = useCallback(async () => {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${LIQUIDITY}${id}/`);
      setLiquidity(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);

      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Could not fetch liquidity details');
    }
  }, [id]);

  useEffect(() => {
    getLiquidityDetails();
  }, [getLiquidityDetails]);

  usePageTitle('Liquidity History', 'View liquidity change history');

  const backClickHandler = () => {
    console.log(history);
    history.goBack();
  };
  console.log(loading, liquidity, loading && !liquidity);
  return (
    <Box sx={{ padding: '0 24px 24px', height: 'calc(100vh - 100px)' }}>
      <span onClick={backClickHandler} className='back mb-4'>
        <img src={back} alt='back' />
        <span>Back</span>
      </span>
      {!loading && !liquidity ? (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: 2, mt: 4 }}>
          <Image src={bg} sx={{ width: { sm: '200px', md: '300px' } }} />
          <Typography variant='h6'>No Status History Found!</Typography>
        </Stack>
      ) : (
        <Timeline position='alternate'>
          {(loading ? [...Array(3)] : liquidity?.history)?.map((item, i) => (
            <TimelineItem key={i}>
              <TimelineSeparator>
                <TimelineDot
                  color={(() => {
                    switch (item?.status) {
                      case 'approved':
                        return 'info';
                      case 'requested':
                        return 'warning';
                      case 'transferred':
                        return 'success';
                      default:
                        return 'error';
                    }
                  })()}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ textAlign: 'left !important' }}>
                {loading ? <Skeleton variant='rounded' animation={'wave'} height={100} /> : <HistoryItem item={item} i={i} liquidity={liquidity} />}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Box>
  );
};

const HistoryItem = ({ item, liquidity, i, type }) => {
  let label;
  // const { value, status } = status?.status?;
  // console.log(item.status);
  const status = capitalize(item?.status);

  const isInitialStatus = i == liquidity.history.length - 1;
  switch (status) {
    case 'Approved':
      console.log(status, 'Approved');
      label = (
        <Label variant={'ghost'} color={'pending'}>
          {status}
        </Label>
      );
      break;

    case 'Requested':
      console.log(status, 'Requested');
      label = (
        <Label variant={'ghost'} color={'application submitted'}>
          {status}
        </Label>
      );
      break;
    case 'Transferred':
      console.log(status, 'Requested');
      label = (
        <Label variant={'ghost'} color={'complete'}>
          {status}
        </Label>
      );
      break;

    default:
      console.log(status, 'default');
      label = (
        <Label variant={'ghost'} color={'rejected'}>
          {status}
        </Label>
      );
      break;
  }

  return (
    <Paper
      sx={{
        borderRadius: '8px',
        borderColor: '#000',
        bgcolor: (theme) => {
          switch (status) {
            case 'Requested':
              return alpha(theme.palette.warning.light, 0.1);
            case 'Approved':
              return alpha(theme.palette.info.light, 0.1);
            case 'Transferred':
              return alpha(theme.palette.success.light, 0.1);
            default:
              return alpha(theme.palette.error.light, 0.1);
          }
        },
        padding: '1rem',
      }}
    >
      {/* {label} */}
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <PreviewItem title={'Status'} tooltip={false} content={label || ''} />
        <PreviewItem
          title={'Remarks'}
          // content={
          //   'asdas aasd asd asdasd asnoka cmd  aok caks ca coian so asjc ajdcnsdincosd csdc sdicsdjcsoidncs dc'
          // }
          content={i == liquidity.history.length - 1 ? capitalize(liquidity.remarks) : capitalize(item?.approved_denied_remarks)}
        />
        <PreviewItem
          title={isInitialStatus ? 'Requested By' : 'Updated By'}
          content={isInitialStatus ? capitalize(liquidity.created_user) : capitalize(item?.approved_denied_by)}
        />
        <PreviewItem title={'Date'} content={formatWithoutTimezone(item?.modified_date, 'dd MMM yyyy HH:mm a')} />
      </Stack>
    </Paper>
  );
};

export default LiquidityHistory;
